import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { MultiValue, SingleValue } from "react-select";
import * as yup from "yup";

import styles from "./HeaderFilters.module.scss";

import { useAppDispatch } from "../../../../../store/hooks/hooks";
import {
  setCompleted,
  setMilestoneType,
  setPeriodFrom,
  setPeriodTo,
  setProjects,
} from "../../../../../store/slices/reportsSlice/milestonesSlice";
import { CustomDateInput } from "../../../../FormFields/CustomDateInput";
import { CustomMultiSelect } from "../../../../FormFields/CustomMultiSelect";
import { IOptions } from "../../../../FormFields/CustomMultiSelect/types";
import { CustomSelect } from "../../../../FormFields/CustomSelect";
import { IOptions as SelectIOptions } from "../../../../FormFields/CustomSelect/types";

const completedTempOptions = [
  {
    label: "All",
    value: "All",
  },
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

interface IProps {
  completed?: string[];
  maxPeriod?: string;
  minPeriod?: string;
  projects?: string[];
  milestoneType?: string[];
}

export const HeaderFilters: FC<IProps> = ({
  completed,
  milestoneType = [],
  projects = [],
  minPeriod = "",
  maxPeriod = "",
}) => {
  const formik = useFormik({
    initialValues: {
      project: projects.map((project) => ({ label: project, value: project })),
      milestoneType: milestoneType.map((type) => ({
        label: type,
        value: type,
      })),
      completed: "All",
      dateFrom: "",
      dateTo: "",
    },
    validationSchema: yup.object({
      dateFrom: yup.date(),
      dateTo: yup.date(),
    }),
    onSubmit: () => {},
  });

  const handleIssuePeriodToChange = (e: any, name: string) => {
    const value = e.target.value;
    const parts = value.split("/");

    if (parts.length === 3) {
      let month = parseInt(parts[0], 10);
      let day = parseInt(parts[1], 10);
      const year = parseInt(parts[2], 10);

      if (month > 12) {
        month = 12;
      } else if (month < 1) {
        month = 1;
      }

      if (day > 31) {
        day = 31;
      } else if (day < 1) {
        day = 1;
      }

      const correctedValue = `${month}/${day}/${year}`;

      name === "issuePeriodTo"
        ? setIssuePeriodTo(correctedValue)
        : setIssuePeriodFrom(correctedValue);
    }
  };

  const formatDateFilter = (value: string) => {
    const [month, day, year] = value.split("/");
    const dateObj = `${year}-${month}-${day}T00:00:00Z`;
    return dateObj;
  };

  const [issuePeriodFrom, setIssuePeriodFrom] = useState(minPeriod);
  const [issuePeriodTo, setIssuePeriodTo] = useState(maxPeriod);

  const onProjectChange = (value: IOptions[]) => {
    formik.setFieldValue("project", value);
  };

  const onMilestoneTypeChange = (value: IOptions[]) => {
    formik.setFieldValue("milestoneType", value);
  };

  const onCompletedChange = (
    newValue: MultiValue<SelectIOptions> | SingleValue<SelectIOptions>,
  ) => {
    if (newValue) {
      formik.setFieldValue("completed", (newValue as SelectIOptions).value);
    }
  };
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isNaN(Date.parse(formatDateFilter(issuePeriodTo)))) {
      formik.setFieldValue("dateTo", formatDateFilter(issuePeriodTo));
    }

    if (!isNaN(Date.parse(formatDateFilter(issuePeriodFrom)))) {
      formik.setFieldValue("dateFrom", formatDateFilter(issuePeriodFrom));
    }
  }, [issuePeriodTo, issuePeriodFrom]);

  useEffect(() => {
    dispatch(setProjects(formik.values.project.map((item) => item.value)));
    dispatch(
      setMilestoneType(formik.values.milestoneType.map((item) => item.value)),
    );
    dispatch(setCompleted(formik.values.completed));
    dispatch(setPeriodFrom(formik.values.dateFrom));
    dispatch(setPeriodTo(formik.values.dateTo));
  }, [formik.values]);

  return (
    <form className={styles.root} onSubmit={formik.handleSubmit}>
      <CustomMultiSelect
        options={projects.map((project) => ({
          label: project,
          value: project,
        }))}
        label="Project"
        className={styles.bigSelect}
        value={formik.values.project}
        onChange={onProjectChange}
      />
      <CustomMultiSelect
        options={milestoneType.map((type) => ({
          label: type,
          value: type,
        }))}
        label="Milestone type"
        className={styles.bigSelect}
        value={formik.values.milestoneType}
        onChange={onMilestoneTypeChange}
      />
      <CustomSelect
        options={completedTempOptions}
        label="Completed"
        selectTheme="labelInsideBorder"
        value={{
          label: formik.values.completed,
          value: formik.values.completed,
        }}
        onChange={onCompletedChange}
      />
      <div className={styles.wrapper}>
        <CustomDateInput
          id={"dateFrom"}
          label="Finish date (from)"
          className={styles.dateInput}
          name="dateFrom"
          value={issuePeriodFrom}
          onChange={(e) => handleIssuePeriodToChange(e, "issuePeriodFrom")}
        />
        <CustomDateInput
          id={"dateTo"}
          label="Finish date (to)"
          className={styles.dateInput}
          name="dateTo"
          value={issuePeriodTo}
          onChange={(e) => handleIssuePeriodToChange(e, "issuePeriodTo")}
        />
      </div>
    </form>
  );
};
