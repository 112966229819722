import { FC } from "react";

import styles from "./FormSubmitWrapper.module.scss";

import { Description } from "../../../Description";
import { CustomButton } from "../../../FormFields/CustomButton";

interface IProps {
  btnText: string;
  additionalText?: string;
  additionalBtn?: string;
  isDisbledSubmit?: boolean;
  onAdditionalBtnClick?: () => void;
}

export const FormSubmitWrapper: FC<IProps> = ({
  btnText,
  additionalText,
  additionalBtn,
  isDisbledSubmit,
  onAdditionalBtnClick,
}) => {
  return (
    <div className={styles.root}>
      <CustomButton
        type="submit"
        className={styles.submitBtn}
        isDisabled={isDisbledSubmit}
      >
        {btnText}
      </CustomButton>
      {(additionalText || additionalBtn) && (
        <div className={styles.btnsAdditionalWrapper}>
          {additionalText && (
            <Description theme={"t4"}>{additionalText}</Description>
          )}
          {additionalBtn && (
            <CustomButton
              theme="text"
              className={styles.signUpBtn}
              onClick={onAdditionalBtnClick}
            >
              {additionalBtn}
            </CustomButton>
          )}
        </div>
      )}
    </div>
  );
};
