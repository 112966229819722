import { useCookies } from "react-cookie";
import { Navigate, Outlet } from "react-router-dom";

import { PATHS } from "../../constants/PATHS";

export const PrivateRoute = () => {
  const [token] = useCookies(["accessToken"]);

  return token.accessToken ? <Outlet /> : <Navigate to={PATHS.BASE_PATH} />;
};
