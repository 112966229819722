import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ChartOptions,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { FC } from "react";
import { Bar } from "react-chartjs-2";

import styles from "./VerticalBarChart.module.scss";

import { DataItem } from "../../../types/previewTemplate";
import { Description } from "../../Description";

ChartJS.register(CategoryScale, LinearScale, BarElement);

interface IProps {
  dataList?: DataItem[];
  options: ChartOptions<"bar">;
  title?: string;
}

export const VerticalBarChart: FC<IProps> = ({ dataList, options, title }) => {
  const labels = dataList?.map((item) => item.label);

  const data = {
    labels,
    datasets: [
      {
        data: dataList?.map((item) => item.value),
        backgroundColor: "#FFA267",
      },
    ],
  };

  return (
    <div className={styles.root}>
      <Description theme={"t3"}>{title}</Description>
      <div className={styles.wrapper}>
        <Bar options={options} plugins={[ChartDataLabels]} data={data} />
      </div>
    </div>
  );
};
