import classNames from "classnames";
import { FC } from "react";

import styles from "./Loader.module.scss";

interface IProps {
  className?: string;
}

export const Loader: FC<IProps> = ({ className }) => {
  return (
    <div className={styles.root}>
      <div className={classNames(styles.loader, className)} />
    </div>
  );
};
