import { useFormik } from "formik";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import * as yup from "yup";

import styles from "./SingUpForm.module.scss";

import { getConfirmationCode } from "../../../../api";
import signupImg from "../../../../assets/img/signup.png";
import { AUTH_FORMS } from "../../../../constants/AUTH_FORMS";
import { MESSAGES } from "../../../../constants/MESSAGES";
import { PATHS } from "../../../../constants/PATHS";
import { useAppDispatch } from "../../../../store/hooks/hooks";
import { setAuthStep } from "../../../../store/slices/authSlice";
import { EAuthSteps } from "../../../../types/formTypes";
import { createErrorMessage } from "../../../../utils/createErrorMessage";
import { decodeToken } from "../../../../utils/decodeToken";
import { CustomLink } from "../../../CustomLink";
import { CustomCheckbox } from "../../../FormFields/CustomCheckbox/CustomCheckbox";
import { CustomInput } from "../../../FormFields/CustomInput";
import { FormVerticalWrapper } from "../../../FormFields/FormVerticalWrapper";
import { FormImg } from "../FormImg";
import { FormSubmitWrapper } from "../FormSubmitWrapper";
import { FormTitle } from "../FormTitle";

export const SignUpForm = () => {
  const [, setToken] = useCookies<string>(["tempToken"]);

  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      agreement: false,
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .matches(
          /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
          MESSAGES.EMAIL
        )
        .required(MESSAGES.REQUIRED),
      password: yup
        .string()
        .required(MESSAGES.REQUIRED)
        .min(8, MESSAGES.PASSWORDMIN)
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?!.*\s)/,
          MESSAGES.REQCHAR
        )
        .notOneOf(["password", "123456", "qwerty"], MESSAGES.NOTPATTERN),
      confirmPassword: yup
        .string()
        .required(MESSAGES.REQUIRED)
        .min(8, MESSAGES.PASSWORDMIN)
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?!.*\s)/,
          MESSAGES.REQCHAR
        )
        .notOneOf(["password", "123456", "qwerty"], MESSAGES.NOTPATTERN)
        .oneOf([yup.ref("password")], MESSAGES.PASSWORDCONFIRM),
      agreement: yup.boolean().oneOf([true]),
    }),
    onSubmit: async (data) => {
      try {
        const resp = await getConfirmationCode(data);

        const tempToken = decodeToken(resp.data.data.validateToken);

        setToken("tempToken", tempToken.token, {
          expires: tempToken.expDate,
          path: "/",
        });

        dispatch(setAuthStep(EAuthSteps.Confirmation));
      } catch (error) {
        toast.error(createErrorMessage(error));
      }
    },
  });

  const onAdditionalBtnClick = () => {
    dispatch(setAuthStep(EAuthSteps.Login));
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormVerticalWrapper>
        <FormVerticalWrapper gap={32} align="center">
          <FormImg src={signupImg} />
          <FormTitle text={AUTH_FORMS.NAMES.SIGNUP} />
          <FormVerticalWrapper gap={24}>
            <CustomInput
              type="email"
              label="Email"
              id="email"
              name="email"
              placeholder="Enter your Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.errors.email}
            />
            <CustomInput
              type="password"
              label="Password"
              id="password"
              name="password"
              placeholder="Enter your password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.errors.password}
              autoComplete="new-password"
            />
            <CustomInput
              type="password"
              label="Confirm password"
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Enter confirm password"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={formik.errors.confirmPassword}
              autoComplete="new-password"
            />
            <CustomCheckbox
              id="agreement"
              name="agreement"
              checked={formik.values.agreement}
              onChange={formik.handleChange}
            >
              <span className={styles.checkboxContent}>
                I agree to the{" "}
                <CustomLink
                  link={PATHS.PRIVACY_POLICY_PATH}
                  className={styles.link}
                >
                  Privacy Policy
                </CustomLink>
              </span>
            </CustomCheckbox>
          </FormVerticalWrapper>
        </FormVerticalWrapper>
        <FormSubmitWrapper
          btnText={AUTH_FORMS.NAMES.SIGNUP}
          additionalText={AUTH_FORMS.ADDITIONAL_TEXT.SIGNUP}
          additionalBtn={AUTH_FORMS.NAMES.LOGIN}
          isDisbledSubmit={!formik.dirty || !formik.isValid}
          onAdditionalBtnClick={onAdditionalBtnClick}
        />
      </FormVerticalWrapper>
    </form>
  );
};
