import { useEffect } from "react";

import { ConnectData } from "../../components/ConnectData";
import { ImportConfigurationPage } from "../../components/ImportConfiguration";
import { ImportConfigurationFieldMappingPage } from "../../components/ImportConfigurationFieldMapping";
import { PreviewPage } from "../../components/PreviewTemplate";
import { SelectTemplate } from "../../components/SelectTemplate";
import { useAppDispatch, useAppSelector } from "../../store/hooks/hooks";
import {
  selectTemplatePage,
  setTemplateSPage,
  setTemplateStep,
} from "../../store/slices/stepSlice";
import {
  setDemo,
  setFilesDemo,
  setFilesUpload,
} from "../../store/slices/uploadFileSlice";
import { ETemplatePage, ETemplateStep } from "../../types/steps";

export const ConnectDataPage = () => {
  const currentPage = useAppSelector(selectTemplatePage);

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(setTemplateSPage(ETemplatePage.ConnectPage));
      dispatch(setTemplateStep(ETemplateStep.step1));
      dispatch(setDemo(null));
      dispatch(setFilesDemo(null));
      dispatch(setFilesUpload(null));
    };
  }, []);

  return (
    <>
      {currentPage === ETemplatePage.ConnectPage && <ConnectData />}
      {currentPage === ETemplatePage.SelectTemplatePage && <SelectTemplate />}
      {currentPage === ETemplatePage.ImportConfigurationPage && <ImportConfigurationPage />}
      {currentPage === ETemplatePage.ImportConfigurationFieldMappingPage && <ImportConfigurationFieldMappingPage />}
      {currentPage === ETemplatePage.PreviewPage && <PreviewPage />}
    </>
  );
};
