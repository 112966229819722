import { useFormik } from "formik";
import { useEffect } from "react";
import { MultiValue, SingleValue } from "react-select";

import styles from "./HeaderHistory.module.scss";

import { ReactComponent as CheckMark } from "../../../../assets/icons/checkmark-icon.svg";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks/hooks";
import {selectIdHistory, selectName, setIdHistory, setName} from "../../../../store/slices/historySlice";
import { CustomSelect } from "../../../FormFields/CustomSelect";
import { IOptions } from "../../../FormFields/CustomSelect/types";

export const HeaderHistory = () => {
  const dispatch = useAppDispatch()
  const reportName = useAppSelector(selectName)
  const reportId = useAppSelector(selectIdHistory)

  const formik = useFormik({
    initialValues: {
      reportTitle: reportName,
      reportId: reportId,
    },
    onSubmit: () => {},
  });

  const onSelectChange = (
    newValue: MultiValue<IOptions> | SingleValue<IOptions>,
  ) => {
    const selectedOption = newValue as IOptions;
    formik.setFieldValue("reportTitle", selectedOption.value);
    formik.setFieldValue("reportId", selectedOption.id);
  };

  useEffect(() => {
    dispatch(setIdHistory(formik.values.reportId))
    dispatch(setName(formik.values.reportTitle))
  }, [formik.values]);

  return (
    <form className={styles.root} onSubmit={formik.handleSubmit}>
      <div className={styles.wrapperSelect}>
        <CustomSelect
          label="Report’s Title"
          placeholder="Select your role"
          selectTheme={"withoutBorder"}
          isSearchable={false}
          value={
            formik.values.reportTitle
              ? {
                  value: formik.values.reportTitle,
                  label: formik.values.reportTitle,
                  id: "",
                }
              : undefined
          }
          onChange={onSelectChange}
          options={[
            {
              value: "All my reports",
              label: "All my reports",
              id: "",
              icon: <CheckMark />,
            },
            {
              value: "Project Summary",
              label: "Project Summary",
              id: "ProjectSummary",
              icon: <CheckMark />,
            },
            {
              value: "Milestones Summary",
              label: "Milestones Summary",
              id: "MilestoneSummary",
              icon: <CheckMark />,
            },
            {
              value: "Issues Summary",
              label: "Issues Summary",
              id: "IssueSummary",
              icon: <CheckMark />,
            },
          ]}
        />
      </div>
    </form>
  );
};
