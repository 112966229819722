import { forwardRef, useEffect } from "react";

import styles from "./Auth.module.scss";
import { ChangePassSeccess } from "./components/ChangePassSeccess";
import { ChangePasswordForm } from "./components/ChangePasswordForm";
import { CheckEmailCode } from "./components/CheckEmailCode";
import { ConfirmationForm } from "./components/ConfirmationForm";
import { LoginForm } from "./components/LoginForm";
import { ResetPasswordForm } from "./components/ResetPasswordForm";
import { SignUpComplitedForm } from "./components/SignUpComplitedForm";
import { SignUpForm } from "./components/SignUpForm";

import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";
import { useAppDispatch, useAppSelector } from "../../store/hooks/hooks";
import { resetAuthSteps, selectAuthStep } from "../../store/slices/authSlice";
import { EAuthSteps } from "../../types/formTypes";
import { CustomButton } from "../FormFields/CustomButton";

interface IProps {
  onClose: () => void;
}

export const Auth = forwardRef<HTMLDivElement, IProps>(({ onClose }, ref) => {
  const dispatch = useAppDispatch();

  const authStep = useAppSelector(selectAuthStep);

  useEffect(() => {
    return () => {
      dispatch(resetAuthSteps());
    };
  }, []);

  return (
    <div ref={ref} className={styles.root}>
      <div className={styles.closeWrapper}>
        <CustomButton theme="unstyled" onClick={onClose}>
          <CloseIcon className={styles.closeIcon} />
        </CustomButton>
      </div>
      {authStep === EAuthSteps.Login && <LoginForm onClose={onClose} />}
      {authStep === EAuthSteps["Sign up"] && <SignUpForm />}
      {authStep === EAuthSteps.Confirmation && <ConfirmationForm />}
      {authStep === EAuthSteps["Registration completed!"] && (
        <SignUpComplitedForm onClose={onClose} />
      )}
      {authStep === EAuthSteps["Reset password"] && <ResetPasswordForm />}
      {authStep === EAuthSteps["Check your email"] && <CheckEmailCode />}
      {authStep === EAuthSteps["Change password"] && <ChangePasswordForm />}
      {authStep === EAuthSteps["Password changed successfully!"] && (
        <ChangePassSeccess onClose={onClose} />
      )}
    </div>
  );
});
