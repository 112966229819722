import { useFormik } from "formik";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import * as yup from "yup";

import { resetPassword } from "../../../../api";
import resetImg from "../../../../assets/img/resetPassword.png";
import { AUTH_FORMS } from "../../../../constants/AUTH_FORMS";
import { MESSAGES } from "../../../../constants/MESSAGES";
import { useAppDispatch } from "../../../../store/hooks/hooks";
import { setAuthStep } from "../../../../store/slices/authSlice";
import { EAuthSteps } from "../../../../types/formTypes";
import { createErrorMessage } from "../../../../utils/createErrorMessage";
import { decodeToken } from "../../../../utils/decodeToken";
import { CustomInput } from "../../../FormFields/CustomInput";
import { FormVerticalWrapper } from "../../../FormFields/FormVerticalWrapper";
import { FormImg } from "../FormImg";
import { FormSubmitWrapper } from "../FormSubmitWrapper";
import { FormTitle } from "../FormTitle";

export const ResetPasswordForm = () => {
  const [, setToken] = useCookies<string>(["tempToken"]);

  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .matches(
          /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
          MESSAGES.EMAIL
        )
        .required(MESSAGES.REQUIRED),
    }),
    onSubmit: async (data) => {
      try {
        const resp = await resetPassword(data);

        const tempToken = decodeToken(resp.data.data.validateToken);

        setToken("tempToken", tempToken.token, {
          expires: tempToken.expDate,
          path: "/",
        });

        dispatch(setAuthStep(EAuthSteps["Check your email"]));
      } catch (error: any) {
        toast.error(createErrorMessage(error));
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormVerticalWrapper>
        <FormVerticalWrapper gap={32} align="center">
          <FormImg src={resetImg} />
          <FormTitle
            text={AUTH_FORMS.NAMES.RESET_PASSWORD}
            description={AUTH_FORMS.SUBTITLES.RESET_PASSWORD}
          />
          <FormVerticalWrapper gap={24}>
            <CustomInput
              type="email"
              label="Email"
              id="email"
              name="email"
              placeholder="Enter your Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.errors.email}
            />
          </FormVerticalWrapper>
        </FormVerticalWrapper>
        <FormSubmitWrapper
          btnText={AUTH_FORMS.BTNS.SEND_CODE}
          isDisbledSubmit={!formik.dirty || !formik.isValid}
        />
      </FormVerticalWrapper>
    </form>
  );
};
