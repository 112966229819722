import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { MultiValue, SingleValue } from "react-select";
import { toast } from "react-toastify";
import * as yup from "yup";

import { ROLES } from "./const";
import styles from "./ProfileSettingsForm.module.scss";

import { updateUserProfile } from "../../../../api/user/user";
import { MESSAGES } from "../../../../constants/MESSAGES";
import { useUserData } from "../../../../hooks/useUserProfile";
import { createErrorMessage } from "../../../../utils/createErrorMessage";
import { CustomButton } from "../../../FormFields/CustomButton";
import { CustomInput } from "../../../FormFields/CustomInput";
import { CustomSelect } from "../../../FormFields/CustomSelect";
import { IOptions } from "../../../FormFields/CustomSelect/types";
import { Loader } from "../../../Loader";

export const ProfileSettingsForm = () => {
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    role: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const { user, refetch } = useUserData();

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: yup.object({
      firstName: yup
        .string()
        .matches(/^[A-Za-z]+$/, MESSAGES.ONLYLATIN)
        .max(60, MESSAGES.maxLength(60)),
      lastName: yup
        .string()
        .matches(/^[A-Za-z]+$/, MESSAGES.ONLYLATIN)
        .max(60, MESSAGES.maxLength(60)),
      email: yup
        .string()
        .matches(
          /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
          MESSAGES.EMAIL
        )
        .required(MESSAGES.REQUIRED),
    }),
    onSubmit: (data) => {
      setIsLoading(true);

      try {
        updateUserProfile({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          companyName: data.company,
          ...(data.role && { roleId: Number(data.role) }),
        });

        setTimeout(() => {
          refetch().then(() => {
            setIsLoading(false);
          });
        }, 500);
      } catch (error) {
        setIsLoading(false);
        toast.error(createErrorMessage(error));
      }
    },
  });

  const onSelectChange = (
    newValue: MultiValue<IOptions> | SingleValue<IOptions>
  ) => {
    if (newValue) {
      formik.setFieldValue("role", (newValue as IOptions).value);
    }
  };

  useEffect(() => {
    if (user?.data.data) {
      setInitialValues({
        firstName: user?.data.data.firstName || "",
        lastName: user?.data.data.lastName || "",
        email: user?.data.data.email || "",
        company: user?.data.data.companyName || "",
        role: user?.data.data.roleId?.toString() || "",
      });
    }
  }, [user]);

  return (
    <form onSubmit={formik.handleSubmit} className={styles.root}>
      <div className={styles.fieldsWrapper}>
        <CustomInput
          id="firstName"
          name="firstName"
          label="First Name"
          placeholder="Enter your First Name"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={formik.errors.firstName as string}
        />
        <CustomInput
          id="lastName"
          name="lastName"
          label="Last Name"
          placeholder="Enter your Last Name"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={formik.errors.lastName as string}
        />
        <CustomInput
          id="email"
          name="email"
          label={
            <>
              Email <span className={styles.emailStar}>*</span>
            </>
          }
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.errors.email as string}
        />
        <CustomInput
          id="company"
          name="company"
          label="Company"
          placeholder="Enter your company"
          value={formik.values.company}
          onChange={formik.handleChange}
        />
        <CustomSelect
          label="Role"
          placeholder="Select your role"
          value={
            ROLES.find((item) => item.value === formik.values.role) || undefined
          }
          onChange={onSelectChange}
          options={ROLES}
        />
      </div>
      <CustomButton
        type="submit"
        className={styles.submitBtn}
        isDisabled={!formik.dirty || !formik.isValid || isLoading}
      >
        {isLoading && <Loader />}
        {!isLoading && "Save"}
      </CustomButton>
    </form>
  );
};
