import { useCookies } from "react-cookie";

import styles from "./TemplateChooserDemo.module.scss";

import { Description } from "../../../Description";
import { Template } from "../../../Template";
import { Title } from "../../../Title";

export const TemplateChooserDemo = () => {
  const [token] = useCookies(["accessToken"]);

  return (
    <div className={styles.root}>
      <div>
        <Title tagName={"h3"}>Templates</Title>
        <Description theme={"t2"} className={styles.description}>
          Select and download a template
        </Description>
      </div>

      <Template
        titleTemplate={"DEMO Data Project Summary Template.xlsx"}
        textDropdown={"Login to download Demo template to your computer"}
        downloadBtn={false}
      />

      <div className={styles.afterLogin}>
        {!token.accessToken && (
          <Title tagName={"h5"}>Available after Login</Title>
        )}
        <div className={styles.wrapper}>
          <Template
            titleTemplate={"Project Summary Template.xlsx"}
            textDropdown={
              "Use this template to list your projects names, start, finish dates, statuses, progress."
            }
            tokenLogin={!!token.accessToken}
          />
          <Template
            titleTemplate={"Milestones Summary Template.xlsx"}
            textDropdown={
              "List your tasks, task types, dates, responsible resources and reference to projects. "
            }
            tokenLogin={!!token.accessToken}
          />
          <Template
            titleTemplate={"Issues Summary Template.xlsx"}
            textDropdown={
              "List project issues, descriptions, due dates, urgency to resolve, statuses, etc."
            }
            tokenLogin={!!token.accessToken}
          />
        </div>
      </div>
    </div>
  );
};
