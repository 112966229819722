import { forwardRef } from "react";

import styles from "./CanselUpdateModal.module.scss";

import { Description } from "../../../Description";
import { CustomButton } from "../../../FormFields/CustomButton";
import { Title } from "../../../Title";

interface IProps {
  onClose: () => void;
  showPreview: (value: string) => void;
}

export const CanselUpdateModal = forwardRef<HTMLDivElement, IProps>(
  ({ onClose, showPreview }, ref) => {
    const onContinueClick = () => {
      showPreview("preview");
    };

    return (
      <div ref={ref} className={styles.root}>
        <div className={styles.wrapper}>
          <Title tagName={"h3"}>Cancel update?</Title>
          <Description theme={"t2"}>
            Are you sure you want to cancel the download of new reports? All
            unsaved data will be lost.
          </Description>
        </div>
        <div className={styles.wrapperBtn}>
          <CustomButton
            theme={"secondary"}
            className={styles.btn}
            onClick={onClose}
          >
            Cancel update
          </CustomButton>
          <CustomButton className={styles.btn} onClick={onContinueClick}>
            Continue
          </CustomButton>
        </div>
      </div>
    );
  },
);
