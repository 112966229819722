import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { useUserData } from "./useUserProfile";

import { getCurrentInfoFiles } from "../api/reports/reports";
import { createErrorMessage } from "../utils/createErrorMessage";

export const useCurrentInfoFiles = () => {
  const { organizationId, tenantId } = useUserData();

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ["getReportsType", tenantId, organizationId],
    queryFn: async () => {
      try {
        const res = await getCurrentInfoFiles(organizationId, tenantId);
        return res.data;
      } catch (error) {
        toast.error(createErrorMessage(error));
      }
    },
    enabled: !!tenantId,
  });

  return {
    data: data?.data,
    isLoading,
    error,
    refetch,
  };
};
