import styles from "./PreviewTemplate.module.scss";

import { useAppSelector } from "../../../store/hooks/hooks";
import { selecSlicePreview } from "../../../store/slices/previewSlice";
import { DoughnutChart } from "../../DashBoards/DoughnutChart";
import { DoughnutChartIssues } from "../../DashBoards/DoughnutChartIssues";
import { PieChart } from "../../DashBoards/PieChart";
import { Title } from "../../Title";

export const PreviewTemplate = () => {
  const preview = useAppSelector(selecSlicePreview);
  const titleReport = preview.header.reportName;

  return (
    <div className={styles.root}>
      <Title tagName={"h4"}>{titleReport}</Title>
      {titleReport === "Project Summary" && (
        <DoughnutChart
          className={styles.doughnutStyle}
          dataList={preview.budgetCostByBusinessSponsor.data}
          radius={170}
          format={preview.budgetCostByBusinessSponsor.format}
        />
      )}
      {titleReport === "Milestone Summary" && (
        <PieChart
          dataList={preview.countByCompleted.data}
          className={styles.wrapperPie}
          legendTitle={preview.countByCompleted.legendTitle}
          title={preview.countByCompleted.title}
        />
      )}
      {titleReport === "Issue Summary" && (
        <DoughnutChartIssues
          dataList={preview.countByIssueStatus.data}
          className={styles.doughnutIssuesStyle}
          title={preview.countByIssueStatus.title}
          legendTitle={preview.countByIssueStatus.legendTitle}
        />
      )}
    </div>
  );
};
