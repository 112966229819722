import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { uploadAndGetWorksheets } from "../../api/template/template";
import { RootState } from "../index";

interface IInitialState {
  data: any | null;
  isLoading: boolean;
  isError: boolean;
}

const initialState: IInitialState = {
  data: null,
  isLoading: false,
  isError: false,
};

interface FetchParseResultParams {
  file: any;
  fileName: string;
  tenantId: string;
  organizationId: string;
}

export const fetchParseResult = createAsyncThunk(
  "parseResult",
  async (params: FetchParseResultParams) => {
    const response = await uploadAndGetWorksheets(params);

    return response.data.data;
  },
);

export const parseResultSlice = createSlice({
  name: "parseResult",
  initialState,
  reducers: {
    setParseResult(state, action) {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchParseResult.pending, (state) => {
        state.isError = false;
        state.isLoading = true;
      })
      .addCase(fetchParseResult.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isError = false;
        state.isLoading = false;
      })
      .addCase(fetchParseResult.rejected, (state) => {
        state.isError = true;
        state.isLoading = false;
      });
  },
});

export const selectParseResult = (state: RootState) => {
  return state.parseResult.data
};
export const selectParseResultIsLoading = (state: RootState) =>
  state.parseResult.isLoading;
export const selectParseResultIsError = (state: RootState) =>
  state.parseResult.isError;

export const { setParseResult } = parseResultSlice.actions;