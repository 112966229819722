import { nanoid } from "@reduxjs/toolkit";
import classNames from "classnames";
import { ChangeEvent, FC } from "react";
import InputMask from "react-input-mask";

import styles from "./CustomDateInput.module.scss";

interface IProps {
  value?: string;
  id?: string;
  name: string;
  label?: string;
  className?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const CustomDateInput: FC<IProps> = ({
  value,
  id = nanoid(),
  name,
  label,
  className,
  onChange,
}) => {
  console.log("INPUT", value);

  return (
    <div className={classNames(styles.root, className)}>
      {label && <label htmlFor={id}>{label}</label>}
      <InputMask
        id={id}
        name={name}
        mask="99/99/9999"
        placeholder="MM/DD/YYYY"
        className={styles.input}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};
