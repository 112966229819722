import styles from "./Copyright.module.scss";

export const Copyright = () => {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <div className={styles.root}>Friday-Report.com © {getCurrentYear()}</div>
  );
};
