import { useState } from "react";
import { useCookies } from "react-cookie";

import styles from "./TemplateUpload.module.scss";

import { useAppSelector } from "../../../../store/hooks/hooks";
import { selectDemo } from "../../../../store/slices/uploadFileSlice";
import { CustomValueTab } from "../../../CustomValueTab";
import { Title } from "../../../Title";
import { DemoData } from "../DemoData";
import { UploadData } from "../UploadData";

export const TemplateUpload = () => {
  const isDemo = useAppSelector(selectDemo);
  const [token] = useCookies(["accessToken"]);
  const [tabValue, setTabValue] = useState(
    !token.accessToken ? "demo" : isDemo || "upload",
  );

  const tabs = [
    {
      id: "tab-1",
      tab: "Demo data",
      value: "demo",
      component: <DemoData />,
      stylesOrder: token.accessToken && styles.order,
    },
    {
      id: "tab-2",
      tab: "Upload your data",
      value: "upload",
      component: <UploadData />,
    },
  ];

  const selectedTab = tabs.find((tab) => tab.value === tabValue);

  return (
    <div className={styles.root}>
      <Title tagName={"h3"}>Upload a template</Title>
      <CustomValueTab
        tabs={tabs}
        tabValue={tabValue}
        setTabValue={setTabValue}
      />
      <div className={styles.wrapperBlocks}>
        {selectedTab && selectedTab.component}
      </div>
    </div>
  );
};
