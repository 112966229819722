import { ReactComponent as Settings } from "../../../../assets/icons/settings-icon.svg";
import { ReactComponent as User } from "../../../../assets/icons/user-icon.svg";
import { PATHS } from "../../../../constants/PATHS";

export const avatarMenuNavigation = [
  {
    id: "1",
    title: "Profile settings",
    icon: <User />,
    link: PATHS.PROFILE_SETTINGS,
  },
  {
    id: "2",
    title: "Account settings",
    icon: <Settings />,
    link: PATHS.ACCOUNT_SETTINGS,
  },
];
