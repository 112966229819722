export const TARIFFS = [
  {
    id: "t1",
    title: "Free",
    subtitle: "Jump seat",
    benefits: [
      "Complete Data Warehouse",
      "Secure Data Sharing across regions / clouds",
      "5 Reports",
    ],
    included: "1GB Included",
    processing: "Standard Processing Included",
    price: "0",
    period: "Year",
    isActive: true,
  },
  {
    id: "t2",
    title: "Standard",
    subtitle: "Coach",
    benefits: [
      "Complete SQL data warehouse",
      "Secure Data Sharing across regions / clouds",
      "Premier Support 24 x 365",
      "1 day of time travel",
      "Always-on enterprise grade encryption in transit and at rest",
      "Customer-dedicated virtual warehouses",
      "Federated authentication",
      "Database replication",
      "Create your own Data Exchange",
      "Data Marketplace access",
      "Additional 10 Reports",
    ],
    included: "10GB Included",
    processing: "Standard Processing Included",
    price: "10",
    period: "Month",
    isActive: false,
  },
  {
    id: "t3",
    title: "Enterprise",
    subtitle: "Premium",
    benefits: [
      "Standard +",
      "Multi-cluster warehouse",
      "Up to 90 days of time travel",
      "Annual rekeying of all encrypted data",
      "Materialized views",
      "Search Optimization Service",
      "Dynamic Data Masking",
      "External Data Tokenization",
    ],
    included: "100GB Included",
    processing: "Advanced Processing Included",
    price: "55,000",
    period: "Year",
    isActive: false,
  },
  {
    id: "t4",
    title: "Business Class",
    benefits: [
      "Enterprise +",
      "HIPAA support",
      "PCI compliance",
      "Tri-Secret Secure using customer-managed keys",
      "AWS PrivateLink support",
      "Azure Private Link support",
      "Google Cloud Private Service Connect support",
      "Database failover and failback for business continuity",
      "External Functions - AWS API Gateway",
      "Private Endpoints support",
    ],
    included: "Unlimited",
    processing: "Unlimited Processing Included",
    price: "160,000",
    period: "Year",
    isActive: false,
  },
];
