import classNames from "classnames";
import { FC } from "react";
import { NavLink } from "react-router-dom";

import styles from "./MenuNavigation.module.scss";
import { sideMenuItem } from "./sideMenuNavigation";

interface IProps {
  isOpenMenu?: boolean;
  menuList: sideMenuItem[];
  className?: string;
  stylesLink?: string;
  isMenuOpen?: () => void;
}

export const MenuNavigation: FC<IProps> = ({
  isOpenMenu = true,
  menuList,
  className,
  stylesLink,
  isMenuOpen,
}) => {
  return (
    <div className={classNames(styles.root, className)}>
      <ul className={styles.tabs}>
        {menuList.map((item) => (
          <li key={item.id}>
            <NavLink
              to={item.link}
              onClick={isMenuOpen}
              className={({ isActive }) =>
                classNames(styles.labelTab, stylesLink, {
                  [styles.activeLink]: isActive,
                  [styles.labelTabClose]: !isOpenMenu,
                })
              }
            >
              <span className={styles.wrapper}>
                {item.icon}
                {isOpenMenu && item.title}
              </span>
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};
