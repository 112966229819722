import { FC, ReactNode } from "react";

import styles from "./FormVerticalWrapper.module.scss";

interface IProps {
  children: ReactNode;
  gap?: number;
  align?: "center";
}

export const FormVerticalWrapper: FC<IProps> = ({
  children,
  gap = 48,
  align,
}) => {
  return (
    <div className={styles.root} style={{ gap: gap, justifyContent: align }}>
      {children}
    </div>
  );
};
