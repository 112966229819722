/* eslint-disable max-len */
export const AUTH_FORMS = {
  NAMES: {
    LOGIN: "Login",
    SIGNUP: "Sign up",
    CONFIRMATION: "Confirmation",
    REGISTRATION_COMPLETED: "Registration completed!",
    RESET_PASSWORD: "Reset password",
    CHECK_YOUR_EMAIL: "Check your email",
    CHANGE_PASSWORD: "Change password",
    PASSWORD_CHANGED_SUCCESSFULLY: "Password changed successfully!",
  },
  ADDITIONAL_TEXT: {
    LOGIN: "Don’t have an account?",
    SIGNUP: "Already have an account?",
  },
  SUBTITLES: {
    CONFIRMATION:
      "An email with a confirmation code has been sent to the email address you provided. Please enter the received code below.",
    REGISTRATION_COMPLETED:
      "You have successfully registered on the platform. Now you can start using all its features",
    RESET_PASSWORD:
      "Enter the email address associated with your account and we will send you an email with a one-time code to reset your password.",
    CHECK_YOUR_EMAIL:
      "Enter the one-time code from the email that we sent to your email.",
    CHANGE_PASSWORD:
      "Your new password must be different from your previous passwords.",
    PASSWORD_CHANGED_SUCCESSFULLY:
      "Your password has been successfully reset. You can log in to your account using a new password.",
  },
  BTNS: {
    SEND_CODE: "Send code",
    CONFIRMATION: "Confirm code",
    SAVE_PASS: "Save new password",
    CONTINUE: "Continue",
    START: "Start",
  },
};
