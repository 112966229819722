import { ChartOptions } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { FC } from "react";
import { Bar } from "react-chartjs-2";

import barChart from "./barChart.json";
import styles from "./BarChart.module.scss";

import { DataItem } from "../../../types/previewTemplate";
import { Description } from "../../Description";

interface IProps {
  impact: DataItem[];
  likelihoodList: DataItem[];
  title?: string;
  legendTitle?: string;
  y: string[];
}

export const BarChart: FC<IProps> = ({
  impact,
  likelihoodList,
  title,
  legendTitle,
  y,
}) => {
  const options: ChartOptions<"bar"> = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        align: "start",
        title: {
          display: true,
          text: legendTitle,
          position: "start",
          color: "#000000",
          padding: 0,
          font: {
            size: 14,
            weight: 500,
          },
        },
        labels: {
          usePointStyle: true,
          boxWidth: 20,
          boxHeight: 20,
          padding: 24,
          color: "#000000",
        },
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          callback: (value) => y[+value] || value,
        },
      },
      x: {
        title: {
          display: true,
          text: "Issue Title",
          color: "#9E9E9E",
          font: {
            size: 14,
            weight: 400,
          },
          padding: 20,
        },
        ticks: {
          maxRotation: 0,
          minRotation: 0,
          autoSkip: false,
          padding: 4,
          font: {
            size: 12,
            lineHeight: "16px",
          },
          callback(tickValue, index) {
            const label = data.labels[index];

            const words = label.split(" ");
            const result = [];

            for (let i = 0; i < words.length; i++) {
              if (
                i < words.length - 1 &&
                words[i].length <= 10 &&
                words[i + 1].length <= 10
              ) {
                result.push(words[i] + " " + words[i + 1]);
                i++; // Пропускаем следующий элемент, так как уже объединили его
              } else {
                result.push(words[i]);
              }
            }

            return result;
          },
        },
      },
    },
  };

  const data = {
    labels: likelihoodList.map((item) => item.label),
    datasets: [
      {
        label: "Likelihood",
        data: likelihoodList?.map((item) => item.value),
        backgroundColor: "#F7CF6B",
        borderColor: "#F7CF6B",
      },
      {
        label: "Impact",
        data: impact?.map((item) => item.value),
        backgroundColor: "#9268F6",
        borderColor: "#9268F6",
      },
    ],
  };
  return (
    <div className={styles.root}>
      <Description theme="t3">{title}</Description>
      <div
        className={styles.wrapper}
        style={{ minWidth: `${100 * barChart.length + 100}px` }}
      >
        <Bar data={data} plugins={[ChartDataLabels]} options={options} />
      </div>
    </div>
  );
};
