import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ProjectHeader } from "./components/ProjectHeader";
import styles from "./DashBoardProject.module.scss";

import {
  getProjectDoughnutChartData,
  getProjectVerticalBarChartData,
} from "../../../api/projectSummary/projectSummary";
import { useUserData } from "../../../hooks/useUserProfile";
import { useAppSelector } from "../../../store/hooks/hooks";
import { selectCurrentId } from "../../../store/slices/stepSlice";
import {
  DataItem,
  FormatItem,
  VerticalItem,
} from "../../../types/previewTemplate";
import { Loader } from "../../Loader";
import { DoughnutChart } from "../DoughnutChart";
import { VerticalBarChart } from "../VerticalBarChart";

export const DashBoardProject = () => {
  const [doughnutChartData, setDoughnutChartData] = useState<DataItem[]>([]);
  const [verticalChartData, setVerticalChartData] = useState<VerticalItem>();
  const [format, setFormat] = useState();
  const [verticalFormat, setVerticalFormat] = useState<FormatItem>();
  const [isLoading, setIsLoading] = useState(false);

  const currentId = useAppSelector(selectCurrentId);

  const { organizationId, tenantId } = useUserData();
  const { reportId } = useParams();

  const maxDataValue = verticalChartData?.data
    ? Math.max(...verticalChartData.data.map((item) => item.value))
    : 0;
  const padding = maxDataValue * 0.1;

  useEffect(() => {
    setIsLoading(true);
    tenantId &&
      reportId &&
      (async () => {
        const doughnutChartData = await getProjectDoughnutChartData(
          currentId ? currentId : reportId,
          organizationId,
          tenantId
        );
        const verticalChartData = await getProjectVerticalBarChartData(
          currentId ? currentId : reportId,
          organizationId,
          tenantId
        );
        setDoughnutChartData(doughnutChartData.data.data.data);
        setVerticalChartData(verticalChartData.data.data);
        setFormat(doughnutChartData.data.data.format);
        setVerticalFormat(verticalChartData.data.data.format);
        setIsLoading(false);
      })();
  }, [tenantId, reportId, currentId]);

  return (
    <div className={styles.root}>
      <ProjectHeader />
      {isLoading && <Loader className={styles.loader} />}
      {!isLoading && (
        <div className={styles.barWrapper}>
          <VerticalBarChart
            dataList={verticalChartData?.data}
            options={{
              responsive: true,
              plugins: {
                datalabels: {
                  color: "black",
                  formatter: (value) =>
                    `${verticalFormat?.currency}${value.toFixed(verticalFormat?.decimal)} ${verticalFormat?.unit}`,
                  labels: {
                    title: {
                      font: {
                        weight: 400,
                        size: 12,
                      },
                    },
                  },
                  anchor: "end",
                  align: -90,
                },
                legend: {
                  display: false,
                },
              },
              scales: {
                y: {
                  max: maxDataValue + padding,
                  ticks: {
                    stepSize: 2,
                    callback: (value) =>
                      `${verticalFormat?.currency}${(+value).toFixed(verticalFormat?.decimal)} ${verticalFormat?.unit}`,
                  },
                  title: {
                    display: true,
                    text: verticalChartData?.x,
                    color: "#9E9E9E",
                    font: {
                      size: 14,
                      weight: 400,
                    },
                    padding: {
                      bottom: 15,
                    },
                  },
                },

                x: {
                  title: {
                    display: true,
                    text: verticalChartData?.y,
                    color: "#9E9E9E",
                    font: {
                      size: 14,
                      weight: 400,
                    },
                    padding: {
                      top: 20,
                    },
                  },
                },
              },
              clip: false,
            }}
            title={verticalChartData?.title}
          />
          <DoughnutChart
            dataList={doughnutChartData}
            format={format}
            radius={130}
            className={styles.wrapper}
          />
        </div>
      )}
    </div>
  );
};
