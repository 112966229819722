import classNames from "classnames";
import { FC } from "react";

import styles from "./CustomItem.module.scss";

import { CustomCheckbox } from "../../../../CustomCheckbox";
import { IOptions } from "../../../types";

interface IProps {
  option: IOptions;
  checked: boolean;
  onClick: (option: IOptions) => void;
}

export const CustomItem: FC<IProps> = ({ option, checked, onClick }) => {
  const handleClick = () => {
    onClick(option);
  };

  return (
    <div className={classNames(styles.root, { [styles.selected]: checked })}>
      <CustomCheckbox
        labelStyles={styles.checkbox}
        checked={checked}
        onChange={handleClick}
      />
      <span>{option.label}</span>
    </div>
  );
};
