import { useQueryClient } from "@tanstack/react-query";
import { forwardRef } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./Logout.module.scss";

import logoutImg from "../../../../assets/img/logout.png";
import { PATHS } from "../../../../constants/PATHS";
import { useAppDispatch } from "../../../../store/hooks/hooks";
import {
  setTemplateSPage,
  setTemplateStep,
} from "../../../../store/slices/stepSlice";
import { ETemplatePage, ETemplateStep } from "../../../../types/steps";
import { deleteCookie } from "../../../../utils/cookie/getCookie";
import { Description } from "../../../Description";
import { CustomButton } from "../../../FormFields/CustomButton";
import { Title } from "../../../Title";
import { FormImg } from "../FormImg";

interface IProps {
  onClose: () => void;
}

export const Logout = forwardRef<HTMLDivElement, IProps>(({ onClose }, ref) => {
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onLogOutClick = () => {
    deleteCookie("accessToken");
    deleteCookie("refreshToken");
    queryClient.clear();

    navigate(PATHS.BASE_PATH);
    dispatch(setTemplateStep(ETemplateStep.step1));
    dispatch(setTemplateSPage(ETemplatePage.ConnectPage));
    onClose();
  };

  return (
    <div ref={ref} className={styles.root}>
      <FormImg src={logoutImg} />
      <div className={styles.wrapperTitle}>
        <Title tagName={"h3"}>Log out of the system?</Title>
        <Description theme={"t2"}>
          Are you sure you want to log out the platform?
        </Description>
      </div>
      <div className={styles.wrapperBtn}>
        <CustomButton
          theme={"secondary"}
          onClick={onClose}
          className={styles.btn}
        >
          Cancel
        </CustomButton>
        <CustomButton onClick={onLogOutClick} className={styles.btn}>
          Log out
        </CustomButton>
      </div>
    </div>
  );
});
