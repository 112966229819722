import { Formik } from "formik";
import { RefObject, forwardRef, useState } from "react";
import { useLocation } from "react-router-dom";
import generatePDF from "react-to-pdf";
import { toast } from "react-toastify";
import * as yup from "yup";

import styles from "./ShareReportModal.module.scss";

import { shareReport } from "../../../../../api/reports/reports";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/close-icon.svg";
import { MESSAGES } from "../../../../../constants/MESSAGES";
import { useUserData } from "../../../../../hooks/useUserProfile";
import { createErrorMessage } from "../../../../../utils/createErrorMessage";
import { CustomButton } from "../../../../FormFields/CustomButton";
import { ReportSent } from "../ReportSent";
import { ShareReport } from "../ShareReport";

interface IProps {
  layoutRef: RefObject<HTMLDivElement>;
  onClose: () => void;
}

export const ShareReportModal = forwardRef<HTMLDivElement, IProps>(
  ({ layoutRef, onClose }, ref) => {
    const [sendEmail, setSendEmail] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { organizationId, tenantId } = useUserData();
    const location = useLocation();

    return (
      <div className={styles.root} ref={ref}>
        <div className={styles.closeWrapper}>
          <CustomButton theme="unstyled" onClick={onClose}>
            <CloseIcon className={styles.closeIcon} />
          </CustomButton>
        </div>
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={yup.object({
            email: yup
              .string()
              .matches(
                /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
                MESSAGES.EMAIL
              )
              .required(MESSAGES.REQUIRED),
          })}
          onSubmit={async (data) => {
            setIsLoading(true);

            const urlParts = location.pathname.split("/")[2];
            const formattedTitle = urlParts.replace(/([A-Z])/g, ' $1').trim();

            const pdf = await generatePDF(layoutRef, {
              filename: urlParts,
              page: {
                orientation: "landscape",
                margin: 5,
              },
              method: "build",
            });

            const pdfBytes = pdf.output("arraybuffer");

            const blobPDF = new Blob([pdfBytes], {
              type: "application/pdf",
            });

            try {
              const res = await shareReport(
                blobPDF,
                urlParts,
                data.email,
                organizationId,
                tenantId,
                formattedTitle
              );

              setSendEmail(true);

              setIsLoading(false);
            } catch (error: any) {
              toast.error(createErrorMessage(error));

              setIsLoading(false);
            }
          }}
        >
          <>
            {!sendEmail && <ShareReport isLoading={isLoading} />}
            {sendEmail && <ReportSent onClose={onClose} />}
          </>
        </Formik>
      </div>
    );
  }
);
