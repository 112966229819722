import styles from "./ConnectDataText.module.scss";

import { Description } from "../../../Description";
import { Title } from "../../../Title";

export const ConnectDataText = () => {
  return (
    <div className={styles.root}>
      <Title tagName={"h3"}>Connect your Data</Title>
      <Description theme={"t2"} className={styles.text}>
        Use your own data and have us create reports for you. Use Excel or other
        Project tools to connect to Friday Report.
      </Description>
    </div>
  );
};
