import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import styles from "./ProjectHeader.module.scss";

import { getProjectHeader } from "../../../../../api/projectSummary/projectSummary";
import { useUserData } from "../../../../../hooks/useUserProfile";
import { useAppSelector } from "../../../../../store/hooks/hooks";
import { selectCurrentId } from "../../../../../store/slices/stepSlice";
import { HeaderDashBoard } from "../../../../../types/previewTemplate";
import { Loader } from "../../../../Loader";
import { HeaderLogo } from "../../../HederLogo";
import { InfoTiles } from "../InfoTiles";

export const ProjectHeader = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [headerList, setHeaderList] = useState<HeaderDashBoard>();

  const infoReportList = [
    { id: "1", value: headerList?.count, title: "Count of Projects" },
    { id: "2", value: `$${headerList?.budgetCost}K`, title: "Budget Cost" },
    { id: "3", value: `${headerList?.average}%`, title: "Average %" },
    { id: "4", value: `$${headerList?.actualCost}K`, title: "Actual Cost" },
  ];

  const currentId = useAppSelector(selectCurrentId);

  const { organizationId, tenantId } = useUserData();
  const { reportId } = useParams();

  useEffect(() => {
    setIsLoading(true);
    tenantId &&
      reportId &&
      (async () => {
        const headerInfo = await getProjectHeader(
          currentId ? currentId : reportId,
          organizationId,
          tenantId,
        );
        setHeaderList(headerInfo.data.data);
        setIsLoading(false);
      })();
  }, [tenantId, reportId, currentId]);

  return (
    <div className={styles.root}>
      <HeaderLogo title={headerList?.reportName} />
      {isLoading && <Loader className={styles.loader} />}
      {!isLoading && (
        <div className={styles.infoWrapper}>
          {infoReportList.map((item) => (
            <InfoTiles key={item.id} title={item.title} value={item.value} />
          ))}
        </div>
      )}
    </div>
  );
};
