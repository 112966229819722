import classNames from "classnames";
import { useFormik } from "formik";
import { FC, RefObject, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { MultiValue, SingleValue } from "react-select";

import styles from "./DashBoardHeader.module.scss";

import { ReactComponent as Arrow } from "../../../../../assets/icons/arrow-icon.svg";
import { ReactComponent as CheckMark } from "../../../../../assets/icons/checkmark-icon.svg";
import { ReactComponent as Download } from "../../../../../assets/icons/down-icon.svg";
import { ReactComponent as Share } from "../../../../../assets/icons/share-icon.svg";
import { useClickOutside } from "../../../../../hooks/useClickOutside";
import { useAppDispatch } from "../../../../../store/hooks/hooks";
import { setCurrentId } from "../../../../../store/slices/stepSlice";
import { OptionsType } from "../../../../../types/dashBoard";
import { formatDate, formatTimeAsUtc } from "../../../../../utils/formatDate";
import { CustomButton } from "../../../../FormFields/CustomButton";
import { CustomSelect } from "../../../../FormFields/CustomSelect";
import { IOptions } from "../../../../FormFields/CustomSelect/types";
import { ReactPortal } from "../../../../ReactPortal";
import { ReactPortalSubstrate } from "../../../../ReactPortalSubstrate";
import { ReportDownload } from "../ReportDownload";
import { ShareReportModal } from "../SharePeportModal";

interface IProps {
  layoutRef: RefObject<HTMLDivElement>;
  option: OptionsType[];
}

export const DashBoardHeader: FC<IProps> = ({ layoutRef, option }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDownMenuOpen, setIsDownMenuOpen] = useState(false);

  const dispatch = useAppDispatch();

  const { reportId } = useParams();

  const containerRef = useRef<HTMLDivElement>(null);
  const shareRef = useRef<HTMLDivElement>(null);
  const downloadBtnRef = useRef<HTMLButtonElement>(null);
  const downloadRef = useRef<HTMLDivElement>(null);

  const onShareClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onShareClose = () => {
    setIsMenuOpen(false);
  };

  const onDownloadClick = () => {
    setIsDownMenuOpen(!isDownMenuOpen);
  };

  const onDownloadClose = () => {
    setIsDownMenuOpen(false);
  };

  useClickOutside(shareRef, onShareClose);
  useClickOutside([downloadRef, downloadBtnRef], onDownloadClose);

  const getUploadedDateAndTimeForReport = (
    reportId: string,
    options: OptionsType[]
  ) => {
    const matchingOption = options.find(
      (item) => item.fileMetaInfoId === reportId
    );
    if (matchingOption) {
      dispatch(setCurrentId(matchingOption.fileMetaInfoId));

      const uploadedOn = matchingOption.uploadedOn;
      return `${formatDate(uploadedOn)} ${formatTimeAsUtc(uploadedOn)} (UTC)`;
    } else {
      return "";
    }
  };

  const formik = useFormik({
    initialValues: {
      reportTitle: "",
      selectedReportId: option[0]?.fileMetaInfoId || null,
    },
    onSubmit: () => {},
  });

  const onSelectChange = (
    newValue: MultiValue<IOptions> | SingleValue<IOptions>
  ) => {
    if (newValue) {
      const selectedOption = newValue as IOptions;
      formik.setFieldValue("reportTitle", selectedOption.value);
      formik.setFieldValue("selectedReportId", selectedOption.id);
      if (selectedOption.id) {
        dispatch(setCurrentId(selectedOption.id));
      }
    }
  };

  useEffect(() => {
    if (reportId) {
      formik.setFieldValue(
        "reportTitle",
        getUploadedDateAndTimeForReport(reportId, option)
      );
    }
    return () => {
      dispatch(setCurrentId(""));
    };
  }, [option, reportId]);

  return (
    <div className={styles.root}>
      <div className={styles.wrapperSelect}>
        <CustomSelect
          label="Report versions"
          placeholder="Select versions"
          selectTheme={"withoutBorder"}
          value={
            formik.values.reportTitle
              ? {
                  value: formik.values.reportTitle,
                  label: formik.values.reportTitle,
                }
              : undefined
          }
          onChange={onSelectChange}
          options={option?.map((item) => ({
            value:
              formatDate(item.uploadedOn) +
              " " +
              formatTimeAsUtc(item.uploadedOn) +
              " " +
              "(UTC)",
            label:
              formatDate(item.uploadedOn) +
              " " +
              formatTimeAsUtc(item.uploadedOn) +
              " " +
              "(UTC)",
            id: item.fileMetaInfoId,
            icon: <CheckMark />,
          }))}
        />
      </div>
      <div className={styles.wrapper} ref={containerRef}>
        <CustomButton
          theme={"unstyled"}
          onClick={onShareClick}
          className={styles.shareBtn}
        >
          <Share /> Share
        </CustomButton>
        <CustomButton
          ref={downloadBtnRef}
          theme={"unstyled"}
          onClick={onDownloadClick}
          className={styles.shareBtn}
        >
          <Download /> Download{" "}
          <Arrow
            className={classNames(styles.arrow, {
              [styles.openMenu]: isDownMenuOpen,
            })}
          />
        </CustomButton>
      </div>

      {isDownMenuOpen && (
        <ReactPortal container={containerRef}>
          <ReportDownload
            ref={downloadRef}
            layoutRef={layoutRef}
            onCloseClick={onDownloadClose}
          />
        </ReactPortal>
      )}

      {isMenuOpen && (
        <ReactPortal>
          <ReactPortalSubstrate className={styles.portalWrapper}>
            <ShareReportModal
              ref={shareRef}
              onClose={onShareClose}
              layoutRef={layoutRef}
            />
          </ReactPortalSubstrate>
        </ReactPortal>
      )}
    </div>
  );
};
