import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "../index";

interface PreviewState {
  data: any | null;
}

const initialState: PreviewState = {
  data: null
};

export const previewSlice = createSlice({
  name: "preview",
  initialState,
  reducers: {
    setSlicePreview(state, action) {
      state.data = action.payload;
    }
  }
});

export const selecSlicePreview = (state: RootState) => state.preview.data;

export const { setSlicePreview } = previewSlice.actions;