import { RefObject, forwardRef } from "react";
import { useLocation } from "react-router-dom";
import generatePDF from "react-to-pdf";
import { toast } from "react-toastify";

import styles from "./ReportDownload.module.scss";

import { getExcelFile } from "../../../../../api/reports/reports";
import { MESSAGES } from "../../../../../constants/MESSAGES";
import { useUserData } from "../../../../../hooks/useUserProfile";
import { useAppSelector } from "../../../../../store/hooks/hooks";
import { selectCurrentId } from "../../../../../store/slices/stepSlice";
import { Description } from "../../../../Description";
import { CustomButton } from "../../../../FormFields/CustomButton";

interface IProps {
  layoutRef: RefObject<HTMLDivElement>;
  onCloseClick: () => void;
}

export const ReportDownload = forwardRef<HTMLDivElement, IProps>(
  ({ layoutRef, onCloseClick }, ref) => {
    const currentId = useAppSelector(selectCurrentId);

    const { organizationId, tenantId } = useUserData();

    const location = useLocation();

    const downPDF = () => {
      onCloseClick();

      setTimeout(
        () =>
          generatePDF(layoutRef, {
            filename: `${location.pathname.split("/")[2]}`,
            page: {
              orientation: "landscape",
              margin: 5,
            },
          }),
        300
      );
    };

    const createFileNameFromURL = () => {
      const match = location.pathname.match(/\/reports\/([^/]+)\//);

      if (match && match[1]) {
        let name = match[1];

        name = name
          .replace(/([A-Z])/g, " $1")
          .replace(/([a-z])([A-Z])/g, "$1 $2")
          .trim()
          .replace(/\b\w/g, (char) => char.toUpperCase());

        return `${name}.xlsx`;
      }

      return "File.xlsx";
    };

    const downExcel = async () => {
      onCloseClick();

      try {
        const res = await getExcelFile(currentId, organizationId, tenantId);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", createFileNameFromURL());
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        toast.error(MESSAGES.DATA_LOADING_ERROR);
      }
    };

    const downPowerBi = () => {
      onCloseClick();
    };

    const downloadList = [
      {
        id: "1",
        title: "PDF",
        onClick: downPDF,
      },
      {
        id: "2",
        title: "Excel",
        onClick: downExcel,
      },
      {
        id: "3",
        title: "PowerBi",
        onClick: downPowerBi,
      },
    ];

    return (
      <div ref={ref} className={styles.root}>
        <ul className={styles.tabs}>
          {downloadList.map((item) => (
            <li key={item.id}>
              <CustomButton
                theme={"unstyled"}
                className={styles.labelTab}
                onClick={item.onClick}
                isDisabled={item.title === "PowerBi"}
              >
                <span className={styles.wrapper}>
                  {item.title}
                  {item.title === "PowerBi" && (
                    <Description theme="t7" className={styles.description}>
                      {"(Сoming soon)"}
                    </Description>
                  )}
                </span>
              </CustomButton>
            </li>
          ))}
        </ul>
      </div>
    );
  }
);
