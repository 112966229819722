import { forwardRef } from "react";

import styles from "./HelpDropdown.module.scss";

import { CustomLink } from "../../../CustomLink";
import { Description } from "../../../Description";

export const HelpDropdown = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div ref={ref} className={styles.root}>
      <Description theme={"t7"} className={styles.text}>
        If you are experiencing difficulties with using our platform or if you
        need technical assistance, we are always here to help you.
      </Description>
      <CustomLink
        link="mailto:support@friday-report.com"
        className={styles.liink}
      >
        support@friday-report.com
      </CustomLink>
    </div>
  );
});
