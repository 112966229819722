import styles from "./ComingSoonData.module.scss";
import { dataList } from "./ComingSoonDataItem";

import { Title } from "../../../Title";
import { ComingSoonItem } from "../ComingSoonItem";

export const ComingSoonData = () => {
  return (
    <div className={styles.root}>
      <Title tagName={"h5"} className={styles.title}>
        Coming Soon
      </Title>
      <div className={styles.dataList}>
        {dataList.map((item) => (
          <ComingSoonItem key={item.id} icon={item.icon} title={item.title} />
        ))}
      </div>
    </div>
  );
};
