import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ETemplatePage, ETemplateStep } from "../../types/steps";
import { RootState } from "../index";

interface IInitialState {
  step: ETemplateStep;
  page: ETemplatePage;
  currentID: string;
}

const initialState: IInitialState = {
  step: ETemplateStep.step1,
  page: ETemplatePage.ConnectPage,
  currentID: "",
};

export const stepSlice = createSlice({
  name: "step",
  initialState,
  reducers: {
    setTemplateStep(state, action: PayloadAction<ETemplateStep>) {
      state.step = action.payload;
    },
    setTemplateSPage(state, action: PayloadAction<ETemplatePage>) {
      state.page = action.payload;
    },
    setCurrentId(state, action: PayloadAction<string>) {
      state.currentID = action.payload;
    },
  },
});
export const selectTemplateStep = (state: RootState) => state.step.step;
export const selectTemplatePage = (state: RootState) => state.step.page;
export const selectCurrentId = (state: RootState) => state.step.currentID;

export const { setTemplateStep, setTemplateSPage, setCurrentId } =
  stepSlice.actions;
