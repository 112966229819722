import styles from "./ProfileSettingsPage.module.scss";

import { ProfileSettingsForm } from "../../components/ProfileSettings/components/ProfileSettingsForm";
import { ProfileSettingsInfo } from "../../components/ProfileSettings/components/ProfileSettingsInfo";
import { UserData } from "../../components/UserData";

export const ProfileSettingsPage = () => {
  return (
    <div className={styles.root}>
      <UserData className={styles.userData} avatarClassName={styles.avatar} />
      <ProfileSettingsForm />
      <ProfileSettingsInfo />
    </div>
  );
};
