import { FC, useState } from "react";
import { toast } from "react-toastify";

import styles from "./PreviewTemplateModal.module.scss";

import { saveTemplate } from "../../../../api/template/template";
import { ReactComponent as ArrowBack } from "../../../../assets/icons/arrowBack-icon.svg";
import { useCurrentInfoFiles } from "../../../../hooks/useCurrentInfoFiles";
import { useUserData } from "../../../../hooks/useUserProfile";
import { useAppSelector } from "../../../../store/hooks/hooks";
import { selectParseResult } from "../../../../store/slices/parseResultSlice";
import { createErrorMessage } from "../../../../utils/createErrorMessage";
import { DoughnutChart } from "../../../DashBoards/DoughnutChart";
import { DoughnutChartIssues } from "../../../DashBoards/DoughnutChartIssues";
import { PieChart } from "../../../DashBoards/PieChart";
import { CustomButton } from "../../../FormFields/CustomButton";
import { Loader } from "../../../Loader";
import { Title } from "../../../Title";

interface IProps {
  titleReport: string;
  onClose: () => void;
  showPreview: (value: string) => void;
}

export const PreviewTemplateModal: FC<IProps> = ({
  titleReport,
  onClose,
  showPreview,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const previewDataList = useAppSelector(selectParseResult);

  const template = useAppSelector(selectParseResult);

  const { organizationId, tenantId } = useUserData();
  const { refetch } = useCurrentInfoFiles();

  const onSaveClick = async () => {
    setIsLoading(true);

    try {
      await saveTemplate(template.fileId, organizationId, tenantId).then(() => {
        refetch().then(() => {
          onClose();
        });
      });
    } catch (error) {
      toast.error(createErrorMessage(error));
    }
  };

  return (
    <div className={styles.root}>
      <Title tagName={"h3"}>Preview and save uploaded files</Title>

      {titleReport === "Project Summary" && (
        <DoughnutChart
          className={styles.doughnutStyle}
          dataList={previewDataList.preview.budgetCostByBusinessSponsor.data}
          radius={170}
          format={previewDataList.preview.budgetCostByBusinessSponsor.format}
        />
      )}
      {titleReport === "Milestones Summary" && (
        <PieChart
          dataList={previewDataList.preview.countByCompleted.data}
          className={styles.wrapperPie}
          title={previewDataList.preview.countByCompleted.title}
          legendTitle={previewDataList.preview.countByCompleted.legendTitle}
        />
      )}
      {titleReport === "Issues Summary" && (
        <DoughnutChartIssues
          dataList={previewDataList.preview.countByIssueStatus.data}
          className={styles.doughnutIssuesStyle}
          title={previewDataList.preview.countByIssueStatus.title}
          legendTitle={previewDataList.preview.countByIssueStatus.legendTitle}
        />
      )}

      <div className={styles.wrapperBtn}>
        <CustomButton
          theme={"secondary"}
          className={styles.btn}
          onClick={() => showPreview("cansel")}
        >
          <ArrowBack className={styles.icon} />
          Back
        </CustomButton>
        <CustomButton
          className={styles.btn}
          onClick={onSaveClick}
          isDisabled={isLoading}
        >
          {isLoading && <Loader />}
          {!isLoading && "Save"}
        </CustomButton>
      </div>
    </div>
  );
};
