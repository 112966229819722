import { useCookies } from "react-cookie";

import { TemplateChooser } from "./components/TemplateChooser";
import { TemplateChooserDemo } from "./components/TemplateChooserDemo";
import { TemplateUpload } from "./components/TemplateUpload";
import styles from "./SelectTemplate.module.scss";

import { ReactComponent as ArrowBack } from "../../assets/icons/arrowBack-icon.svg";
import { useAppDispatch, useAppSelector } from "../../store/hooks/hooks";
import {
  selectParseResultIsError,
  selectParseResultIsLoading,
} from "../../store/slices/parseResultSlice";
import {
  setTemplateSPage,
  setTemplateStep,
} from "../../store/slices/stepSlice";
import {
  selectDemo,
  selectFileDemo,
  selectFileUpload,
} from "../../store/slices/uploadFileSlice";
import { ETemplatePage, ETemplateStep } from "../../types/steps";
import { CustomLink } from "../CustomLink";
import { Description } from "../Description";
import { CustomButton } from "../FormFields/CustomButton";

export const SelectTemplate = () => {
  const [token] = useCookies(["accessToken"]);

  const dispatch = useAppDispatch();

  const isDownloadFile = useAppSelector(selectFileDemo);
  const isUploadFile = useAppSelector(selectFileUpload);
  const isLoading = useAppSelector(selectParseResultIsLoading);
  const isError = useAppSelector(selectParseResultIsError);
  const isDemoTab = useAppSelector(selectDemo);

  const onBackClick = () => {
    dispatch(setTemplateSPage(ETemplatePage.ConnectPage));
    dispatch(setTemplateStep(ETemplateStep.step1));
  };

  const onNextClick = async () => {
    dispatch(setTemplateSPage(ETemplatePage.ImportConfigurationPage));
    dispatch(setTemplateStep(ETemplateStep.step3));
  };

  const isDisable = () => {
    if (isDemoTab === "demo") {
      return !isDownloadFile;
    } else {
      return !isUploadFile || isLoading || isError;
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        {token.accessToken && <TemplateChooser />}
        {!token.accessToken && <TemplateChooserDemo />}
        <span className={styles.line} />
        <TemplateUpload />
      </div>
      <div className={styles.wrapperBtn}>
        <CustomButton
          theme={"secondary"}
          className={styles.btn}
          onClick={onBackClick}
        >
          <ArrowBack className={styles.icon} />
          Back
        </CustomButton>
        <CustomButton
          isDisabled={isDisable()}
          className={styles.btn}
          onClick={onNextClick}
        >
          Next
        </CustomButton>
      </div>
      {!token.accessToken && (
        <div className={styles.wrapperSupport}>
          <Description theme={"t4"} className={styles.text}>
            Do you have any questions?
          </Description>
          <CustomLink link={"/"} className={styles.supLink}>
            Get support
          </CustomLink>
        </div>
      )}
    </div>
  );
};
