import { forwardRef } from "react";

import styles from "./TemplateHelpDropdown.module.scss";

import { Description } from "../../../Description";
import { CustomButton } from "../../../FormFields/CustomButton";

interface IProps {
  text: string;
  token?: string;
  onOpenClick?: () => void;
  isDisable?: boolean;
}

export const TemplateHelpDropdown = forwardRef<HTMLDivElement, IProps>(
  ({ onOpenClick, text, token, isDisable }, ref) => {
    return (
      <div ref={ref} className={styles.root}>
        <Description theme={"t7"}>{text}</Description>
        {!token && !isDisable && (
          <CustomButton
            theme="text"
            onClick={onOpenClick}
            className={styles.btn}
          >
            Login
          </CustomButton>
        )}
      </div>
    );
  },
);
