import { useEffect, useState } from "react";

import styles from "./DemoData.module.scss";

import { useAppDispatch } from "../../../../store/hooks/hooks";
import {
  setDemo,
  setFilesDemo,
} from "../../../../store/slices/uploadFileSlice";
import { CustomButton } from "../../../FormFields/CustomButton";
import { LoadTemplate } from "../LoadTemplate";

export const DemoData = () => {
  const [showTemplate, setShowTemplate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const handleClick = () => {
    setShowTemplate(true);
    setIsLoading(true);
    const timeout = setTimeout(() => {
      setIsLoading(false);
      dispatch(setFilesDemo("DEMO Data"));
    }, 1000);
    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    dispatch(setDemo("demo"));
    return () => {
      dispatch(setFilesDemo(null));
    };
  }, []);

  return (
    <div className={styles.root}>
      {!showTemplate && (
        <CustomButton onClick={handleClick}>Load Demo data</CustomButton>
      )}
      {showTemplate && (
        <LoadTemplate
          className={styles.demoTemplate}
          titleTemplate={"DEMO Data Project Summary Template.xlsx"}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};
