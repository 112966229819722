import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import * as yup from "yup";

import styles from "./HeaderFilters.module.scss";

import { useAppDispatch } from "../../../../../store/hooks/hooks";
import {
  setOwners,
  setPeriodFrom,
  setPeriodTo,
} from "../../../../../store/slices/reportsSlice/IssuesSlice";
import { Description } from "../../../../Description";
import { CustomDateInput } from "../../../../FormFields/CustomDateInput";
import { CustomMultiSelect } from "../../../../FormFields/CustomMultiSelect";
import { IOptions } from "../../../../FormFields/CustomMultiSelect/types";

interface IProps {
  count?: number;
  countOpened?: number;
  maxPeriod: string;
  minPeriod?: string;
  owners?: string[];
}

export const HeaderFilters: FC<IProps> = ({
  count,
  countOpened,
  minPeriod = "",
  maxPeriod = "",
  owners = [],
}) => {
  const [issuePeriodFrom, setIssuePeriodFrom] = useState(minPeriod);
  const [issuePeriodTo, setIssuePeriodTo] = useState(maxPeriod);

  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      issueOwner: owners.map((owner) => ({ label: owner, value: owner })),
      issuePeriodFrom: "",
      issuePeriodTo: "",
    },
    validationSchema: yup.object({
      issuePeriodFrom: yup.date(),
      issuePeriodTo: yup.date(),
    }),
    onSubmit: () => {},
  });

  const handleIssuePeriodToChange = (e: any, name: string) => {
    const value = e.target.value;
    const parts = value.split("/");

    if (parts.length === 3) {
      let month = parseInt(parts[0], 10);
      let day = parseInt(parts[1], 10);
      const year = parseInt(parts[2], 10);

      console.log("TEST", day);

      if (month > 12) {
        month = 12;
      } else if (month < 1) {
        month = 1;
      }

      if (day > 31) {
        day = 31;
      } else if (day < 1) {
        day = 1;
      }

      const correctedValue = `${month}/${day}/${year}`;

      name === "issuePeriodTo"
        ? setIssuePeriodTo(correctedValue)
        : setIssuePeriodFrom(correctedValue);
    }
  };

  const formatDateFilter = (value: string) => {
    const [month, day, year] = value.split("/");
    const dateObj = `${year}-${month}-${day}T00:00:00Z`;
    return dateObj;
  };

  const onSelectChange = (value: IOptions[]) => {
    formik.setFieldValue("issueOwner", value);
  };

  useEffect(() => {
    if (!isNaN(Date.parse(formatDateFilter(issuePeriodTo)))) {
      formik.setFieldValue("issuePeriodTo", formatDateFilter(issuePeriodTo));
    }

    if (!isNaN(Date.parse(formatDateFilter(issuePeriodFrom)))) {
      formik.setFieldValue(
        "issuePeriodFrom",
        formatDateFilter(issuePeriodFrom)
      );
    }
  }, [issuePeriodTo, issuePeriodFrom]);

  useEffect(() => {
    dispatch(setOwners(formik.values.issueOwner.map((item) => item.value)));
    dispatch(setPeriodFrom(formik.values.issuePeriodFrom));
    dispatch(setPeriodTo(formik.values.issuePeriodTo));
  }, [formik.values]);

  return (
    <form className={styles.root} onSubmit={formik.handleSubmit}>
      <div className={styles.infoField}>
        <span className={styles.infoFieldTitle}>{count}</span>
        <Description theme="t4" className={styles.infoFieldSubtitle}>
          All Issues
        </Description>
      </div>
      <div className={styles.infoField}>
        <span className={styles.infoFieldTitle}>{countOpened}</span>
        <Description theme="t4" className={styles.infoFieldSubtitle}>
          Open Issues
        </Description>
      </div>
      <CustomMultiSelect
        options={owners.map((owner) => ({ label: owner, value: owner }))}
        label="Issue Owner"
        value={formik.values.issueOwner}
        onChange={onSelectChange}
        className={styles.select}
      />
      <CustomDateInput
        id="issuePeriodFrom"
        name="issuePeriodFrom"
        value={issuePeriodFrom}
        onChange={(e) => handleIssuePeriodToChange(e, "issuePeriodFrom")}
        label="Issue Period (from)"
      />
      <CustomDateInput
        id="issuePeriodTo"
        name="issuePeriodTo"
        value={issuePeriodTo}
        onChange={(e) => handleIssuePeriodToChange(e, "issuePeriodTo")}
        label="Issue Period (to)"
      />
    </form>
  );
};
