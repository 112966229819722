import styles from "./Sections.module.scss";

import { Title } from "../../../Title";

export const Sections = () => {
  return (
    <Title tagName="h5" className={styles.root}>
      Pricing
    </Title>
  );
};
