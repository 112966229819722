import { FC, useRef, useState } from "react";

import styles from "./HelpBtnsWrapper.module.scss";

import { ReactComponent as AvatarIcon } from "../../../../assets/icons/avatar-icon.svg";
import { ReactComponent as HelpIcon } from "../../../../assets/icons/help-icon.svg";
import { useClickOutside } from "../../../../hooks/useClickOutside";
import { CustomButton } from "../../../FormFields/CustomButton";
import { ReactPortal } from "../../../ReactPortal";
import { AvatarMenu } from "../AvatarMenu";
import { HelpDropdown } from "../HelpDropdown";

interface IProps {
  onOpenLogout: () => void;
}

export const HelpBtnsWrapper: FC<IProps> = ({ onOpenLogout }) => {
  const [isHelperOpen, setIsHelperOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const helpRef = useRef<HTMLDivElement>(null);
  const helpBtnRef = useRef<HTMLButtonElement>(null);
  const avatarMenuRef = useRef<HTMLDivElement>(null);
  const avatarBtnRef = useRef<HTMLButtonElement>(null);

  const onHelpClick = () => {
    setIsHelperOpen(!isHelperOpen);
  };

  const onHelpClose = () => {
    setIsHelperOpen(false);
  };

  const onMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onMenuClose = () => {
    setIsMenuOpen(false);
  };

  useClickOutside([helpRef, helpBtnRef], onHelpClose);
  useClickOutside([avatarMenuRef, avatarBtnRef], onMenuClose);

  return (
    <div ref={containerRef} className={styles.root}>
      <CustomButton ref={helpBtnRef} theme="unstyled" onClick={onHelpClick}>
        <HelpIcon />
      </CustomButton>
      <CustomButton ref={avatarBtnRef} theme="unstyled" onClick={onMenuClick}>
        <AvatarIcon className={styles.avatarIcon} />
      </CustomButton>
      {isHelperOpen && (
        <ReactPortal container={containerRef}>
          <HelpDropdown ref={helpRef} />
        </ReactPortal>
      )}
      {isMenuOpen && (
        <ReactPortal container={containerRef}>
          <AvatarMenu
            ref={avatarMenuRef}
            onOpenClick={onOpenLogout}
            isMenuOpen={onMenuClose}
          />
        </ReactPortal>
      )}
    </div>
  );
};
