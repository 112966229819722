import { AxiosPromise } from "axios";

import { IIUserRolesResp, IUpdateUserProfile } from "../../types/user";
import { API_PATHS } from "../API_PATHS";
import { request } from "../utils";

export const getOrganization = () => {
  return request({
    method: "GET",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.ORGANIZATION}`,
  });
};

export const getTenant = (param: string) => {
  return request({
    method: "GET",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.TENANT(param)}`,
  });
};

export const getUserData = () => {
  return request({
    method: "GET",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.USER.GET_PROFILE}`,
  });
};

export const updateUserProfile = (data: IUpdateUserProfile) => {
  return request({
    method: "PATCH",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.USER.UPDATE_PROFILE}`,
    data,
  });
};

//in future
export const getUserRoles = (): AxiosPromise<IIUserRolesResp> => {
  return request({
    method: "GET",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.USER.ROLE}`,
  });
};
