export const API_PATHS = {
  BASE_URLS: {
    API_AUTH: process.env.REACT_APP_API_AUTH_URL,
    API_URL: process.env.REACT_APP_API_URL,
  },
  AUTH: {
    LOGIN: "/Auth/Login",
    VALIDATE_SEND_CONFIRMATION_CODE: "/Auth/ValidateSendConfirmationCode",
    CONFIRM_EMAIL_REGISTER: "/Auth/ConfirmEmailRegister",
    REFRESH_TOKEN: "/Auth/RefreshToken",
    RESET_PASSWORD: "/Auth/ResetPasswordSendConfirmationCode",
    CONFIRM_EMAIL: "/Auth/ConfirmEmail",
    CHANGE_PASSWORD: "/Auth/ChangePassword",
  },
  FILE: {
    DOWNLOAD_TEMPLATE: (param?: string) =>
      param
        ? `/File/DownloadTemplate?fileTemplate=${param}`
        : "/File/DownloadDemoTemplate",
    UPLOAD_AND_GET_WORKSHEETS: "/File/UploadAndGetWorksheets",
    SAVE_TEMPLATE: (fileId: string, organizationId: number, tenantId: number) =>
      `/File/SaveData?FileId=${fileId}&OrganizationId=${organizationId}&TenantId=${tenantId}`,
    GET_CURRENT_FILES: (organizationId: number, tenantId: number) =>
      `/File/GetCurrentMetaInfoFiles?OrganizationId=${organizationId}&TenantId=${tenantId}`,
    SHARE_REPORT: (
      summary: string,
      email: string,
      orgId: number,
      tenId: number,
      reportName: string
    ) =>
      `/${summary}Report/ShareReport?Email=${email}&OrganizationId=${orgId}&TenantId=${tenId}&ReportName=${reportName}`,
    DOWNLOAD_FILE: (fileMetaInfoId: string, orgId: number, tenId: number) =>
      `/File/DownloadFile?FileMetaInfoId=${fileMetaInfoId}&OrganizationId=${orgId}&TenantId=${tenId}`
  },
  IMPORT: {
    GET_IMPORT_CONFIGURATIONS: (organizationId: number,
      tenantId: number,
      adapterIdentifier: string) =>
      // eslint-disable-next-line max-len
      `/Import/GetImportConfigurations?organizationId=${organizationId}&tenantId=${tenantId}&adapterIdentifier=${adapterIdentifier}`,
    GET_DESTINATION_METADATA: () =>
      `/Import/GetDestinationMetadata`,
    GET_DEFAULT_IMPORT_CONFIGURATION: (organizationId: number,
      tenantId: number,
      adapterIdentifier: string,
      destinationIdentifier: string) =>
      // eslint-disable-next-line max-len
      `/Import/GetDefaultImportConfiguration?organizationId=${organizationId}&tenantId=${tenantId}&adapterIdentifier=${adapterIdentifier}&destinationIdentifier=${destinationIdentifier}`,
    GET_SOURCE_PROPERTIES: () =>
      `/Import/GetSourceProperties`,
    SAVE_IMPORT_CONFIGURATION: () =>
      `/Import/SaveImportConfiguration`,
    GET_PREVIEW: () =>
      `/Import/GetPreview`
  },
  PROJECT_SUMMARY: {
    GET_VERSIONS: (summary: string, orgId: number, tenId: number) =>
      `/${summary}Report/GetVersions?OrganizationId=${orgId}&TenantId=${tenId}`,
    GET_DOUGHNUT_CHART: (
      fileId: string,
      orgId: number,
      tenId: number,
      url: string
    ) =>
      `/${url}?FileMetaInfoId=${fileId}&OrganizationId=${orgId}&TenantId=${tenId}`,
    GET_VERTICAL_CHART: (
      fileId: string,
      orgId: number,
      tenId: number,
      url: string
    ) =>
      `/${url}?FileMetaInfoId=${fileId}&OrganizationId=${orgId}&TenantId=${tenId}`,
    GET_HEADER_INFO: (fileId: string, orgId: number, tenId: number) =>
      `/ProjectSummaryReport/GetReportHeader?FileMetaInfoId=${fileId}&OrganizationId=${orgId}&TenantId=${tenId}`,
    GET_TABLE_INFO: (
      summary: string,
      fileId: string,
      orgId: number,
      tenId: number
    ) =>
      `/${summary}Report/GetList?FileMetaInfoId=${fileId}&OrganizationId=${orgId}&TenantId=${tenId}`,
  },
  ISSUES_SUMMARY: {
    GET_VERSIONS: (organizationId: number, tenantId: number) =>
      `/IssueSummaryReport/GetVersions?OrganizationId=${organizationId}&TenantId=${tenantId}`,
    GET_LIST_REPORT: (
      fileId: string,
      organizationId: number,
      tenantId: number,
      owners?: string[],
      startDate?: string,
      endDate?: string
    ) => {
      let url =
        `/IssueSummaryReport/GetList?FileMetaInfoId=${fileId}&OrganizationId=${organizationId}` +
        `&TenantId=${tenantId}`;

      if (owners?.length) {
        url += `&Owners=${owners?.join("&Owners=")}`;
      }

      if (startDate && startDate !== "") {
        url += `&StartDate=${startDate}`;
      }

      if (endDate && endDate !== "") {
        url += `&EndDate=${endDate}`;
      }

      return url;
    },
    GET_HEADER_INFO: (
      fileId: string,
      organizationId: number,
      tenantId: number,
      owners?: string[],
      startDate?: string,
      endDate?: string
    ) => {
      // eslint-disable-next-line max-len
      let url = `/IssueSummaryReport/GetReportHeader?FileMetaInfoId=${fileId}&OrganizationId=${organizationId}&TenantId=${tenantId}`;

      if (owners?.length) {
        url += `&Owners=${owners?.join("&Owners=")}`;
      }

      if (startDate && startDate !== "") {
        url += `&StartDate=${startDate}`;
      }

      if (endDate && endDate !== "") {
        url += `&EndDate=${endDate}`;
      }

      return url;
    },
    GET_DOUGHNUT_CHART: (
      fileId: string,
      orgId: number,
      tenId: number,
      owners?: string[],
      startDate?: string,
      endDate?: string
    ) => {
      // eslint-disable-next-line max-len
      let url = `/IssueSummaryReport/GetCountByIssueStatus?FileMetaInfoId=${fileId}&OrganizationId=${orgId}&TenantId=${tenId}`;

      if (owners?.length) {
        url += `&Owners=${owners?.join("&Owners=")}`;
      }

      if (startDate && startDate !== "") {
        url += `&StartDate=${startDate}`;
      }

      if (endDate && endDate !== "") {
        url += `&EndDate=${endDate}`;
      }

      return url;
    },
    GET_BAR_CHART: (
      fileId: string,
      orgId: number,
      tenId: number,
      owners?: string[],
      startDate?: string,
      endDate?: string
    ) => {
      // eslint-disable-next-line max-len
      let url =
        `/IssueSummaryReport/GetLikelihoodAndImpactByIssueTitle?FileMetaInfoId=${fileId}&OrganizationId=${orgId}` +
        `&TenantId=${tenId}`;

      if (owners?.length) {
        url += `&Owners=${owners?.join("&Owners=")}`;
      }

      if (startDate && startDate !== "") {
        url += `&StartDate=${startDate}`;
      }

      if (endDate && endDate !== "") {
        url += `&EndDate=${endDate}`;
      }

      return url;
    },
  },
  MILESTONE_SUMMARY: {
    GET_HEADER_INFO: (fileId: string, orgId: number, tenId: number) =>
      `/MilestoneSummaryReport/GetReportHeader?FileMetaInfoId=${fileId}&OrganizationId=${orgId}&TenantId=${tenId}`,
    GET_LIST_REPORT: (
      fileId: string,
      orgId: number,
      tenId: number,
      projects?: string[],
      milestoneTypes?: string[],
      completedState?: string,
      startDate?: string,
      endDate?: string
    ) => {
      // eslint-disable-next-line max-len
      let url = `/MilestoneSummaryReport/GetList?FileMetaInfoId=${fileId}&OrganizationId=${orgId}&TenantId=${tenId}`;

      if (projects?.length) {
        url += `&Projects=${projects?.join("&Projects=")}`;
      }

      if (milestoneTypes?.length) {
        url += `&MilestoneTypes=${milestoneTypes?.join("&MilestoneTypes=")}`;
      }

      if (completedState && completedState !== "All") {
        url += `&CompletedState=${completedState}`;
      }

      if (startDate && startDate !== "") {
        url += `&StartDate=${startDate}`;
      }

      if (endDate && endDate !== "") {
        url += `&EndDate=${endDate}`;
      }

      return url;
    },
    GET_COUNT_BY_TYPE: (
      fileId: string,
      orgId: number,
      tenId: number,
      projects?: string[],
      milestoneTypes?: string[],
      completedState?: string,
      startDate?: string,
      endDate?: string
    ) => {
      // eslint-disable-next-line max-len
      let url = `/MilestoneSummaryReport/GetCountByType?FileMetaInfoId=${fileId}&OrganizationId=${orgId}&TenantId=${tenId}`;

      if (projects?.length) {
        url += `&Projects=${projects?.join("&Projects=")}`;
      }

      if (milestoneTypes?.length) {
        url += `&MilestoneTypes=${milestoneTypes?.join("&MilestoneTypes=")}`;
      }

      if (completedState && completedState !== "All") {
        url += `&CompletedState=${completedState}`;
      }

      if (startDate && startDate !== "") {
        url += `&StartDate=${startDate}`;
      }

      if (endDate && endDate !== "") {
        url += `&EndDate=${endDate}`;
      }

      return url;
    },
    GET_COUNT_BY_COMPLETED: (
      fileId: string,
      orgId: number,
      tenId: number,
      projects?: string[],
      milestoneTypes?: string[],
      completedState?: string,
      startDate?: string,
      endDate?: string
    ) => {
      // eslint-disable-next-line max-len
      let url = `/MilestoneSummaryReport/GetCountByCompleted?FileMetaInfoId=${fileId}&OrganizationId=${orgId}&TenantId=${tenId}`;

      if (projects?.length) {
        url += `&Projects=${projects?.join("&Projects=")}`;
      }

      if (milestoneTypes?.length) {
        url += `&MilestoneTypes=${milestoneTypes?.join("&MilestoneTypes=")}`;
      }

      if (completedState && completedState !== "All") {
        url += `&CompletedState=${completedState}`;
      }

      if (startDate && startDate !== "") {
        url += `&StartDate=${startDate}`;
      }

      if (endDate && endDate !== "") {
        url += `&EndDate=${endDate}`;
      }

      return url;
    },
  },
  HISTORY: {
    GET_LIST_HISTORY: (
      organizationId: number,
      tenantId: number,
      templateName?: string
    ) => {
      let url = `/File/GetListOfUploadedFiles?OrganizationId=${organizationId}&TenantId=${tenantId}`;

      if (templateName && templateName !== "") {
        url += `&templateName=${templateName}`;
      }

      return url;
    },
    CHANGE_CURRENT: (
      fileId: string,
      organizationId: number,
      tenantId: number
    ) =>
      `/File/SetCurrent?FileMetaInfoId=${fileId}&OrganizationId=${organizationId}&TenantId=${tenantId}`,
    DELETE_FILE: (fileId: string, organizationId: number, tenantId: number) =>
      `/File/DeleteFile?FileMetaInfoId=${fileId}&OrganizationId=${organizationId}&TenantId=${tenantId}`,
    DOWNLOAD_FILE: (fileId: string, organizationId: number, tenantId: number) =>
      `/File/DownloadFile?FileMetaInfoId=${fileId}&OrganizationId=${organizationId}&TenantId=${tenantId}`,
  },
  USER: {
    GET_PROFILE: "/User/GetProfile",
    UPDATE_PROFILE: "/User/UpdateProfile",
    ROLE: "/Role/GetList",
  },
  ORGANIZATION: "/Organization/GetList",
  TENANT: (id: string) => `/Tenant/GetList?organizationId=${id}`,
};
