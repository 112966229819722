import { GroupBase, StylesConfig } from "react-select";

import { IOptions } from "./types";

export const setCustomStyles = (
  theme: "standard" | "withoutBorder" | "labelInsideBorder"
) => {
  let selectStyles: StylesConfig<IOptions, boolean, GroupBase<IOptions>> = {
    placeholder: (baseStyles) => ({
      ...baseStyles,
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "22px",
      color: "#A3A7AE",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    singleValue: (baseStyles) => ({
      ...baseStyles,
      fontSize: "14px",
      lineHeight: "19px",
      color: "#404040",
      margin: 0,
    }),
  };

  const dropdownStyles: StylesConfig<IOptions, boolean, GroupBase<IOptions>> = {
    menu: (baseStyles) => ({
      ...baseStyles,
      borderRadius: "8px",
      boxShadow: "0px 9px 28px 8px #0000000D",
    }),
    menuList: (baseStyles) => ({
      ...baseStyles,
      display: "flex",
      flexDirection: "column",
      gap: "4px",
      padding: "8px 4px",
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "4px",
      padding: "9px 16px",
      borderRadius: "4px",
      backgroundColor: state.isSelected ? "#F1F1F1" : "#FFFFFF",
      color: state.isSelected ? "#FF6300" : "#404040",
      cursor: state.isSelected ? "default" : "pointer",
      ":hover": {
        backgroundColor: "#F1F1F1",
      },
    }),
  };

  switch (theme) {
    case "labelInsideBorder":
      selectStyles = {
        ...selectStyles,
        ...{
          control: (baseStyles) => ({
            ...baseStyles,
            border: "none",
            cursor: "pointer",
            boxShadow: "none",
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: 0,
          }),
        },
      };
      break;
    case "withoutBorder":
      selectStyles = {
        ...selectStyles,
        ...{
          control: (baseStyles) => ({
            ...baseStyles,
            height: "20px",
            border: "none",
            cursor: "pointer",
            boxShadow: "none",
          }),
          valueContainer: (baseStyles) => ({ ...baseStyles, padding: 0 }),
        },
      };
      break;
    default:
      selectStyles = {
        ...selectStyles,
        ...{
          control: (baseStyles, state) => ({
            ...baseStyles,
            height: "40px",
            borderRadius: "8px",
            borderColor: "none",
            border: state.isFocused ? "1px solid #5E5E5E" : "1px solid #9E9E9E",
            boxShadow: "none",
            cursor: "pointer",

            ":hover": {
              border: "1px solid #5E5E5E",
            },
          }),
        },
      };
      break;
  }

  return { ...selectStyles, ...dropdownStyles };
};
