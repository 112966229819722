import { jwtDecode } from "jwt-decode";

import { ITempToken, IToken } from "../types/auth";

export const decodeToken = (token: string) => {
  const decodeToken: IToken | ITempToken = jwtDecode(token);

  const expDate = new Date(decodeToken.exp * 1000);

  return { token, decodeToken, expDate };
};
