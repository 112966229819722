export const ROLES = [
  {
    value: "1",
    label: "Project manager",
  },
  {
    value: "2",
    label: "Business analyst",
  },
  {
    value: "3",
    label: "Student",
  },
  {
    value: "4",
    label: "Other",
  },
];
