import classNames from "classnames";
import { motion } from "framer-motion";
import { FC, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./ReportsItem.module.scss";

import { ReactComponent as Dots } from "../../../../assets/icons/dots-icon.svg";
import { PATHS } from "../../../../constants/PATHS";
import { useClickOutside } from "../../../../hooks/useClickOutside";
import { useAppDispatch } from "../../../../store/hooks/hooks";
import {
  setTemplateSPage,
  setTemplateStep,
} from "../../../../store/slices/stepSlice";
import { EReportTypes } from "../../../../types/reports";
import { ETemplatePage, ETemplateStep } from "../../../../types/steps";
import { CustomLink } from "../../../CustomLink";
import { CustomButton } from "../../../FormFields/CustomButton";
import { ReactPortal } from "../../../ReactPortal";
import { ReactPortalSubstrate } from "../../../ReactPortalSubstrate";
import { ReportSettings } from "../ReportSettings";
import { UpdateReportModal } from "../UpdateReportModal";

interface IProps {
  id: string;
  title: string;
  type: EReportTypes;
  report?: string;
  isDisable?: boolean;
  fileName: string;
}

export const ReportsItem: FC<IProps> = ({
  id,
  title,
  type,
  report,
  isDisable,
  fileName,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isUpdateOpen, seIsUpdateOpen] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const containerRef = useRef<HTMLDivElement>(null);
  const settingsMenuRef = useRef<HTMLDivElement>(null);
  const settingsBtnRef = useRef<HTMLButtonElement>(null);
  const formRef = useRef<HTMLDivElement>(null);

  const onOpenClick = () => {
    seIsUpdateOpen(true);
  };

  const onCloseClick = () => {
    seIsUpdateOpen(false);
  };

  useClickOutside(formRef, onCloseClick);

  const onMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onMenuClose = () => {
    setIsMenuOpen(false);
  };

  const onClickLoad = () => {
    dispatch(setTemplateSPage(ETemplatePage.SelectTemplatePage));
    dispatch(setTemplateStep(ETemplateStep.step2));
    navigate(PATHS.LOAD_DATA_PATH);
  };

  useClickOutside([settingsMenuRef, settingsBtnRef], onMenuClose);

  return (
    <div className={styles.root}>
      <div
        className={styles.imgCard}
        style={{ backgroundImage: `url(${report})` }}
      >
        {isDisable && (
          <motion.div className={styles.disabledImage}>
            <CustomButton className={styles.loadBtn} onClick={onClickLoad}>
              Load your data
            </CustomButton>
          </motion.div>
        )}
      </div>
      <div className={styles.wrapper} ref={containerRef}>
        <CustomLink
          className={styles.title}
          isDisabled={isDisable}
          link={`/reports/${type}/${id}`}
        >
          {title}
        </CustomLink>
        <CustomButton
          ref={settingsBtnRef}
          theme={"unstyled"}
          className={classNames(styles.dotsBtn, {
            [styles.disabled]: isDisable,
          })}
          onClick={onMenuClick}
          isDisabled={isDisable}
        >
          <Dots />
        </CustomButton>
      </div>
      {isMenuOpen && (
        <ReactPortal container={containerRef}>
          <ReportSettings ref={settingsMenuRef} onOpenClick={onOpenClick} reportName={type} name={title}/>
        </ReactPortal>
      )}

      {isUpdateOpen && (
        <ReactPortal>
          <ReactPortalSubstrate className={styles.portalWrapper}>
            <UpdateReportModal
              id={id}
              fileName={fileName}
              ref={formRef}
              onClose={onCloseClick}
              titleReport={title}
            />
          </ReactPortalSubstrate>
        </ReactPortal>
      )}
    </div>
  );
};
