import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../index";

interface IInitialState {
  owners: string[];
  periodFrom: string;
  periodTo: string;
}

const initialState: IInitialState = {
  owners: [],
  periodFrom: "",
  periodTo: "",
};

export const issuesSlice = createSlice({
  name: "issues",
  initialState,
  reducers: {
    setOwners(state, action: PayloadAction<string[]>) {
      state.owners = action.payload;
    },
    setPeriodFrom(state, action: PayloadAction<string>) {
      state.periodFrom = action.payload;
    },
    setPeriodTo(state, action: PayloadAction<string>) {
      state.periodTo = action.payload;
    },
  },
});
export const selectOwners = (state: RootState) => state.issues.owners;
export const selectPeriodFrom = (state: RootState) => state.issues.periodFrom;
export const selectPeriodTo = (state: RootState) => state.issues.periodTo;

export const { setOwners, setPeriodFrom, setPeriodTo } = issuesSlice.actions;
