import { API_PATHS } from "../API_PATHS";
import { request } from "../utils";

export const getCountByType = (
  fileId: string,
  orgId: number,
  tenantId: number,
  projects?: string[],
  milestoneTypes?: string[],
  completedState?: string,
  startDate?: string,
  endDate?: string,
) => {
  return request({
    method: "GET",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.MILESTONE_SUMMARY.GET_COUNT_BY_TYPE(
      fileId,
      orgId,
      tenantId,
      projects,
      milestoneTypes,
      completedState,
      startDate,
      endDate,
    )}`,
  });
};

export const getCountByCompleted = (
  fileId: string,
  orgId: number,
  tenantId: number,
  projects?: string[],
  milestoneTypes?: string[],
  completedState?: string,
  startDate?: string,
  endDate?: string,
) => {
  return request({
    method: "GET",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.MILESTONE_SUMMARY.GET_COUNT_BY_COMPLETED(
      fileId,
      orgId,
      tenantId,
      projects,
      milestoneTypes,
      completedState,
      startDate,
      endDate,
    )}`,
  });
};

export const getListMilestones = (
  fileId: string,
  orgId: number,
  tenantId: number,
  projects?: string[],
  milestoneTypes?: string[],
  completedState?: string,
  startDate?: string,
  endDate?: string,
) => {
  return request({
    method: "GET",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.MILESTONE_SUMMARY.GET_LIST_REPORT(
      fileId,
      orgId,
      tenantId,
      projects,
      milestoneTypes,
      completedState,
      startDate,
      endDate,
    )}`,
  });
};

export const getReportHeader = (
  fileId: string,
  orgId: number,
  tenantId: number,
) => {
  return request({
    method: "GET",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.MILESTONE_SUMMARY.GET_HEADER_INFO(
      fileId,
      orgId,
      tenantId,
    )}`,
  });
};
