import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../index";

interface IInitialState {
  name: string;
  id: string
}

const initialState: IInitialState = {
  name: "All my reports",
  id: "",
};

export const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    setName(state, action: PayloadAction<string>) {
      state.name = action.payload;
    },
    setIdHistory(state, action: PayloadAction<string>) {
      state.id = action.payload;
    },
  },
});
export const selectName = (state: RootState) => state.history.name;
export const selectIdHistory = (state: RootState) => state.history.id;

export const { setName, setIdHistory } = historySlice.actions;
