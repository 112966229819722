export const enum ETemplateStep {
  "step1",
  "step2",
  "step3",
  "step4",
  "step5",
}

export const enum ETemplatePage {
  "ConnectPage",
  "SelectTemplatePage",
  "ImportConfigurationPage",
  "ImportConfigurationFieldMappingPage",
  "PreviewPage",
}
