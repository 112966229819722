import classNames from "classnames";
import { FC, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";

import styles from "./Template.module.scss";

import { getTemplate } from "../../api/template/template";
import { ReactComponent as HelpIcon } from "../../assets/icons/help-icon.svg";
import { MESSAGES } from "../../constants/MESSAGES";
import { useClickOutside } from "../../hooks/useClickOutside";
import { Auth } from "../Auth";
import { Description } from "../Description";
import { CustomButton } from "../FormFields/CustomButton";
import { ReactPortal } from "../ReactPortal";
import { ReactPortalSubstrate } from "../ReactPortalSubstrate";
import { TemplateHelpDropdown } from "../SelectTemplate/components/TemplateHelpDropdown";

interface IProps {
  titleTemplate: string;
  textDropdown: string;
  tokenLogin?: boolean;
  className?: string;
  showHelp?: boolean;
  downloadBtn?: boolean;
  typeTemplate?: string;
}

export const Template: FC<IProps> = ({
  textDropdown,
  titleTemplate,
  tokenLogin = true,
  className,
  showHelp = true,
  downloadBtn = true,
  typeTemplate,
}) => {
  const [isHelperOpen, setIsHelperOpen] = useState(false);
  const [isPortalOpen, seIsPortalOpen] = useState(false);

  const [token] = useCookies(["accessToken"]);

  const helpBtnRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const helpRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLDivElement>(null);

  const onOpenClick = () => {
    seIsPortalOpen(true);
  };

  const onCloseClick = () => {
    seIsPortalOpen(false);
  };

  const onHelpClick = () => {
    setIsHelperOpen(!isHelperOpen);
  };

  const onHelpClose = () => {
    setIsHelperOpen(false);
    seIsPortalOpen(false);
  };

  const fetchTemplate = async () => {
    try {
      const res = await getTemplate(typeTemplate);
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", titleTemplate);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      toast.error(MESSAGES.DATA_LOADING_ERROR);
    }
  };

  useClickOutside([containerRef], onHelpClose);
  useClickOutside([formRef], onCloseClick);

  return (
    <div className={classNames(styles.root, className)}>
      <div
        className={classNames(styles.titleWrapper, {
          [styles.wrapperDemo]: !downloadBtn || !tokenLogin,
        })}
      >
        <Description
          theme={"t3"}
          className={classNames(styles.titleTemplate, {
            [styles.disabledTitle]: !tokenLogin,
          })}
        >
          {titleTemplate}
        </Description>
        {showHelp && (
          <div className={styles.wrapper} ref={containerRef}>
            <CustomButton
              ref={helpBtnRef}
              theme="unstyled"
              onClick={onHelpClick}
            >
              <HelpIcon className={styles.helpIcon} />
            </CustomButton>
          </div>
        )}
      </div>
      {tokenLogin && downloadBtn && (
        <CustomButton
          onClick={fetchTemplate}
          theme={"secondary"}
          className={classNames(styles.downloadBtn, {
            [styles.hiddenBtn]: !tokenLogin,
          })}
        >
          Download
        </CustomButton>
      )}
      {isHelperOpen && (
        <ReactPortal container={containerRef}>
          <TemplateHelpDropdown
            ref={helpRef}
            text={textDropdown}
            token={token.accessToken}
            onOpenClick={onOpenClick}
            isDisable={downloadBtn}
          />
        </ReactPortal>
      )}
      {isPortalOpen && (
        <ReactPortal>
          <ReactPortalSubstrate className={styles.portalWrapper}>
            <Auth ref={formRef} onClose={onCloseClick} />
          </ReactPortalSubstrate>
        </ReactPortal>
      )}
    </div>
  );
};
