export const PATHS = {
  BASE_PATH: "/",
  CONNECT_DATA_PATH: "/connect-data",
  LOAD_DATA_PATH: "/load-data",
  REPORTS_PATH: "/reports",
  HISTORY_PATH: "/history",
  ACCOUNT_SETTINGS: "account-settings",
  PROFILE_SETTINGS: "profile-settings",
  REPORTS: {
    PROJECT: "ProjectSummary",
    MILESTONES: "MilestoneSummary",
    ISSUES: "IssueSummary",
  },
  PRIVACY_POLICY_PATH: "privacy-policy",
};
