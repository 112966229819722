import { API_PATHS } from "../API_PATHS";
import { request } from "../utils";

export const getHistoryTemplate = (
  organizationId: number,
  tenantId: number,
  templateName?: string,
) => {
  return request({
    method: "GET",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.HISTORY.GET_LIST_HISTORY(organizationId, tenantId, templateName)}`,
  });
};

export const changeCurrentTemplate = (
  fileId: string,
  organizationId: number,
  tenantId: number,
) => {
  return request({
    method: "PUT",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.HISTORY.CHANGE_CURRENT(fileId, organizationId, tenantId)}`,
  });
};

export const downloadTemplate = (
  fileId: string,
  organizationId: number,
  tenantId: number,
) => {
  return request({
    method: "GET",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.HISTORY.DOWNLOAD_FILE(fileId, organizationId, tenantId)}`,
    responseType: "blob",
  });
};

export const deleteTemplate = (
  fileId: string,
  organizationId: number,
  tenantId: number,
) => {
  return request({
    method: "DELETE",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.HISTORY.DELETE_FILE(fileId, organizationId, tenantId)}`,
  });
};
