import classNames from "classnames";
import { FC } from "react";

import styles from "./UserData.module.scss";

import { ReactComponent as AvatarIcon } from "../../assets/icons/avatar-icon.svg";
import { useUserData } from "../../hooks/useUserProfile";
import { Loader } from "../Loader";

interface IProps {
  className?: string;
  avatarClassName?: string;
}

export const UserData: FC<IProps> = ({ className, avatarClassName }) => {
  const { user, isLoading } = useUserData();

  return (
    <div className={classNames(styles.root, className)}>
      <AvatarIcon className={classNames(styles.avatarIcon, avatarClassName)} />
      {isLoading && <Loader />}
      {!isLoading && user && user.data.data.email}
    </div>
  );
};
