import { AxiosPromise } from "axios";

import {
  IChangePasswordData,
  IChangePasswordResp,
  ILoginUser,
  INewUser,
  INewUserResp,
  IRefreshToken,
  IRegisterOrLoginUserResp,
  IRegisterUser,
  IResetPasswordConfirmEmailData,
  IResetPasswordConfirmEmailResp,
  IResetPasswordData,
  IResetPasswordResp,
} from "../../types/auth";
import { API_PATHS } from "../API_PATHS";
import { request } from "../utils";

export const getConfirmationCode = (
  data: INewUser
): AxiosPromise<INewUserResp> => {
  return request({
    url: `${API_PATHS.BASE_URLS.API_AUTH}${API_PATHS.AUTH.VALIDATE_SEND_CONFIRMATION_CODE}`,
    method: "POST",
    data,
  });
};

export const postNewUser = (
  data: IRegisterUser
): AxiosPromise<IRegisterOrLoginUserResp> => {
  return request({
    url: `${API_PATHS.BASE_URLS.API_AUTH}${API_PATHS.AUTH.CONFIRM_EMAIL_REGISTER}`,
    method: "POST",
    data,
  });
};

export const loginUser = (
  data: ILoginUser
): AxiosPromise<IRegisterOrLoginUserResp> => {
  return request({
    url: `${API_PATHS.BASE_URLS.API_AUTH}${API_PATHS.AUTH.LOGIN}`,
    method: "POST",
    data,
  });
};

export const getAceessTokenByRefresh = (
  data: IRefreshToken
): AxiosPromise<IRegisterOrLoginUserResp> => {
  return request({
    url: `${API_PATHS.BASE_URLS.API_AUTH}${API_PATHS.AUTH.REFRESH_TOKEN}`,
    method: "POST",
    data,
  });
};

export const resetPassword = (
  data: IResetPasswordData
): AxiosPromise<IResetPasswordResp> => {
  return request({
    url: `${API_PATHS.BASE_URLS.API_AUTH}${API_PATHS.AUTH.RESET_PASSWORD}`,
    method: "POST",
    data,
  });
};

export const resetPasswordConfirmEmail = (
  data: IResetPasswordConfirmEmailData
): AxiosPromise<IResetPasswordConfirmEmailResp> => {
  return request({
    url: `${API_PATHS.BASE_URLS.API_AUTH}${API_PATHS.AUTH.CONFIRM_EMAIL}`,
    method: "POST",
    data,
  });
};

export const changePassword = (
  data: IChangePasswordData
): AxiosPromise<IChangePasswordResp> => {
  return request({
    url: `${API_PATHS.BASE_URLS.API_AUTH}${API_PATHS.AUTH.CHANGE_PASSWORD}`,
    method: "POST",
    data,
  });
};
