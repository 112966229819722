import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "..";
import { EAuthSteps } from "../../types/formTypes";

interface IInitialState {
  step: EAuthSteps;
}

const initialState: IInitialState = {
  step: EAuthSteps.Login,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthStep(state, action: PayloadAction<EAuthSteps>) {
      state.step = action.payload;
    },
    resetAuthSteps(state) {
      state.step = EAuthSteps.Login;
    },
  },
});

export const selectAuthStep = (state: RootState) => state.auth.step;

export const { setAuthStep, resetAuthSteps } = authSlice.actions;

export default authSlice.reducer;
