import { useRef, useState } from "react";
import { useCookies } from "react-cookie";

import styles from "./DataSources.module.scss";

import { ReactComponent as Exel } from "../../../../assets/icons/exel-icon.svg";
import { useClickOutside } from "../../../../hooks/useClickOutside";
import { useAppDispatch } from "../../../../store/hooks/hooks";
import {
  setTemplateSPage,
  setTemplateStep,
} from "../../../../store/slices/stepSlice";
import { setDemo } from "../../../../store/slices/uploadFileSlice";
import { ETemplatePage, ETemplateStep } from "../../../../types/steps";
import { Auth } from "../../../Auth";
import { ReactPortal } from "../../../ReactPortal";
import { ReactPortalSubstrate } from "../../../ReactPortalSubstrate";
import { Title } from "../../../Title";
import { DataSourcesItem } from "../DataSourcesItem";

export const DataSources = () => {
  const [isPortalOpen, seIsPortalOpen] = useState(false);

  const [token] = useCookies(["accessToken"]);

  const dispatch = useAppDispatch();

  const handelClick = () => {
    dispatch(setTemplateSPage(ETemplatePage.SelectTemplatePage));
    dispatch(setTemplateStep(ETemplateStep.step2));
    if (token.accessToken) {
      dispatch(setDemo("demo"));
    }
  };

  const formRef = useRef<HTMLDivElement>(null);

  const onOpenClick = () => {
    if (token.accessToken) {
      dispatch(setTemplateSPage(ETemplatePage.SelectTemplatePage));
      dispatch(setTemplateStep(ETemplateStep.step2));
      dispatch(setDemo("upload"));
    }
    seIsPortalOpen(true);
  };

  const onCloseClick = () => {
    seIsPortalOpen(false);
  };

  useClickOutside(formRef, onCloseClick);

  return (
    <div className={styles.root}>
      <Title tagName={"h4"}>Available Data Sources</Title>
      <div className={styles.wrapper}>
        <DataSourcesItem
          onClick={onOpenClick}
          icon={<Exel />}
          title={"Upload your Data"}
          className={styles.item}
        />
        <DataSourcesItem
          onClick={handelClick}
          icon={<Exel />}
          title={"Demo Data"}
          mark={true}
          className={styles.item}
        />
      </div>
      {isPortalOpen && (
        <ReactPortal>
          <ReactPortalSubstrate className={styles.portalWrapper}>
            <Auth ref={formRef} onClose={onCloseClick} />
          </ReactPortalSubstrate>
        </ReactPortal>
      )}
    </div>
  );
};
