import { useNavigate } from "react-router-dom";

import styles from "./MainContent.module.scss";

import { PATHS } from "../../../../constants/PATHS";
import { Description } from "../../../Description";
import { CustomButton } from "../../../FormFields/CustomButton";
import { Title } from "../../../Title";

export const MainContent = () => {
  const navigate = useNavigate();

  const getDayGreeting = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();

    return dayOfWeek === 5 ? "" : "almost";
  };

  const handleNavigate = () => {
    navigate(PATHS.LOAD_DATA_PATH);
  };

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <Title tagName={"h1"}>
          Thank God it is {getDayGreeting()}{" "}
          <span className={styles.friday}>Friday!</span>
        </Title>
        <div className={styles.wrapperText}>
          <Description theme={"t1"} className={styles.text}>
            Here you can conveniently download and manage project reports. Our
            platform makes it easy to create reports with metrics, graphs and
            charts to assess the progress of projects.
          </Description>
          <Title tagName={"h4"}>
            Start building your report in a few clicks!
          </Title>
        </div>
      </div>
      <CustomButton className={styles.button} onClick={handleNavigate}>
        Start
      </CustomButton>
    </div>
  );
};
