import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ImportConfiguration } from "../../types/importConfiguration";
import { RootState } from "../index";

interface ImportConfigurationState {
  importConfigurations: ImportConfiguration[] | null;
  selectedImportConfiguration: ImportConfiguration | null;
}

const initialState: ImportConfigurationState = {
  importConfigurations: null,
  selectedImportConfiguration: null
};

export const importConfigurationSlice = createSlice({
  name: "importConfiguration",
  initialState,
  reducers: {
    setSliceImportConfigurations(state, action: PayloadAction<ImportConfiguration[] | null>) {
      state.importConfigurations = action.payload;
    },
    setSliceSelectedImportConfiguration(state, action: PayloadAction<ImportConfiguration | null>) {
      state.selectedImportConfiguration = action.payload;
    }
  }
});

export const selectSliceImportConfigurations = (state: RootState) => state.importConfiguration.importConfigurations;
export const selectSliceSelectedImportConfiguration = (state: RootState) =>
  state.importConfiguration.selectedImportConfiguration;
export const { setSliceImportConfigurations, setSliceSelectedImportConfiguration } =
  importConfigurationSlice.actions;
