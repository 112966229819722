import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { useUserData } from "./useUserProfile";

import { getVersionsProject } from "../api/reports/reports";
import { createErrorMessage } from "../utils/createErrorMessage";

export const useFileVersions = () => {
  const { organizationId, tenantId } = useUserData();
  const location = useLocation();

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ["getVersions", tenantId],
    queryFn: async () => {
      const urlParts = location.pathname.split("/")[2];

      try {
        const versionsList = await getVersionsProject(
          urlParts,
          organizationId,
          tenantId
        );

        return versionsList.data.data;
      } catch (error) {
        toast.error(createErrorMessage(error));
      }
    },
    enabled: !!tenantId,
  });

  return { data, isLoading, error, refetch };
};
