import { FC } from "react";
import { OptionProps, components } from "react-select";

export const CustomSelectOption: FC<OptionProps<any>> = ({
  children,
  ...props
}) => {
  return (
    <components.Option {...props}>
      {children}
      {props.isSelected && props.data.icon && props.data.icon}
    </components.Option>
  );
};
