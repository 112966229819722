import { forwardRef } from "react";

import styles from "./DeleteReport.module.scss";

import { Description } from "../../../Description";
import { CustomButton } from "../../../FormFields/CustomButton";
import { Title } from "../../../Title";

interface IProps {
  id: string;
  onClose: () => void;
  onDeleteClick: (id: string) => void;
}

export const DeleteReport = forwardRef<HTMLDivElement, IProps>(
  ({ onClose, onDeleteClick, id }, ref) => {
    return (
      <div ref={ref} className={styles.root}>
        <Title tagName={"h3"}>Delete report?</Title>
        <Description theme={"t2"} className={styles.description}>
          Are you sure you want to delete the report from the archive? This data
          will be lost.
        </Description>
        <div className={styles.wrapperBtn}>
          <CustomButton
            theme={"secondary"}
            onClick={onClose}
            className={styles.btn}
          >
            {" "}
            Cancel
          </CustomButton>
          <CustomButton
            className={styles.btn}
            onClick={() => onDeleteClick(id)}
          >
            Delete
          </CustomButton>
        </div>
      </div>
    );
  },
);
