import { API_PATHS } from "../API_PATHS";
import { request } from "../utils";

export const getIssueVersions = (organizationId: number, tenantId: number) => {
  return request({
    method: "GET",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.ISSUES_SUMMARY.GET_VERSIONS(organizationId, tenantId)}`,
  });
};

export const getListIssues = (
  fileId: string,
  orgId: number,
  tenantId: number,
  owners: string[],
  startDate: string,
  endDate: string,
) => {
  return request({
    method: "GET",
    // eslint-disable-next-line max-len
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.ISSUES_SUMMARY.GET_LIST_REPORT(fileId, orgId, tenantId, owners, startDate, endDate)}`,
  });
};

export const getIssuesHeader = (
  fileId: string,
  orgId: number,
  tenantId: number,
  owners?: string[],
  startDate?: string,
  endDate?: string,
) => {
  return request({
    method: "GET",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.ISSUES_SUMMARY.GET_HEADER_INFO(
      fileId,
      orgId,
      tenantId,
      owners,
      startDate,
      endDate,
    )}`,
  });
};

export const getIssuesDoughnutChartData = (
  fileId: string,
  orgId: number,
  tenantId: number,
  owners?: string[],
  startDate?: string,
  endDate?: string,
) => {
  return request({
    method: "GET",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.ISSUES_SUMMARY.GET_DOUGHNUT_CHART(
      fileId,
      orgId,
      tenantId,
      owners,
      startDate,
      endDate,
    )}`,
  });
};

export const getIssuesBarChartData = (
  fileId: string,
  orgId: number,
  tenantId: number,
  owners?: string[],
  startDate?: string,
  endDate?: string,
) => {
  return request({
    method: "GET",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.ISSUES_SUMMARY.GET_BAR_CHART(
      fileId,
      orgId,
      tenantId,
      owners,
      startDate,
      endDate,
    )}`,
  });
};
