import { useQuery } from "@tanstack/react-query";
import {useEffect, useState} from "react";
import { toast } from "react-toastify";

import styles from "./HistoryPage.module.scss";

import { getHistoryTemplate } from "../../api/history/history";
import { EmptyHistoryReports } from "../../components/History/components/EmptyHistoryReports";
import { HeaderHistory } from "../../components/History/components/HeaderHistory";
import { HistoryTable } from "../../components/History/components/HistoryTable";
import { Loader } from "../../components/Loader";
import { useUserData } from "../../hooks/useUserProfile";
import {useAppSelector} from "../../store/hooks/hooks";
import {selectIdHistory, selectName} from "../../store/slices/historySlice";
import { IHistoryReports } from "../../types/historyReports";
import { createErrorMessage } from "../../utils/createErrorMessage";

export const HistoryPage = () => {
  const [listTable, setListTable] = useState<IHistoryReports[] | null>(null);
  const [update, setUpdate] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { organizationId, tenantId } = useUserData();
  const reportName = useAppSelector(selectName)
  const reportId = useAppSelector(selectIdHistory)

  const { isLoading: queryLoading } = useQuery({
    queryKey: ["getHistoryTemplates", tenantId, organizationId, reportId, reportName, update],
    queryFn: async () => {
      try {
        const res = await getHistoryTemplate(organizationId, tenantId, reportId);
        setListTable(res.data.data);
        setIsLoading(false)
      } catch (error) {
        toast.error(createErrorMessage(error));
      }
    },
    enabled: !!tenantId,
  });

  useEffect(() => {
    setIsLoading(true)
  }, [tenantId, organizationId, reportId, reportName, update]);

  return (
    <div className={styles.root}>
      <HeaderHistory />
      {!!listTable?.length && !isLoading && (
        <HistoryTable
          listTable={listTable}
          organizationId={organizationId}
          tenantId={tenantId}
          setUpdate={setUpdate}
        />
      )}
      {!isLoading && !listTable?.length && <EmptyHistoryReports />}
      {isLoading && <Loader className={styles.loader} />}
    </div>
  );
};
