export interface ILoginForm {
  email: string;
  password: string;
}

export interface ISignupForm extends ILoginForm {
  confirmPassword: string;
}

export const enum EAuthSteps {
  "Login",
  "Sign up",
  "Confirmation",
  "Registration completed!",
  "Reset password",
  "Check your email",
  "Change password",
  "Password changed successfully!",
}
