import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import styles from "./DashBoardTable.module.scss";

import { getListIssues } from "../../../../../api/issuesSummary/issuesSummary";
import { getListMilestones } from "../../../../../api/milestonesSummary/milestonesSummary";
import { getTableInfo } from "../../../../../api/reports/reports";
import { useUserData } from "../../../../../hooks/useUserProfile";
import { useAppSelector } from "../../../../../store/hooks/hooks";
import {
  selectOwners,
  selectPeriodFrom,
  selectPeriodTo,
} from "../../../../../store/slices/reportsSlice/IssuesSlice";
import {
  selectCompleted,
  selectMilestoneType,
  selectProjects,
  selectFinishFrom,
  selectFinishTo,
} from "../../../../../store/slices/reportsSlice/milestonesSlice";
import { selectCurrentId } from "../../../../../store/slices/stepSlice";
import { createErrorMessage } from "../../../../../utils/createErrorMessage";
import { formatDate } from "../../../../../utils/formatDate";
import { Loader } from "../../../../Loader";

export const DashBoardTable = () => {
  const [listTable, setListTable] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tableHeaders, setTableHeaders] = useState<{ [key: string]: any }>({});

  const currentId = useAppSelector(selectCurrentId);
  const owners = useAppSelector(selectOwners);
  const periodFrom = useAppSelector(selectPeriodFrom);
  const periodTo = useAppSelector(selectPeriodTo);

  const projects = useAppSelector(selectProjects);
  const milestoneType = useAppSelector(selectMilestoneType);
  const completed = useAppSelector(selectCompleted);
  const finishFrom = useAppSelector(selectFinishFrom);
  const finishTo = useAppSelector(selectFinishTo);

  const dateKeys = [
    "baselineFinish",
    "baselineStart",
    "endDate",
    "startDate",
    "start",
    "finish",
    "issueDueDate",
  ];

  const { organizationId, tenantId } = useUserData();
  const { reportId } = useParams();
  const location = useLocation();

  const generateTableHeaders = (data: any) => {
    const headers: { [key: string]: { title: string } } = {};
    for (const key in data) {
      headers[key] = {
        title: key
          .replace(/([A-Z])/g, " $1")
          .trim()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
      };
    }
    return headers;
  };
  const urlParts = location.pathname.split("/")[2];

  const { isLoading: isLoadingChart } = useQuery({
    queryKey: [
      "getListTable",
      owners,
      periodFrom,
      periodTo,
      projects,
      milestoneType,
      completed,
      finishFrom,
      finishTo,
      currentId,
    ],
    queryFn: async () => {
      if (tenantId && reportId) {
        try {
          let tableData;
          switch (urlParts) {
            case "ProjectSummary":
              tableData = await getTableInfo(
                urlParts,
                currentId ? currentId : reportId,
                organizationId,
                tenantId
              );
              break;
            case "IssueSummary":
              tableData = await getListIssues(
                currentId ? currentId : reportId,
                organizationId,
                tenantId,
                owners,
                periodFrom,
                periodTo
              );
              break;
            case "MilestoneSummary":
              tableData = await getListMilestones(
                currentId ? currentId : reportId,
                organizationId,
                tenantId,
                projects,
                milestoneType,
                completed,
                finishFrom,
                finishTo
              );
              break;
          }
          setListTable(tableData?.data.data);
          const headers = generateTableHeaders(tableData?.data.data[0]);
          setTableHeaders(headers);
          setIsLoading(false);
        } catch (error) {
          toast.error(createErrorMessage(error));
        }
      }
    },
    enabled: (() => {
      switch (urlParts) {
        case "ProjectSummary":
          return !!tenantId && !!reportId && (!!currentId || !!reportId);
        case "IssueSummary":
          return (
            !!tenantId &&
            !!reportId &&
            (!!currentId || !!reportId) &&
            !!owners &&
            !!periodFrom &&
            !!periodTo
          );
        case "MilestoneSummary":
          return (
            !!tenantId &&
            !!reportId &&
            (!!currentId || !!reportId) &&
            !!completed &&
            !!projects &&
            !!milestoneType &&
            !!finishFrom &&
            !!finishTo
          );
        default:
          return true;
      }
    })(),
  });

  useEffect(() => {
    setIsLoading(true);
  }, [
    owners,
    periodFrom,
    periodTo,
    projects,
    milestoneType,
    completed,
    finishFrom,
    finishTo,
  ]);

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <div className={styles.root}>
          <table className={styles.table}>
            <thead>
              <tr className={styles.tableRow}>
                {Object.values(tableHeaders).map((header, index) => (
                  <th key={index}>{header.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {listTable?.map((item, index) => (
                <tr key={index} className={styles.row}>
                  {Object.keys(tableHeaders).map((key, index) => (
                    <td key={index} className={styles.nameProd}>
                      {dateKeys.includes(key)
                        ? formatDate(item[key])
                        : item[key]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};
