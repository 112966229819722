import { FC } from "react";

import styles from "./SignUpComplitedForm.module.scss";

import complitedImg from "../../../../assets/img/complited.png";
import { AUTH_FORMS } from "../../../../constants/AUTH_FORMS";
import { CustomButton } from "../../../FormFields/CustomButton";
import { FormVerticalWrapper } from "../../../FormFields/FormVerticalWrapper";
import { FormImg } from "../FormImg";
import { FormTitle } from "../FormTitle";

interface IProps {
  onClose?: () => void;
}

export const SignUpComplitedForm: FC<IProps> = ({ onClose }) => {
  return (
    <div className={styles.root}>
      <FormVerticalWrapper>
        <FormVerticalWrapper gap={24} align="center">
          <FormImg src={complitedImg} />
          <FormTitle
            text={AUTH_FORMS.NAMES.REGISTRATION_COMPLETED}
            description={AUTH_FORMS.SUBTITLES.REGISTRATION_COMPLETED}
            aligment="center"
          />
        </FormVerticalWrapper>
        <CustomButton className={styles.btn} onClick={onClose}>
          {AUTH_FORMS.BTNS.START}
        </CustomButton>
      </FormVerticalWrapper>
    </div>
  );
};
