import { FC } from "react";

import styles from "./FormImg.module.scss";

interface IProps {
  src: string;
}

export const FormImg: FC<IProps> = ({ src }) => {
  return <img src={src} alt="" loading="lazy" className={styles.root} />;
};
