import { FC } from "react";

import styles from "./SectionTitle.module.scss";

import { Description } from "../../../Description";
import { Title } from "../../../Title";

interface IProps {
  title: string;
  description: string;
}

export const SectionTitle: FC<IProps> = ({ title, description }) => {
  return (
    <div className={styles.root}>
      <Title tagName="h3">{title}</Title>
      <Description theme={"t2"} className={styles.description}>
        {description}
      </Description>
    </div>
  );
};
