import classNames from "classnames";
import { Outlet } from "react-router";

import styles from "./Steps.module.scss";

import { useAppSelector } from "../../store/hooks/hooks";
import { selectTemplateStep } from "../../store/slices/stepSlice";
import { Title } from "../Title";

export const Steps = () => {
  const step = useAppSelector(selectTemplateStep);

  return (
    <div className={styles.root}>
      <Title tagName={"h6"} className={styles.title}>
        STEP {step + 1}
      </Title>
      <div className={styles.wrapperSteps}>
        <span
          className={classNames(styles.step, {
            [styles.active]: step >= 0,
          })}
        />
        <span
          className={classNames(styles.step, {
            [styles.active]: step >= 1,
          })}
        />
        <span
          className={classNames(styles.step, {
            [styles.active]: step >= 2,
          })}
        />
        <span
          className={classNames(styles.step, {
            [styles.active]: step >= 3,
          })}
        />
        <span
          className={classNames(styles.step, {
            [styles.active]: step >= 4,
          })}
        />
      </div>
      <Outlet />
    </div>
  );
};
