import { FC } from "react";
import { toast } from "react-toastify";

import styles from "./UpdateReport.module.scss";

import { downloadTemplate } from "../../../../api/history/history";
import { MESSAGES } from "../../../../constants/MESSAGES";
import { useUserData } from "../../../../hooks/useUserProfile";
import { useAppSelector } from "../../../../store/hooks/hooks";
import { selectParseResultIsLoading } from "../../../../store/slices/parseResultSlice";
import { selectFileUpload } from "../../../../store/slices/uploadFileSlice";
import { Description } from "../../../Description";
import { CustomButton } from "../../../FormFields/CustomButton";
import { CustomUpload } from "../../../FormFields/CustomUpload";
import { Title } from "../../../Title";

interface IProps {
  id: string;
  fileName: string;
  showPreview: (value: string) => void;
}

export const UpdateReport: FC<IProps> = ({ id, fileName, showPreview }) => {
  const isLoading = useAppSelector(selectParseResultIsLoading);
  const files = useAppSelector(selectFileUpload);

  const { organizationId, tenantId } = useUserData();

  const downloadFile = async () => {
    try {
      const res = await downloadTemplate(id, organizationId, tenantId);
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      toast.error(MESSAGES.DATA_LOADING_ERROR);
    }
  };

  return (
    <div className={styles.root}>
      <Title tagName={"h3"}>Update Report</Title>
      <div className={styles.wrapper}>
        <CustomUpload
          className={styles.upload}
          loadTemplate={styles.wrapperLoad}
        />
        <Description theme={"t4"} className={styles.download}>
          Previously uploaded file:
          <CustomButton
            theme={"text"}
            className={styles.btn}
            onClick={downloadFile}
          >
            Download
          </CustomButton>
        </Description>
      </div>
      <CustomButton
        className={styles.nextBtn}
        isDisabled={!files || isLoading}
        onClick={() => showPreview("preview")}
      >
        Next
      </CustomButton>
    </div>
  );
};
