import classNames from "classnames";
import { useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

import styles from "./Header.module.scss";

import { ReactComponent as Logo } from "../../../../assets/icons/logo-icon.svg";
import { ReactComponent as LogoText } from "../../../../assets/icons/logoText-icon.svg";
import { PATHS } from "../../../../constants/PATHS";
import { useClickOutside } from "../../../../hooks/useClickOutside";
import { Auth } from "../../../Auth";
import { Logout } from "../../../Auth/components/Logout";
import { CustomButton } from "../../../FormFields/CustomButton";
import { ReactPortal } from "../../../ReactPortal";
import { ReactPortalSubstrate } from "../../../ReactPortalSubstrate";
import { HelpBtnsWrapper } from "../HelpBtnsWrapper";
import { PageName } from "../PageName";

export const Header = () => {
  const [isPortalOpen, seIsPortalOpen] = useState(false);
  const [isLogoutOpen, seIsLogoutlOpen] = useState(false);

  const [token] = useCookies(["accessToken"]);

  const formRef = useRef<HTMLDivElement>(null);
  const logoutRef = useRef<HTMLDivElement>(null);

  const onOpenClick = () => {
    seIsPortalOpen(true);
  };

  const onCloseClick = () => {
    seIsPortalOpen(false);
  };

  const onOpenLogout = () => {
    seIsLogoutlOpen(true);
  };

  const onCloseLogout = () => {
    seIsLogoutlOpen(false);
  };

  useClickOutside(formRef, onCloseClick);
  useClickOutside(logoutRef, onCloseLogout);

  return (
    <header
      className={classNames(styles.root, {
        [styles.headerSideMenu]: token.accessToken,
      })}
    >
      {token.accessToken && <PageName />}
      {!token.accessToken && (
        <Link to={PATHS.BASE_PATH} className={styles.logoWrapper}>
          <Logo className={styles.logoIcon} />
          <LogoText className={styles.logoTextIcon} />
        </Link>
      )}
      {token.accessToken && <HelpBtnsWrapper onOpenLogout={onOpenLogout} />}
      {!token.accessToken && (
        <CustomButton theme="text" onClick={onOpenClick}>
          Login
        </CustomButton>
      )}
      {isPortalOpen && (
        <ReactPortal>
          <ReactPortalSubstrate className={styles.portalWrapper}>
            <Auth ref={formRef} onClose={onCloseClick} />
          </ReactPortalSubstrate>
        </ReactPortal>
      )}
      {isLogoutOpen && (
        <ReactPortal>
          <ReactPortalSubstrate className={styles.portalLogoutWrapper}>
            <Logout ref={logoutRef} onClose={onCloseLogout} />
          </ReactPortalSubstrate>
        </ReactPortal>
      )}
    </header>
  );
};
