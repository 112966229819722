import { useFormik } from "formik";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import * as yup from "yup";

import { changePassword } from "../../../../api";
import changePasswordImg from "../../../../assets/img/changePassword.png";
import { AUTH_FORMS } from "../../../../constants/AUTH_FORMS";
import { MESSAGES } from "../../../../constants/MESSAGES";
import { useAppDispatch } from "../../../../store/hooks/hooks";
import { setAuthStep } from "../../../../store/slices/authSlice";
import { EAuthSteps } from "../../../../types/formTypes";
import { createErrorMessage } from "../../../../utils/createErrorMessage";
import { decodeToken } from "../../../../utils/decodeToken";
import { CustomInput } from "../../../FormFields/CustomInput";
import { FormVerticalWrapper } from "../../../FormFields/FormVerticalWrapper";
import { FormImg } from "../FormImg";
import { FormSubmitWrapper } from "../FormSubmitWrapper";
import { FormTitle } from "../FormTitle";

export const ChangePasswordForm = () => {
  const [token, setToken] = useCookies<string>([
    "tempToken",
    "accessToken",
    "refreshToken",
  ]);

  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: yup.object({
      password: yup
        .string()
        .required(MESSAGES.REQUIRED)
        .min(8, MESSAGES.PASSWORDMIN)
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?!.*\s)/,
          MESSAGES.REQCHAR
        )
        .notOneOf(["password", "123456", "qwerty"], MESSAGES.NOTPATTERN),
      confirmPassword: yup
        .string()
        .required(MESSAGES.REQUIRED)
        .min(8, MESSAGES.PASSWORDMIN)
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?!.*\s)/,
          MESSAGES.REQCHAR
        )
        .notOneOf(["password", "123456", "qwerty"], MESSAGES.NOTPATTERN)
        .oneOf([yup.ref("password")], MESSAGES.PASSWORDCONFIRM),
    }),
    onSubmit: async (data) => {
      try {
        const resp = await changePassword({
          validateToken: token.tempToken,
          password: data.password,
          confirmPassword: data.confirmPassword,
        });

        const accessToken = decodeToken(resp.data.data.accessToken);
        const refreshToken = decodeToken(resp.data.data.refreshToken);

        setToken("accessToken", accessToken.token, {
          expires: accessToken.expDate,
          path: "/",
        });
        setToken("refreshToken", refreshToken.token, {
          expires: refreshToken.expDate,
          path: "/",
        });

        dispatch(setAuthStep(EAuthSteps["Password changed successfully!"]));
      } catch (error) {
        toast.error(createErrorMessage(error));
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormVerticalWrapper>
        <FormVerticalWrapper gap={32} align="center">
          <FormImg src={changePasswordImg} />
          <FormTitle
            text={AUTH_FORMS.NAMES.CHANGE_PASSWORD}
            description={AUTH_FORMS.SUBTITLES.CHANGE_PASSWORD}
          />
          <FormVerticalWrapper gap={24}>
            <CustomInput
              type="password"
              label="Password"
              id="password"
              name="password"
              placeholder="Enter new password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.errors.password}
            />
            <CustomInput
              type="password"
              label="Confirm password"
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Enter confirm password"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={formik.errors.confirmPassword}
            />
          </FormVerticalWrapper>
        </FormVerticalWrapper>
        <FormSubmitWrapper btnText={AUTH_FORMS.BTNS.SAVE_PASS} />
      </FormVerticalWrapper>
    </form>
  );
};
