import classNames from "classnames";
import { FC, ReactNode } from "react";

import styles from "./Description.module.scss";

interface IProps {
  theme: "t1" | "t2" | "t3" | "t4" | "t5" | "t6" | "t7";
  children?: ReactNode | string;
  className?: string;
}

export const Description: FC<IProps> = ({ theme, children, className }) => {
  return (
    <p className={classNames(styles.root, styles[theme], className)}>
      {children}
    </p>
  );
};
