import classNames from "classnames";
import { FC, ReactElement } from "react";

import styles from "./DataSourcesItem.module.scss";

import { CustomButton } from "../../../FormFields/CustomButton";
import { Title } from "../../../Title";

interface IProps {
  icon: ReactElement;
  title: string;
  mark?: boolean;
  className?: string;
  onClick?: () => void;
}
export const DataSourcesItem: FC<IProps> = ({
  icon,
  title,
  mark,
  className,
  onClick,
}) => {
  return (
    <CustomButton
      theme={"unstyled"}
      className={classNames(styles.root, className)}
      onClick={onClick}
    >
      {icon}
      <Title tagName={"h5"} className={styles.title}>
        {title}
      </Title>
      {mark && <span className={styles.mark}>DEMO</span>}
    </CustomButton>
  );
};
