import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import styles from "./Header.module.scss";

import { getIssuesHeader } from "../../../../../api/issuesSummary/issuesSummary";
import { useUserData } from "../../../../../hooks/useUserProfile";
import { useAppSelector } from "../../../../../store/hooks/hooks";
import { selectCurrentId } from "../../../../../store/slices/stepSlice";
import { HeaderIssues } from "../../../../../types/issuesSummary";
import { createErrorMessage } from "../../../../../utils/createErrorMessage";
import { formatDate } from "../../../../../utils/formatDate";
import { Loader } from "../../../../Loader";
import { HeaderLogo } from "../../../HederLogo";
import { HeaderFilters } from "../HeaderFilters";

export const Header = () => {
  const [headerList, setHeaderList] = useState<HeaderIssues>();

  const currentId = useAppSelector(selectCurrentId);

  const { organizationId, tenantId } = useUserData();
  const { reportId } = useParams();

  const { isLoading } = useQuery({
    queryKey: ["getHeaderIssues", tenantId, reportId, currentId],
    queryFn:
      tenantId &&
      reportId &&
      (async () => {
        try {
          const headerInfo = await getIssuesHeader(
            currentId ? currentId : reportId,
            organizationId,
            tenantId,
          );
          setHeaderList(headerInfo.data.data);
        } catch (error) {
          toast.error(createErrorMessage(error));
        }
      }),
    enabled: !!tenantId && !!reportId && (!!currentId || !!reportId),
  });

  return (
    <div className={styles.root}>
      <HeaderLogo title={headerList?.reportName} />
      {isLoading && <Loader />}

      {!isLoading && headerList && (
        <HeaderFilters
          count={headerList?.count}
          countOpened={headerList?.countOpenedIssues}
          maxPeriod={formatDate(headerList.maxPeriod)}
          minPeriod={formatDate(headerList.minPeriod)}
          owners={headerList?.owners}
        />
      )}
    </div>
  );
};
