import { API_PATHS } from "../API_PATHS";
import { request } from "../utils";

export const getProjectDoughnutChartData = (
  fileId: string,
  orgId: number,
  tenantId: number,
) => {
  const url = "ProjectSummaryReport/GetBudgetCostByBusinessSponsor";
  return request({
    method: "GET",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.PROJECT_SUMMARY.GET_DOUGHNUT_CHART(fileId, orgId, tenantId, url)}`,
  });
};

export const getProjectVerticalBarChartData = (
  fileId: string,
  orgId: number,
  tenantId: number,
) => {
  const url = "ProjectSummaryReport/GetBudgetCostByProjectPhase";
  return request({
    method: "GET",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.PROJECT_SUMMARY.GET_VERTICAL_CHART(fileId, orgId, tenantId, url)}`,
  });
};

export const getProjectHeader = (
  fileId: string,
  orgId: number,
  tenantId: number,
) => {
  return request({
    method: "GET",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.PROJECT_SUMMARY.GET_HEADER_INFO(fileId, orgId, tenantId)}`,
  });
};
