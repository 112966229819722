import { ReactElement } from "react";

import { ReactComponent as History } from "../../../../../../assets/icons/history-icon.svg";
import { ReactComponent as Load } from "../../../../../../assets/icons/load-icon.svg";
import { ReactComponent as Reports } from "../../../../../../assets/icons/reports-icon.svg";
import { PATHS } from "../../../../../../constants/PATHS";

export interface sideMenuItem {
  id: string;
  title: string;
  icon: ReactElement;
  link: string;
}

export const sideMenuNavigation = [
  {
    id: "tab-11",
    title: "Load Data",
    icon: <Load />,
    link: PATHS.LOAD_DATA_PATH,
  },
  {
    id: "tab-12",
    title: "Reports",
    icon: <Reports />,
    link: PATHS.REPORTS_PATH,
  },
  {
    id: "tab-13",
    title: "History",
    icon: <History />,
    link: PATHS.HISTORY_PATH,
  },
];
