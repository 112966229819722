import { nanoid } from "@reduxjs/toolkit";
import classNames from "classnames";
import { FC } from "react";
import ReactSelect, { MultiValue, SingleValue } from "react-select";

import { CustomSelectOption } from "./components/CustomSelectOption";
import styles from "./CustomSelect.module.scss";
import { setCustomStyles } from "./setCustomStyles";
import { IOptions } from "./types";

interface IProps {
  options: IOptions[];
  id?: string;
  label?: string;
  selectTheme?: "standard" | "withoutBorder" | "labelInsideBorder";
  placeholder?: string;
  value?: IOptions;
  onChange?: (newValue: MultiValue<IOptions> | SingleValue<IOptions>) => void;
  isSearchable?: boolean;
}

export const CustomSelect: FC<IProps> = ({
  options,
  value,
  id = nanoid(),
  label,
  selectTheme = "standard",
  placeholder = "Placeholder",
  onChange,
  isSearchable,
}) => {
  return (
    <div
      className={classNames(styles.root, {
        [styles[selectTheme]]: selectTheme,
      })}
    >
      {label && <label htmlFor={id}>{label}</label>}
      <ReactSelect
        id={id}
        inputId={id}
        styles={setCustomStyles(selectTheme)}
        placeholder={placeholder}
        options={options}
        value={value}
        components={{ Option: CustomSelectOption }}
        onChange={onChange}
        isSearchable={isSearchable}
      />
    </div>
  );
};
