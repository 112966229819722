import { ComingSoonData } from "./components/ComingSoonData";
import { ConnectDataText } from "./components/ConnectDataText";
import { DataSources } from "./components/DataSources";
import styles from "./ConnectData.module.scss";

export const ConnectData = () => {
  return (
    <div className={styles.root}>
      <ConnectDataText />
      <DataSources />
      <ComingSoonData />
    </div>
  );
};
