import styles from "./PrivacyPolicyPage.module.scss";

import { CustomLink } from "../../components/CustomLink";

export const PrivacyDataList = [
  {
    id: "0",
    title: "",
    text: (
      <>
        <p>Effective as of: April, 2024</p>
        <p>
          Your privacy is extremely important to us. To better protect you, we
          provide this notice explaining our online information practices and
          the choices you can make about the way your information is collected
          and used.
        </p>
        <p>
          This Privacy Policy relates to information collected by Blockchain
          Systems LLC. DBA Friday Report (referred to in this Privacy Policy as
          "Friday Report" "we" or "us" or "our") through your use of our website
          and our Services, features, and information available on our website
          (which are collectively referred to in this Privacy Policy as the
          "Friday Report Service").
        </p>
        <strong>
          If you do not agree to our use of your personal data in line with this
          policy, please do not use the Friday Report Services.
        </strong>
      </>
    ),
  },
  {
    id: "1",
    title: "Scope of this Privacy Policy",
    text: (
      <>
        <p>
          Please be aware that this Privacy Policy does not govern all the
          information Friday Report may process. Our commitment to customers and
          service providers is governed by a separate Data Protection Addendum.
          This Privacy Policy is also incorporated into and governed by our
          Terms of Use.
        </p>
        <p>
          Any capitalized words we use in this Privacy Policy that we haven’t
          defined here will have the same meaning that they’re given in our
          Terms of Use.
        </p>
        <p>
          Our commitments to employees are governed by our internal employment
          policies. In all instances we are committed to transparency with our
          customers, employees, and to protecting your data privacy.
        </p>
      </>
    ),
  },
  {
    id: "2",
    title: "What Information do we Collect?",
    text: (
      <>
        <p>
          You will be required to establish an account in order to take
          advantage of certain features of the Friday Report Service. If you
          wish to establish an account you will be required to provide us with
          information (including personally identifiable information and
          non-personally identifiable information). In addition, we may obtain
          your personally identifiable information from you if you identify
          yourself to us by sending us an e-mail with questions or comments.
        </p>
        <p>
          Depending on your use of the Friday Report Service, we collect two
          types of information: personally identifiable information and
          non-personally identifiable information.
        </p>
      </>
    ),
  },
  {
    id: "3",
    title: "Personally Identifiable Information",
    text: (
      <>
        Personally identifiable information identifies you or can be used to
        identify or contact you. Examples of personally identifiable information
        may include your name, IP address, company name, job title, address,
        e-mail address, telephone number, and billing and credit card
        information.
      </>
    ),
  },
  {
    id: "4",
    title: "Non-Personally Identifiable Information",
    text: (
      <>
        Non-personally identifiable information is information, any single item
        of which, by itself, cannot be used to identify or contact you, which
        may include demographic information (such as age, profession, company
        industry, gender, current location, or zip code), IP addresses, browser
        types, domain names, and statistical data involving the use of the
        Friday Report Service. Certain non-personally identifiable information
        may be considered a part of your personally identifiable information if
        it were combined with other identifiers (for example, combining your zip
        code with your street address) in a way that enables you to be
        identified. But the same pieces of information may be considered
        non-personally identifiable information when they are taken alone or
        combined only with other non-personally identifiable information (for
        example, your account preferences).
      </>
    ),
  },
  {
    id: "5",
    title: "Why do we Need your Personal Data?",
    text: (
      <>
        We do not sell any data, including your personal data. We will only
        collect and process your personal data in accordance with applicable
        data protection and privacy laws. We need to collect and process certain
        personal data in order to provide you with access to Friday Report. If
        you registered with us, you will have been asked to check a tick box
        indicating your agreement to provide this data in order to access our
        services. This consent provides us with the legal basis we require under
        applicable law to process your data. You maintain the right to withdraw
        such consent at any time.
      </>
    ),
  },
  {
    id: "6",
    title: "Information we collect automatically when you use the Services:",
    text: (
      <>
        We collect information about you when you use our Services, such as
        browsing our websites and taking certain actions within the Services,
        including:
        <ul>
          <li>
            Your use of the Services: We keep track of certain information about
            you when you visit and interact with any of our Services. This
            information includes the features you use; the tasks, projects,
            teams and other links you click on; the type, size and filenames of
            attachments you upload to the Services; frequently used search
            terms; and how you interact with others on the Services. We also
            collect information about the teams and people you work with and how
            you interact with them, like who you collaborate and communicate
            with most frequently.
          </li>
          <li>
            Other services you link to your account: We receive information
            about you when you or your administrator enable third-party apps or
            integrate or link a third-party service with our Services. For
            example, you or your administrator may also integrate our Services
            with other services you use, such as to allow you to access, store,
            share and edit certain content from a third-party through our
            Services. For example, you may authorize our Services to access and
            display files from a third-party document-sharing service within the
            Services interface. The information we receive when you link or
            integrate our Services with a third-party service depends on the
            settings, permissions and privacy policy controlled by that
            third-party service. You should always check the privacy settings
            and notices in these third-party services to understand what data
            may be disclosed to us or shared with our Services.
          </li>
          <li>
            Other partners: We may receive information about you and your
            activities on and off the Services from third-party partners, such
            as advertising and market research partners who provide us with
            information about your interest in, and engagement with, our
            Services and online advertisements, or in cases where you give such
            third parties permission to share information with us or where the
            information is publicly available online or through your device or
            browser data.
          </li>
          <li>
            Friday Report's use of information received, and Friday Report's
            transfer of information to any other app, from Google APIs will
            adhere to Google's API Services User Data Policy, including the
            Limited Use Requirements.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: "7",
    title: (
      <>
        <p>Your Rights</p>
        <p>Where and when is information collected?</p>
      </>
    ),
    text: (
      <>
        <p>
          Opt-out: You may contact us anytime to opt-out of: (i) direct
          marketing communications; (ii) automated decision-making and/or
          profiling; (iii) our collection of sensitive personal data, if any; or
          (iv) any new processing of your personal data that we may carry out
          beyond the original purpose. Please note that your use of some of the
          platform may be ineffective upon opt-out.
        </p>{" "}
        <p>
          Access: You may access the data we hold about you at any time by
          exporting your Team’s data (if you are an admin) or by contacting us
          directly. Amend: You can also contact us to update or correct any
          inaccuracies in your personal data.
        </p>{" "}
        <p>
          Move: Your data is portable – i.e. you have the flexibility to move
          your data to other service providers as you wish.
        </p>{" "}
        <p>
          Erase and Forget: In certain situations, for example when the data we
          hold about you is no longer relevant or is incorrect, you can request
          that we erase your data.
        </p>{" "}
        <p>
          You may contact us at{" "}
          <CustomLink
            link="mailto:support@friday-report.com"
            className={styles.link}
          >
            support@friday-report.com
          </CustomLink>{" "}
          or via mail or courier 8586 Potter Park Dr, 106, Sarasota, FL 34238
          anytime for any other questions you may have about your personal data
          and our use of such personal data.
        </p>
      </>
    ),
  },
  {
    id: "8",
    title: "Where will Data be Transferred?",
    text: (
      <>
        Your data, including personal data that we collect from you, may be
        transferred to, stored at and processed by us and other third parties
        outside the country in which you reside, including, but not limited to
        the United States, where data protection and privacy regulations may not
        offer the same level of protection as in other parts of the world. By
        using our platform, you agree to this transfer, storing or processing
        subject to the terms of our Data Protection Addendum. We will take all
        steps reasonably necessary to ensure that your data is treated securely
        and in accordance with this policy. Except as otherwise provided in our
        Data Protection Addendum, your team's project and task data will never
        be transferred to third parties. The only data we share with third
        parties is for analytics, error tracking, and marketing.
      </>
    ),
  },
  {
    id: "9",
    title: "How Long will Data be Stored?",
    text: (
      <>
        We will only retain your personal data as long as reasonably required
        for you to use the Site/Application(s) and/or to provide you with the
        Services unless a longer retention period is required or permitted by
        law (for example, for regulatory purposes).
      </>
    ),
  },
  {
    id: "10",
    title: "Do we Collect Information from Children under 16 years of age?",
    text: (
      <>
        We are committed to protecting the privacy of children. The Friday
        Report Service is not designed for or directed to children under the age
        of 16. We do not collect personally identifiable information from any
        person we actually know is under the age of 16.
      </>
    ),
  },
  {
    id: "11",
    title: "What do we do with the information we Collect?",
    text: (
      <>
        <p>
          In general, we use the information collected to provide you with a
          great overall experience using the Friday Report Service, to help us
          understand who uses the Friday Report Service, for internal operations
          such as operating and improving the Friday Report Service, to contact
          you for customer service and billing purposes, and to facilitate the
          delivery of our advertising in some cases.
        </p>
        <p>
          We use your information to send you a welcome e-mail after you create
          an account, when you are invited to Friday Report, or when you sign up
          for a demo, webinar or to receive our newsletter. We may also use your
          information to send other e-mail communication related to the Friday
          Report website and to give you the option to reactivate an inactive
          account. When required, we will always give you the option to
          unsubscribe in any email we send you.
        </p>
        <p>
          We may also use the information gathered to perform statistical
          analysis of user behavior or to evaluate and improve the Friday Report
          Service. We may link some of this information to personally
          identifiable information for internal purposes or to improve your
          experience with the Friday Report Service.
        </p>
      </>
    ),
  },
  {
    id: "12",
    title: "When do we Disclose Information to Third Parties?",
    text: (
      <>
        Except as set forth in this Privacy Policy, the Cookie Policy, or the
        Friday Report Data Protection Addendum or as specifically authorized by
        you, we will not disclose any information to third parties outside of
        Friday Report.
      </>
    ),
  },
  {
    id: "13",
    title: "Laws and Legal Rights",
    text: (
      <>
        <p>
          We may disclose your information (including personally identifiable
          information) if we believe in good faith that we are required to do so
          in order to comply with an applicable statute, regulation, rule or
          law, a subpoena, a search warrant, a court or regulatory order, or
          other valid legal process. We may disclose personally identifiable
          information in special circumstances when we have reason to believe
          that disclosing this information is necessary to identify, contact, or
          bring legal action against someone who may be violating our Terms of
          Service, to detect fraud, or to protect the safety and/or security of
          our users, the Friday Report Service or the general public. We are
          subject to the investigatory and enforcement powers of the Federal
          Trade Commission (FTC).
        </p>
        <p>
          We also may be required to disclose an individual’s personal
          information in response to a lawful request by public authorities,
          including to meet national security or law enforcement requirements.
        </p>
      </>
    ),
  },
  {
    id: "14",
    title: "Outside Contractors",
    text: (
      <>
        We may employ independent contractors, vendors and suppliers
        (collectively, "Outside Contractors") to provide specific services and
        products related to the Friday Report Service, such as hosting, credit
        card processing and fraud screening, and mailing list hosting for the
        Friday Report Service. In the course of providing products or services
        to us, these Outside Contractors may have access to information
        collected through the Friday Report Service, including your personally
        identifiable information. We require that these contractors agree to (1)
        protect the privacy of your personally identifiable information
        consistent with this Privacy Policy or the Data Protection Addendum and
        (2) not use or disclose your personally identifiable information for any
        purpose other than providing us with the products or services for which
        we contracted or as required by law. Please see our Data Protection
        Addendum for more information about subprocessors.
      </>
    ),
  },
  {
    id: "15",
    title: "Sale of Business",
    text: (
      <>
        We reserve the right to transfer information to a third party in the
        event of a sale, merger or other transfer of all or substantially all of
        the assets of Friday Report or any of its Corporate Affiliates (as
        defined herein), or that portion of Friday Report or any of its
        Corporate Affiliates to which the Service relates, or in the event that
        we discontinue our business or file a petition or have filed against us
        a petition in bankruptcy, reorganization or similar proceeding, provided
        that the third party agrees to adhere to the terms of this Privacy
        Policy.
      </>
    ),
  },
  {
    id: "16",
    title: "Affiliates",
    text: (
      <>
        We may disclose information (including personally identifiable
        information) about you to our Corporate Affiliates. For purposes of this
        Privacy Policy, "Corporate Affiliate" means any person or entity which
        directly or indirectly controls, is controlled by or is under common
        control with Friday Report, whether by ownership or otherwise. Any
        information relating to you that we provide to our Corporate Affiliates
        will be treated by those Corporate Affiliates in accordance with the
        terms of this Privacy Policy.
      </>
    ),
  },
  {
    id: "17",
    title:
      "Does this Privacy Policy apply when I link to other websites or services?",
    text: (
      <>
        The Friday Report Service may provide you with access to other websites
        and services. Please be aware that we are not responsible for the
        privacy practices of any websites or services other than the Friday
        Report Service. We encourage you to read the privacy policies or
        statements of each and every such website and service. This Privacy
        Policy applies solely to information collected by us through the Friday
        Report Service.
      </>
    ),
  },
  {
    id: "18",
    title:
      "Is the information collected through the Friday Report Service secure?",
    text: (
      <>
        We take precautions to protect the security of your information. We have
        physical, electronic, and managerial procedures to help safeguard,
        prevent unauthorized access, maintain data security, and correctly use
        your information. However, neither people nor security systems are
        foolproof, including encryption systems. In addition, people can commit
        intentional crimes, make mistakes or fail to follow policies. If
        applicable law imposes any non-disclaimable duty to protect your
        personally identifiable information, you agree that intentional
        misconduct will be the standards used to measure our compliance with
        that duty. For more information about our policies and standards used to
        ensure security, please see our Security Policy.
      </>
    ),
  },
  {
    id: "19",
    title: "Could my Information be Transferred to other Countries?",
    text: (
      <>
        Personally identifiable information collected on the Friday Report
        Service may be transferred from time to time to our offices or
        personnel, or to third parties, located throughout the world, and the
        Friday Report Service may be viewed and hosted anywhere in the world,
        including countries that may not have laws of general applicability
        regulating the use and transfer of such data. To the fullest extent
        allowed by applicable law, by using the Friday Report Service and
        submitting such information on it, you voluntarily consent to the
        trans-border transfer and hosting of such information. To the fullest
        extent allowed by applicable law, if you are a User accessing the Friday
        Report Service from a jurisdiction with laws or regulations governing
        personal data collection, use, and disclosure that differ from those of
        the United States, please be advised that all aspects of the Friday
        Report Service are governed by the internal laws of the United States
        and the State of Florida, USA, regardless of your location.
      </>
    ),
  },
  {
    id: "20",
    title: "EU-U.S. Privacy Shield Framework",
    text: (
      <>
        <p>
          While we acknowledge that the EU-US Privacy Shield Framework as set
          forth by the US Department of Commerce regarding the collection, use,
          and retention of personal information from European Union member
          countries may no longer be a valid transfer mechanism for data
          transfers to the European Economic Area, we nevertheless comply with
          the principles contained therein as additional measures to protect our
          users’ privacy. We have certified that Friday Report adheres to the
          Privacy Shield Principles of Notice, Choice, Accountability for Onward
          Transfer, Security, Data Integrity and Purpose Limitation, Access, and
          Recourse, Enforcement and Liability. If there is any conflict between
          the policies in this Privacy Policy and the Privacy Shield Principles,
          the more restrictive policy shall govern. To learn more about the
          Privacy Shield Program, and to view our certification page, please
          visit{" "}
          <CustomLink
            link="https://www.privacyshield.gov/"
            className={styles.link}
          >
            https://www.privacyshield.gov/
          </CustomLink>{" "}
          .
        </p>
        <p>
          In compliance with the EU-US Privacy Shield Principles, we commit to
          resolve complaints about your privacy and our collection or use of
          your personal information. European Union individuals with inquiries
          or complaints regarding this privacy policy should first contact us at{" "}
          <CustomLink
            link="mailto:support@friday-report.com"
            className={styles.link}
          >
            support@friday-report.com
          </CustomLink>
          .
        </p>
        <p>
          We have further committed to cooperate with EU Data Protection
          Authorities (DPAs) with regard to unresolved Privacy Shield
          complaints. If you do not receive timely acknowledgment of your
          complaint from us, or if we have not addressed your complaint to your
          satisfaction, please contact the EU DPAs for more information or to
          file a complaint. The services of EU DPAs are provided at no cost to
          you.
        </p>
        <p>
          As further explained in the Privacy Shield Principles, a binding
          arbitration option will also be made available to you in order to
          address residual complaints that are not resolved by other means set
          forth above.
        </p>
        <p>
          In cases of onward transfer to third parties of Personal Data received
          pursuant to the EU-U.S. Privacy Shield, Friday Report is potentially
          liable. Please see our DPA for more details.
        </p>
      </>
    ),
  },
  {
    id: "21",
    title: "California Privacy Rights",
    text: (
      <>
        <p>
          Friday Report complies with the California Consumer Privacy Act
          (referred to in this Privacy Policy as “CCPA”) as amended by the
          California Privacy Rights Act (“CPRA”) as set forth by the State of
          California. If the CCPA applies to your personal information this
          Privacy Policy provides you with information regarding how to receive
          information about our privacy practices, as well as request access to,
          and deletion of your personal information.
        </p>
        <p>
          Friday Report does not sell the personal information we collect. We
          only share your personal information as described in this Privacy
          Policy. For detailed information about the personal information we
          have collected over the last 12 months, and the categories of sources
          of such information, please see the “Information we collect
          automatically when you use the Services” and “Information we receive
          from other sources”. We collect this information for business purposes
          set forth in the “What do we do with the information we Collect?”. We
          share this information with the categories of third parties as set
          forth in the “When do we Disclose Information to Third Parties?”
          Please note that we do use third-party cookies as further set forth in
          the “Cookie Policy.”
        </p>
        <p>
          California consumers may make a request pursuant to their rights under
          the CCPA by contacting{" "}
          <CustomLink
            link="mailto:support@friday-report.com"
            className={styles.link}
          >
            support@friday-report.com
          </CustomLink>
          . Upon receiving a request, we will verify your request using the
          information associated with the Friday Report Services, including your
          email address. Government identification may be required. Consume r
          can also designate an authorized agent to exercise these rights on the
          consumer's behalf.
        </p>
      </>
    ),
  },
  {
    id: "22",
    title:
      "What Choices do I have regarding the Collection," +
      " Disclosure and Distribution of Personally Identifiable Information?",
    text: (
      <>
        Except as otherwise described in this Privacy Policy or in the Data
        Protection Addendum, we will only use personally identifiable
        information for the purposes described above, or as otherwise disclosed
        at the time we request such information from you. You must "opt in" and
        give us permission to use your personally identifiable information for
        any other purpose. You may also change your preference and "opt out" of
        receiving certain marketing communications from us by clicking the
        unsubscribe link included at the bottom of all such communications, by
        contacting us via email.
      </>
    ),
  },
  {
    id: "23",
    title: "Do Not Track",
    text: (
      <>
        The term "Do Not Track" refers to an HTTP header offered by certain web
        browsers to request that websites refrain from tracking the user. We
        take no action in response to Do Not Track requests.
      </>
    ),
  },
  {
    id: "24",
    title: "Can I update or correct my personally identifiable information?",
    text: (
      <>
        <p>
          The rights you have to request updates or corrections to the
          information Friday Report collects depend on your relationship with
          Friday Report.
        </p>
        <p>
          Customers have the right to request the restriction of certain uses
          and disclosures of personally identifiable information as follows. You
          can contact us in order to (1) update or correct your personally
          identifiable information, (2) change your preferences with respect to
          communications and other information you receive from us, or (3)
          delete the personally identifiable information maintained about you in
          our systems (subject to the following paragraph), up to and including
          by deleting your team. Such updates, corrections, changes and
          deletions will have no effect on other information that we maintain,
          or information that we have provided to third parties in accordance
          with this Privacy Policy prior to such update, correction, change or
          deletion. To protect your privacy and security, we may take reasonable
          steps (such as requesting a unique password) to verify your identity
          before granting you profile access or making corrections. You are
          responsible for maintaining the secrecy of your unique password and
          account information at all times.
        </p>
        <p>
          You should be aware that it is not technologically possible to remove
          each and every record of the information you have provided to us from
          our system. The need to back up our systems to protect information
          from inadvertent loss means that a copy of in a non-erasable form that
          will be difficult or impossible for us to locate. Promptly after
          receiving your request, all personal information stored in databases
          we actively use, and other readily searchable media will be updated,
          corrected, changed or deleted, as appropriate, as soon as and to the
          extent reasonably and technically practicable.
        </p>
        <p>
          If you are an end user in a Workspace not owned by you and wish to
          update, delete, or receive any information we have about you, you may
          do so by contacting the organization who owns your Friday Report
          Workspace.
        </p>
      </>
    ),
  },
  {
    id: "25",
    title: "How will I know if there are any changes to this Privacy Policy?",
    text: (
      <>
        We may revise this Privacy Policy from time to time. We will not make
        changes that result in significant additional uses or disclosures of
        your personally identifiable information without notifying you of such
        changes via email. We may also make non-significant changes to this
        Privacy Policy that generally will not significantly affect our use of
        your personally identifiable information, for which an email is not
        required. We encourage you to check this page periodically for any
        changes. If any non-significant changes to this Privacy Policy are
        unacceptable to you, you must immediately contact us and, stop using the
        Friday Report Service until the issue is resolved. Your continued use of
        the Friday Report Service following the posting of non-significant
        changes to this Privacy Policy constitutes your acceptance of those
        changes.
      </>
    ),
  },
  {
    id: "26",
    title: "Whom do I Contact if I have any Privacy Questions?",
    text: (
      <>
        <p>
          If you have any questions or comments about this Privacy Policy or
          feel that we are not abiding by the terms of this Privacy Policy,
          please contact our Privacy Agent in any of the following ways:
        </p>
        <div className={styles.wrapperText}>
          <p>
            – By email:{" "}
            <CustomLink
              link="mailto:support@friday-report.com"
              className={styles.link}
            >
              support@friday-report.com
            </CustomLink>
          </p>
          <p>By postal mail or courier:</p>
          <p>ATTN: Privacy Agent Friday Report</p>
          <p>8586 Potter Park Dr, 106, Sarasota, FL 34238</p>
        </div>
        <p>
          Subject to the next paragraph, we ask that you not send or disclose to
          us any sensitive personal data (e.g., social security numbers,
          information related to racial or ethnic origin, political opinions,
          religion or other beliefs, health, biometrics or genetic
          characteristics, criminal background or trade union membership) on or
          through the Friday Report Services or otherwise.
        </p>
        <p>
          If you send or disclose any sensitive personal data to us when you
          submit user-generated content to the Friday Report Services, you
          consent to our processing and use of such sensitive personal data in
          accordance with this policy. If you do not consent to our processing
          and use of such sensitive personal data, you must not submit such user
          generated content to our platform.
        </p>
        <strong>
          By using the Friday Report Service, you signify your acceptance of
          this Privacy Policy. If you do not agree to this Privacy Policy, you
          should not use the Friday Report Service. Continued use of the Friday
          Report Service following the posting of changes to this Privacy Policy
          that do not significantly affect the use or disclosure of your
          personally identifiable information will mean that you accept those
          changes.
        </strong>
      </>
    ),
  },
];
