import styles from "./Banner.module.scss";

import banner from "../../../../assets/img/404Banner.png";

export const Banner = () => {
  return (
    <div>
      <img src={banner} alt="" className={styles.root} loading="lazy" />
    </div>
  );
};
