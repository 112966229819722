import classNames from "classnames";
import { forwardRef, useEffect, useState } from "react";

import styles from "./UpdateReportModal.module.scss";

import { ReactComponent as CloseIcon } from "../../../../assets/icons/close-icon.svg";
import { useAppDispatch } from "../../../../store/hooks/hooks";
import { setFilesUpload } from "../../../../store/slices/uploadFileSlice";
import { CustomButton } from "../../../FormFields/CustomButton";
import { CanselUpdateModal } from "../CanselUpdateModal";
import { PreviewTemplateModal } from "../PreviewTemplateModal";
import { UpdateReport } from "../UpdateReport/UpdateReport";

interface IProps {
  id: string;
  titleReport: string;
  onClose: () => void;
  fileName: string;
}

export const UpdateReportModal = forwardRef<HTMLDivElement, IProps>(
  ({ id, titleReport, fileName, onClose }, ref) => {
    const [isShowPreview, setIsShowPreview] = useState("update");

    const dispatch = useAppDispatch();

    useEffect(() => {
      return () => {
        dispatch(setFilesUpload(null));
      };
    }, []);

    return (
      <div
        ref={ref}
        className={classNames(styles.root, {
          [styles.closeRoot]: isShowPreview === "cansel",
        })}
      >
        {isShowPreview !== "cansel" && (
          <div className={styles.closeWrapper}>
            <CustomButton theme="unstyled" onClick={onClose}>
              <CloseIcon className={styles.closeIcon} />
            </CustomButton>
          </div>
        )}

        {isShowPreview === "update" && (
          <UpdateReport
            id={id}
            fileName={fileName}
            showPreview={setIsShowPreview}
          />
        )}
        {isShowPreview === "preview" && (
          <PreviewTemplateModal
            onClose={onClose}
            showPreview={setIsShowPreview}
            titleReport={titleReport}
          />
        )}
        {isShowPreview === "cansel" && (
          <CanselUpdateModal onClose={onClose} showPreview={setIsShowPreview} />
        )}
      </div>
    );
  },
);
