import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Header } from "./components/Header";
import styles from "./MilestonesSummary.module.scss";

import {
  getCountByCompleted,
  getCountByType,
} from "../../../api/milestonesSummary/milestonesSummary";
import { useUserData } from "../../../hooks/useUserProfile";
import { useAppSelector } from "../../../store/hooks/hooks";
import {
  selectCompleted,
  selectFinishFrom,
  selectMilestoneType,
  selectFinishTo,
  selectProjects,
} from "../../../store/slices/reportsSlice/milestonesSlice";
import { selectCurrentId } from "../../../store/slices/stepSlice";
import { DoughnutChart, VerticalItem } from "../../../types/previewTemplate";
import { createErrorMessage } from "../../../utils/createErrorMessage";
import { Loader } from "../../Loader";
import { PieChart } from "../PieChart";
import { VerticalBarChart } from "../VerticalBarChart";

export const MilestonesSummary = () => {
  const [verticalBar, setVerticalBar] = useState<VerticalItem>();
  const [pieChart, setPieChart] = useState<DoughnutChart>();
  const [isLoading, setIsLoading] = useState(false);

  const currentId = useAppSelector(selectCurrentId);
  const projects = useAppSelector(selectProjects);
  const milestoneType = useAppSelector(selectMilestoneType);
  const completed = useAppSelector(selectCompleted);
  const periodFrom = useAppSelector(selectFinishFrom);
  const periodTo = useAppSelector(selectFinishTo);

  const { organizationId, tenantId } = useUserData();
  const { reportId } = useParams();

  const maxDataValue = verticalBar?.data
    ? Math.max(...verticalBar.data.map((item) => item.value))
    : 0;
  const padding = maxDataValue * 0.1;

  const { isLoading: load } = useQuery({
    queryKey: [
      "getMilestonesChartData",
      projects,
      milestoneType,
      completed,
      periodFrom,
      periodTo,
    ],
    queryFn:
      tenantId &&
      reportId &&
      (async () => {
        try {
          const verticalBarByType = await getCountByType(
            currentId ? currentId : reportId,
            organizationId,
            tenantId,
            projects,
            milestoneType,
            completed,
            periodFrom,
            periodTo
          );
          const pieChartByCompleted = await getCountByCompleted(
            currentId ? currentId : reportId,
            organizationId,
            tenantId,
            projects,
            milestoneType,
            completed,
            periodFrom,
            periodTo
          );
          setVerticalBar(verticalBarByType.data.data);
          setPieChart(pieChartByCompleted.data.data);
          setIsLoading(false);
        } catch (error) {
          toast.error(createErrorMessage(error));
        }
      }),
    enabled:
      !!tenantId &&
      !!reportId &&
      (!!currentId || !!reportId) &&
      !!projects &&
      !!milestoneType &&
      !!completed &&
      !!periodFrom &&
      !!periodTo,
  });

  useEffect(() => {
    setIsLoading(true);
  }, [projects, milestoneType, completed, periodFrom, periodTo]);

  return (
    <div className={styles.root}>
      <Header />
      {isLoading && <Loader />}
      {!isLoading && (
        <div className={styles.wrapper}>
          <PieChart
            dataList={pieChart?.data}
            title={pieChart?.title}
            legendTitle={pieChart?.legendTitle}
          />
          <VerticalBarChart
            dataList={verticalBar?.data}
            options={{
              responsive: true,
              plugins: {
                datalabels: {
                  color: "black",
                  labels: {
                    title: {
                      font: {
                        weight: 400,
                        size: 12,
                      },
                    },
                  },
                  anchor: "end",
                  align: -90,
                },
                legend: {
                  display: false,
                },
              },
              scales: {
                y: {
                  max: maxDataValue + padding,
                  ticks: {
                    stepSize: 2,
                  },
                  title: {
                    display: true,
                    text: verticalBar?.y,
                    color: "#9E9E9E",
                    font: {
                      size: 14,
                      weight: 400,
                    },
                    padding: {
                      bottom: 15,
                    },
                  },
                },

                x: {
                  title: {
                    display: true,
                    text: verticalBar?.x,
                    color: "#9E9E9E",
                    font: {
                      size: 14,
                      weight: 400,
                    },
                    padding: {
                      top: 20,
                    },
                  },
                },
              },
              clip: false,
            }}
            title={verticalBar?.title}
          />
        </div>
      )}
    </div>
  );
};
