import classNames from "classnames";
import { FC } from "react";

import styles from "./LoadTemplate.module.scss";

import { ReactComponent as DeleteIcon } from "../../../../assets/icons/close-icon.svg";
import { ReactComponent as LoadFile } from "../../../../assets/icons/loadFile-icon.svg";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks/hooks";
import {
  setFilesUpload,
  selectDemo,
} from "../../../../store/slices/uploadFileSlice";
import { CustomButton } from "../../../FormFields/CustomButton";
import { Loader } from "../../../Loader";

interface IProps {
  titleTemplate: string;
  className?: string;
  isLoading?: boolean;
}

export const LoadTemplate: FC<IProps> = ({
  titleTemplate,
  className,
  isLoading,
}) => {
  const dispatch = useAppDispatch();
  const demoTab = useAppSelector(selectDemo);

  const handleRemoveFile = () => {
    dispatch(setFilesUpload(null));
  };

  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.wrapper}>
        {isLoading ? <Loader className={styles.loader} /> : <LoadFile />}
        {titleTemplate}
      </div>
      {demoTab !== "demo" && (
        <CustomButton theme={"unstyled"} onClick={handleRemoveFile}>
          <DeleteIcon className={styles.deleteIcon} />
        </CustomButton>
      )}
    </div>
  );
};
