import styles from "./Page404.module.scss";

import { Banner } from "../../components/404/components/Banner";
import { Content } from "../../components/404/components/Content";

export const Page404 = () => {
  return (
    <div className={styles.root}>
      <Content />
      <Banner />
    </div>
  );
};
