export const formatDate = (value: string) => {
  const date = new Date(value);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  return `${month}/${day}/${year}`;
};

export const formatTimeAsUtc = (value: string) => {
  const date = new Date(value);
  const utcOptions: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: "UTC",
    hour12: true,
  };
  return date.toLocaleTimeString("en-US", utcOptions);
};
