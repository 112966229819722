import { combineReducers } from "@reduxjs/toolkit";

import { authSlice } from "./slices/authSlice";
import { historySlice } from "./slices/historySlice";
import { importConfigurationSlice } from "./slices/importConfigurationSlice";
import { parseResultSlice } from "./slices/parseResultSlice";
import { previewSlice } from "./slices/previewSlice";
import { issuesSlice } from "./slices/reportsSlice/IssuesSlice";
import { milestonesSlice } from "./slices/reportsSlice/milestonesSlice";
import { stepSlice } from "./slices/stepSlice";
import { uploadFileSlice } from "./slices/uploadFileSlice";

const rootReducer = combineReducers({
  [authSlice.name]: authSlice.reducer,
  [stepSlice.name]: stepSlice.reducer,
  [issuesSlice.name]: issuesSlice.reducer,
  [milestonesSlice.name]: milestonesSlice.reducer,
  [uploadFileSlice.name]: uploadFileSlice.reducer,
  [parseResultSlice.name]: parseResultSlice.reducer,
  [previewSlice.name]: previewSlice.reducer,
  [historySlice.name]: historySlice.reducer,
  [importConfigurationSlice.name]: importConfigurationSlice.reducer,
});

export type TRootReducer = ReturnType<typeof rootReducer>;

export default rootReducer;
