import { useFormik } from "formik";
import { FC } from "react";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import * as yup from "yup";

import styles from "./LoginForm.module.scss";

import { loginUser } from "../../../../api";
import loginImg from "../../../../assets/img/login.png";
import { AUTH_FORMS } from "../../../../constants/AUTH_FORMS";
import { MESSAGES } from "../../../../constants/MESSAGES";
import { useUserData } from "../../../../hooks/useUserProfile";
import { useAppDispatch } from "../../../../store/hooks/hooks";
import { setAuthStep } from "../../../../store/slices/authSlice";
import { EAuthSteps } from "../../../../types/formTypes";
import { createErrorMessage } from "../../../../utils/createErrorMessage";
import { decodeToken } from "../../../../utils/decodeToken";
import { CustomButton } from "../../../FormFields/CustomButton";
import { CustomInput } from "../../../FormFields/CustomInput";
import { FormVerticalWrapper } from "../../../FormFields/FormVerticalWrapper";
import { FormImg } from "../FormImg";
import { FormSubmitWrapper } from "../FormSubmitWrapper";
import { FormTitle } from "../FormTitle";

interface IProps {
  onClose: () => void;
}

export const LoginForm: FC<IProps> = ({ onClose }) => {
  const [, setToken] = useCookies<string>(["accessToken", "refreshToken"]);

  const { refetch } = useUserData();

  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .matches(
          /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
          MESSAGES.EMAIL
        )
        .required(MESSAGES.REQUIRED),
      password: yup
        .string()
        .required(MESSAGES.REQUIRED)
        .min(8, MESSAGES.PASSWORDMIN)
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/,
          MESSAGES.REQCHAR
        )
        .notOneOf(["password", "123456", "qwerty"], MESSAGES.NOTPATTERN),
    }),
    onSubmit: async (data) => {
      try {
        const resp = await loginUser(data);

        const accessToken = decodeToken(resp.data.data.accessToken);
        const refreshToken = decodeToken(resp.data.data.refreshToken);

        setToken("accessToken", accessToken.token, {
          expires: accessToken.expDate,
          path: "/",
        });
        setToken("refreshToken", refreshToken.token, {
          expires: refreshToken.expDate,
          path: "/",
        });

        refetch();

        onClose();
      } catch (error: any) {
        toast.error(createErrorMessage(error));
      }
    },
  });

  const onAdditionalBtnClick = () => {
    dispatch(setAuthStep(EAuthSteps["Sign up"]));
  };

  const onForgotPasswordBtnClick = () => {
    dispatch(setAuthStep(EAuthSteps["Reset password"]));
  };

  return (
    <form className={styles.root} onSubmit={formik.handleSubmit}>
      <FormVerticalWrapper>
        <FormVerticalWrapper gap={32} align="center">
          <FormImg src={loginImg} />
          <FormTitle text={AUTH_FORMS.NAMES.LOGIN} />
          <FormVerticalWrapper gap={24}>
            <CustomInput
              type="email"
              label="Email"
              id="email"
              name="email"
              placeholder="Enter your Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.errors.email}
            />
            <div className={styles.passwordWrapper}>
              <CustomInput
                type="password"
                label="Password"
                id="password"
                name="password"
                placeholder="Enter your password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.errors.password}
                autoComplete="current-password"
              />
              <CustomButton
                theme="text"
                className={styles.forgotPassBtn}
                onClick={onForgotPasswordBtnClick}
              >
                Forgot password?
              </CustomButton>
            </div>
          </FormVerticalWrapper>
        </FormVerticalWrapper>
        <FormSubmitWrapper
          btnText={AUTH_FORMS.NAMES.LOGIN}
          additionalText={AUTH_FORMS.ADDITIONAL_TEXT.LOGIN}
          additionalBtn={AUTH_FORMS.NAMES.SIGNUP}
          isDisbledSubmit={!formik.dirty || !formik.isValid}
          onAdditionalBtnClick={onAdditionalBtnClick}
        />
      </FormVerticalWrapper>
    </form>
  );
};
