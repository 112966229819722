import classNames from "classnames";
import { FC } from "react";
import { FileUploader } from "react-drag-drop-files";

import styles from "./CustomUpload.module.scss";

import { ReactComponent as CloseIcon } from "../../../assets/icons/close-icon.svg";
import { ReactComponent as ErrorFile } from "../../../assets/icons/errorFile-icon.svg";
import { ReactComponent as Load } from "../../../assets/icons/load-icon.svg";
import { ReactComponent as LoadFile } from "../../../assets/icons/loadFile-icon.svg";
import { useUserData } from "../../../hooks/useUserProfile";
import { useAppDispatch, useAppSelector } from "../../../store/hooks/hooks";
import {
  fetchParseResult,
  selectParseResultIsError,
  selectParseResultIsLoading,
} from "../../../store/slices/parseResultSlice";
import {
  setFilesUpload,
  selectFileUpload,
} from "../../../store/slices/uploadFileSlice";
import { Loader } from "../../Loader";
import { CustomButton } from "../CustomButton";

interface IProps {
  className?: string;
  loadTemplate?: string;
}

export const CustomUpload: FC<IProps> = ({ className, loadTemplate }) => {
  const isLoading = useAppSelector(selectParseResultIsLoading);
  const isError = useAppSelector(selectParseResultIsError);

  const { organizationId, tenantId } = useUserData();

  const dispatch = useAppDispatch();
  const files = useAppSelector(selectFileUpload);

  const handleChange = async (file: File) => {
    dispatch(setFilesUpload(file));

    dispatch(
      fetchParseResult({
        file: file,
        fileName: file?.name,
        tenantId: tenantId,
        organizationId: organizationId,
      })
    );
  };

  const handleRemoveFile = () => {
    dispatch(setFilesUpload(null));
  };

  const loadFile = () => {
    if (isLoading) {
      return <Loader className={styles.loader} />;
    }

    if (isError) {
      return <ErrorFile />;
    }

    return <LoadFile />;
  };

  return (
    <div className={classNames(styles.root, className)}>
      {!files && (
        <>
          <FileUploader
            types={["xlsx"]}
            maxSize={50}
            handleChange={handleChange}
          >
            <div className={styles.uploadField}>
              <div className={styles.dropWrapper}>
                <Load className={styles.loadIcon} />
                <p className={styles.uploadFieldText}>Drag and drop the file</p>
              </div>
              <div className={styles.lineWrapper}>
                <span className={styles.line} />
                <p className={styles.textOr}>or</p>
                <span className={styles.line} />
              </div>
              <CustomButton onClick={() => null} className={styles.btn}>
                Browse for file
              </CustomButton>
            </div>
          </FileUploader>
          <p className={styles.text}>
            The file must be in the .xlsx format and not exceed 50 MB in size.
          </p>
        </>
      )}
      {files && (
        <div className={classNames(styles.fileViewWrapper, loadTemplate)}>
          <div className={styles.wrapper}>
            {loadFile()}
            {files?.name}
          </div>
          <CustomButton onClick={handleRemoveFile} theme="unstyled">
            <CloseIcon className={styles.deleteIcon} />
          </CustomButton>
        </div>
      )}
    </div>
  );
};
