import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Header } from "./components/Header";
import styles from "./IssuesSummary.module.scss";

import {
  getIssuesBarChartData,
  getIssuesDoughnutChartData,
} from "../../../api/issuesSummary/issuesSummary";
import { useUserData } from "../../../hooks/useUserProfile";
import { useAppSelector } from "../../../store/hooks/hooks";
import {
  selectOwners,
  selectPeriodFrom,
  selectPeriodTo,
} from "../../../store/slices/reportsSlice/IssuesSlice";
import { selectCurrentId } from "../../../store/slices/stepSlice";
import { IssuesBarChart, DoughnutChart } from "../../../types/previewTemplate";
import { createErrorMessage } from "../../../utils/createErrorMessage";
import { DoughnutChartIssues } from "../../DashBoards/DoughnutChartIssues";
import { Loader } from "../../Loader";
import { BarChart } from "../BarChart";

export const IssuesSummary = () => {
  const [doughnutChartData, setDoughnutChartData] = useState<DoughnutChart>();
  const [barChart, setBarChart] = useState<IssuesBarChart>();
  const [isLoading, setIsLoading] = useState(false);

  const currentId = useAppSelector(selectCurrentId);
  const owners = useAppSelector(selectOwners);
  const periodFrom = useAppSelector(selectPeriodFrom);
  const periodTo = useAppSelector(selectPeriodTo);

  const { organizationId, tenantId } = useUserData();
  const { reportId } = useParams();

  const { isLoading: load } = useQuery({
    queryKey: ["getIssuesDoughnutChartData", owners, periodFrom, periodTo],
    queryFn:
      tenantId &&
      reportId &&
      (async () => {
        try {
          const chartData = await getIssuesDoughnutChartData(
            currentId ? currentId : reportId,
            organizationId,
            tenantId,
            owners,
            periodFrom,
            periodTo,
          );
          const barChartData = await getIssuesBarChartData(
            currentId ? currentId : reportId,
            organizationId,
            tenantId,
            owners,
            periodFrom,
            periodTo,
          );
          setBarChart(barChartData.data.data);
          setDoughnutChartData(chartData.data.data);
          setIsLoading(false);
        } catch (error) {
          toast.error(createErrorMessage(error));
        }
      }),
    enabled:
      !!tenantId &&
      !!reportId &&
      (!!currentId || !!reportId) &&
      !!owners &&
      !!periodFrom &&
      !!periodTo,
  });

  useEffect(() => {
    setIsLoading(true);
  }, [owners, periodFrom, periodTo]);

  return (
    <div className={styles.root}>
      <Header />
      {isLoading && <Loader />}
      {!isLoading && (
        <div className={styles.wrapper}>
          <DoughnutChartIssues
            dataList={doughnutChartData?.data}
            title={doughnutChartData?.title}
            legendTitle={doughnutChartData?.legendTitle}
          />
          <BarChart
            impact={barChart?.impact || []}
            likelihoodList={barChart?.likelihood || []}
            title={barChart?.title}
            legendTitle={barChart?.legendTitle}
            y={barChart?.y || []}
          />
        </div>
      )}
    </div>
  );
};
