import styles from "./Subscription.module.scss";

import { TARIFFS } from "../../../../constants/TARIFFS";
import { SubscriptionCard } from "../SubscriptionCard";

export const Subscription = () => {
  return (
    <div className={styles.root}>
      {TARIFFS.map((item) => (
        <SubscriptionCard
          id={item.id}
          title={item.title}
          subtitle={item.subtitle}
          benefits={item.benefits}
          included={item.included}
          processing={item.processing}
          price={item.price}
          period={item.period}
          isActive={item.isActive}
        />
      ))}
    </div>
  );
};
