import classNames from "classnames";
import { FC } from "react";
import { Link } from "react-router-dom";

import { Copyright } from "./components/Copyright/Copyright";
import { MenuNavigation } from "./components/MenuNavigation/MenuNavigation";
import { sideMenuNavigation } from "./components/MenuNavigation/sideMenuNavigation";
import styles from "./SideMenu.module.scss";

import { ReactComponent as Arrow } from "../../../../assets/icons/arrow-icon.svg";
import { ReactComponent as Logo } from "../../../../assets/icons/logo-icon.svg";
import { ReactComponent as LogoText } from "../../../../assets/icons/logoText-icon.svg";
import { PATHS } from "../../../../constants/PATHS";
import { Description } from "../../../Description";

interface IProps {
  isOpen: boolean;
  onClick: () => void;
}

export const SideMenu: FC<IProps> = ({ isOpen, onClick }) => {
  return (
    <nav
      className={classNames(styles.root, {
        [styles.rootCloseMenu]: !isOpen,
      })}
    >
      <div className={styles.wrapper}>
        <Link
          to={PATHS.BASE_PATH}
          className={classNames(styles.logoWrapper, {
            [styles.logoWrapperClose]: !isOpen,
          })}
        >
          <Logo className={styles.logoIcon} />
          {isOpen && <LogoText className={styles.logoTextIcon} />}
        </Link>
        <Arrow
          className={classNames(styles.arrow, {
            [styles.arrowClose]: !isOpen,
          })}
          onClick={onClick}
        />
      </div>
      <MenuNavigation isOpenMenu={isOpen} menuList={sideMenuNavigation} />
      {isOpen && (
        <div className={styles.copyright}>
          <Copyright />
          <Description theme={"t7"}>
            8586 Potter Park Drive, Suite 106, Sarasota, FL 34238
          </Description>
        </div>
      )}
    </nav>
  );
};
