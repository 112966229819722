import classNames from "classnames";
import { FC } from "react";

import styles from "./SubscriptionCard.module.scss";

import { CustomButton } from "../../../FormFields/CustomButton";
import { Title } from "../../../Title";

interface IProps {
  id: string;
  title: string;
  subtitle?: string;
  benefits: string[];
  included: string;
  processing: string;
  price: string;
  period: string;
  isActive: boolean;
}

export const SubscriptionCard: FC<IProps> = ({
  id,
  title,
  subtitle,
  benefits,
  included,
  processing,
  price,
  period,
  isActive,
}) => {
  return (
    <div className={classNames(styles.root, { [styles.disabled]: !isActive })}>
      <div className={styles.header}>{isActive && "Current plan"}</div>
      <div className={styles.contentWrapper}>
        <div className={styles.titleWrapper}>
          <Title tagName="h3" className={styles.title}>
            {title}
          </Title>
          {subtitle && (
            <Title tagName="h3" className={styles.title}>
              ({subtitle})
            </Title>
          )}
        </div>
        <ul className={styles.list}>
          {benefits.map((item, i) => (
            <li key={`${id}_${i}`} className={styles.listItem}>
              {item}
            </li>
          ))}
        </ul>
        <div className={styles.wrapper}>
          <Title tagName="h4">{included}</Title>
          <Title tagName="h4">{processing}</Title>
        </div>
        <Title tagName="h2">
          <>
            ${price}
            <span className={styles.period}>/{period}</span>
          </>
        </Title>
        {!isActive && (
          <CustomButton alignContent="center" isDisabled>
            Coming Soon
          </CustomButton>
        )}
      </div>
    </div>
  );
};
