import classNames from "classnames";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { Outlet } from "react-router";

import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { SideMenu } from "./components/SideMenu";
import styles from "./Layout.module.scss";

import { useAuthToken } from "../../hooks/useAuthToken";
import { useUserData } from "../../hooks/useUserProfile";

export const Layout = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(true);

  const [token] = useCookies(["accessToken"]);

  const onClickMenu = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  useAuthToken();
  useUserData();

  return (
    <div
      className={classNames(styles.root, {
        [styles.rootSideMenu]: token.accessToken,
      })}
    >
      {token.accessToken && (
        <SideMenu isOpen={isOpenMenu} onClick={onClickMenu} />
      )}
      <div
        className={classNames(styles.wrapperContent, {
          [styles.wrapperContentCalcWidthMenuOpen]:
            token.accessToken && isOpenMenu,
          [styles.wrapperContentCalcWidthMenuClose]:
            token.accessToken && !isOpenMenu,
        })}
      >
        <Header />
        <main className={styles.main}>
          <Outlet />
        </main>
        <Footer />
      </div>
    </div>
  );
};
