import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../index";

interface IInitialState {
  project: string[];
  milestoneType: string[];
  completed: string;
  periodFrom: string;
  periodTo: string;
}

const initialState: IInitialState = {
  project: [],
  milestoneType: [],
  completed: "",
  periodFrom: "",
  periodTo: "",
};

export const milestonesSlice = createSlice({
  name: "milestones",
  initialState,
  reducers: {
    setProjects(state, action: PayloadAction<string[]>) {
      state.project = action.payload;
    },
    setMilestoneType(state, action: PayloadAction<string[]>) {
      state.milestoneType = action.payload;
    },
    setCompleted(state, action: PayloadAction<string>) {
      state.completed = action.payload;
    },
    setPeriodFrom(state, action: PayloadAction<string>) {
      state.periodFrom = action.payload;
    },
    setPeriodTo(state, action: PayloadAction<string>) {
      state.periodTo = action.payload;
    },
  },
});
export const selectProjects = (state: RootState) => state.milestones.project;
export const selectMilestoneType = (state: RootState) =>
  state.milestones.milestoneType;
export const selectCompleted = (state: RootState) => state.milestones.completed;
export const selectFinishFrom = (state: RootState) =>
  state.milestones.periodFrom;
export const selectFinishTo = (state: RootState) => state.milestones.periodTo;

export const {
  setProjects,
  setMilestoneType,
  setCompleted,
  setPeriodFrom,
  setPeriodTo,
} = milestonesSlice.actions;
