import { useNavigate } from "react-router-dom";

import styles from "./Content.module.scss";

import { CustomLink } from "../../../CustomLink";
import { Description } from "../../../Description";
import { CustomButton } from "../../../FormFields/CustomButton";
import { Title } from "../../../Title";

export const Content = () => {
  const navigate = useNavigate();

  const onBntClick = () => {
    navigate(-1);
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.headerWrapper}>
          <Title tagName="h1" className={styles.title}>
            Error 404!
          </Title>
          <Title tagName="h3">Page not found.</Title>
        </div>
        <div className={styles.mainWrapper}>
          <Description theme="t1">
            Refresh the page or select one of the available menu items to
            explore the functionality of our platform.
          </Description>
          <div className={styles.emailWrapper}>
            <Description theme="t7">
              Have questions or need assistance? Contact our support team for
              additional information:
            </Description>
            <CustomLink link="mailto:support@friday-report.com">
              support@friday-report.com
            </CustomLink>
          </div>
        </div>
      </div>
      <CustomButton type="button" className={styles.btn} onClick={onBntClick}>
        Go Back
      </CustomButton>
    </div>
  );
};
