import { FC, ReactElement, useRef, useState } from "react";

import styles from "./ComingSoonItem.module.scss";

import { Description } from "../../../Description";
import { ReactPortal } from "../../../ReactPortal";

interface IProps {
  icon: ReactElement;
  title: string;
}

export const ComingSoonItem: FC<IProps> = ({ icon, title }) => {
  const [isHovered, setIsHovered] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const onMouseEnter = () => {
    setIsHovered(true);
  };

  const onMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className={styles.root}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={containerRef}
    >
      {icon}
      {isHovered && (
        <ReactPortal container={containerRef}>
          <Description theme={"t6"} className={styles.hint}>
            {title}
          </Description>
        </ReactPortal>
      )}
    </div>
  );
};
