import styles from "./ReportsPage.module.scss";

import issuesSummaryImg from "../../assets/img/issuesSummaryImg.png";
import milestonesSummaryImg from "../../assets/img/milestonesSummaryImg.png";
import projectSummaryImg from "../../assets/img/projectSummaryImg.png";
import { Loader } from "../../components/Loader";
import { ReportsItem } from "../../components/Reports/components/ReportsItem";
import { useCurrentInfoFiles } from "../../hooks/useCurrentInfoFiles";

export const ReportsPage = () => {
  const { data, isLoading } = useCurrentInfoFiles();

  const reportImages = {
    ProjectSummary: projectSummaryImg,
    IssueSummary: issuesSummaryImg,
    MilestoneSummary: milestonesSummaryImg,
  };

  return (
    <div className={styles.root}>
      {isLoading && (
        <div className={styles.loaderWrapper}>
          <Loader />
        </div>
      )}
      {!isLoading &&
        data &&
        data.map((report) => (
          <ReportsItem
            key={report.id}
            id={report.id}
            fileName={report.fileName}
            title={report.templateDisplayName}
            type={report.templateName}
            report={
              reportImages[report.templateName as keyof typeof reportImages]
            }
            isDisable={!report.fileName}
          />
        ))}
    </div>
  );
};
