import styles from "./MainPageContent.module.scss";

import { MainBanner } from "../../components/MainPageContent/conponents/MainBanner";
import { MainContent } from "../../components/MainPageContent/conponents/MainContent";

export const MainPage = () => {
  return (
    <div className={styles.root}>
      <MainContent />
      <MainBanner />
    </div>
  );
};
