import { useQuery } from "@tanstack/react-query";
import { useCookies } from "react-cookie";

import { getUserData } from "../api/user/user";

export const useUserData = () => {
  const [token] = useCookies(["accessToken"]);

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ["user"],
    queryFn: getUserData,
    enabled: !!token.accessToken,
  });

  return {
    user: data,
    organizationId: data?.data.data.organizationId,
    tenantId: data?.data.data.tenantId,
    isLoading,
    error,
    refetch,
  };
};
