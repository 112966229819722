import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../index";

interface UploadFileState {
  demo: string | null;
  fileDemo: File | string | null;
  fileUpload: File | null;
}

const initialState: UploadFileState = {
  demo: null,
  fileDemo: null,
  fileUpload: null,
};

export const uploadFileSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    setDemo(state, action: PayloadAction<string | null>) {
      state.demo = action.payload;
    },
    setFilesDemo(state, action: PayloadAction<File | string | null>) {
      state.fileDemo = action.payload;
    },
    setFilesUpload(state, action: PayloadAction<File | null>) {
      state.fileUpload = action.payload;
    },
  },
});

export const selectDemo = (state: RootState) => state.upload.demo;
export const selectFileDemo = (state: RootState) => state.upload.fileDemo;
export const selectFileUpload = (state: RootState) => state.upload.fileUpload;
export const { setDemo, setFilesDemo, setFilesUpload } =
  uploadFileSlice.actions;
