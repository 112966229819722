import { AxiosError } from "axios";

import { IResponsError } from "../types/errors";

export const createErrorMessage = (error: unknown) => {
  const message = ((error as AxiosError).response?.data as IResponsError)
    .ErrorMessage;

  return message;
};
