import { FC } from "react";

import styles from "./FormTitle.module.scss";

import { Title } from "../../../Title";

interface IProps {
  text: string;
  description?: string;
  aligment?: "center";
}

export const FormTitle: FC<IProps> = ({ text, description, aligment }) => {
  return (
    <div className={styles.root}>
      <Title tagName="h2" className={styles.title}>
        {text}
      </Title>
      {description && <p style={{ textAlign: aligment }}>{description}</p>}
    </div>
  );
};
