import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { getCookie } from "../utils/cookie/getCookie";

export const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

export const request = (params: AxiosRequestConfig): Promise<AxiosResponse> => {
  return axiosInstance(params);
};

axiosInstance.interceptors.request.use((config) => {
  const accessToken = getCookie("accessToken");

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
