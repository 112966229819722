import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { Form, Popconfirm, Button, Input, Space, Table, Select, Dropdown } from 'antd';
import type { GetRef, InputRef, MenuProps, TableColumnType, TableProps } from 'antd';
import { AnyObject } from 'antd/es/_util/type';
import { ItemType } from 'antd/es/menu/interface';
import type { ColumnGroupType, ColumnType, FilterDropdownProps, GetRowKey } from 'antd/es/table/interface';
import type { BaseSelectRef } from 'rc-select';
import React, { SetStateAction, useContext, useEffect, useRef, useState } from "react";
import Highlighter from 'react-highlight-words';

export type SelectOption = {
  label: string;
  value: string | number
}

export type CustomColumnType<TRecord = any> = (ColumnGroupType<TRecord> | ColumnType<TRecord>) & {
  inputType?: 'text' | 'dropdown';
  editable?: boolean;
  options?: SelectOption[];
};

export type CustomColumnsType<TRecord = AnyObject> = CustomColumnType<TRecord>[];

export interface CustomTableProps<TRecord = AnyObject> extends TableProps<TRecord> {
  createItemFactory?: (createParameter?: string) => TRecord | Promise<TRecord>;
  columns?: CustomColumnsType<TRecord>;
  getRowKey: GetRowKey<TRecord>;
  dataSource: TRecord[];
  onDataSourceChange?: (newDatSource: TRecord[]) => void;
  allowAddDelete: boolean;
  addButtonItems?: ItemType[];
}

export const CustomTable = <TRecord = AnyObject,>({
  dataSource,
  columns,
  rowSelection,
  getRowKey,
  pagination = { pageSize: 10 },
  loading,
  createItemFactory,
  onDataSourceChange,
  allowAddDelete,
  addButtonItems
}: CustomTableProps<TRecord>) => {

  type DataIndex = keyof TRecord;

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex as SetStateAction<string>);
  };

  const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<TRecord> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex.toString()}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          {/* <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button> */}
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex as SetStateAction<string>);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      `${record[dataIndex]}`
        // record[dataIndex]
        // .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  type FormInstance<T> = GetRef<typeof Form<T>>;
  const EditableContext = React.createContext<FormInstance<any> | null>(null);

  interface EditableRowProps {
    index: number;
  }

  const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  interface EditableCellProps {
    title: React.ReactNode;
    editable?: boolean;
    dataIndex: keyof TRecord;
    record: TRecord;
    placeholder: string;
    handleSave: (record: TRecord) => void;
    inputType?: 'text' | 'dropdown';
    options?: SelectOption[];
  }

  const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    placeholder,
    handleSave,
    inputType,
    options,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    // const [value, setValue] = useState(record[dataIndex]);
    // const inputRef = useRef<InputRef>(null);
    const inputRef = useRef<InputRef>(null);
    const selectRef = useRef<BaseSelectRef>(null);
    const form = useContext(EditableContext)!;

    // const handleChange = (newValue: any) => {
    //   setValue(newValue);
    //   // (value) => {
    //   //   record[dataIndex as keyof typeof record] = value as any;
    //   //   save();
    //   // }
    // };

    useEffect(() => {
      if (editing) {
        inputRef.current?.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();

        toggleEdit();
        handleSave({ ...record, ...values });
      } catch (errInfo) {
        console.log('Save failed:', errInfo);
      }
    };

    let childNode = children;

    if (editable) {
      // if (editing) {
      let inputNode;
      switch (inputType) {
        case 'dropdown':
          inputNode = (
            <Select
              ref={selectRef}
              onBlur={save}
              placeholder={placeholder}
              // defaultValue={record[dataIndex] as string}
              onChange={(value) => {
                record[dataIndex as keyof typeof record] = value as any;
                // save();
              }}
              style={{ width: '100%' }}
            >
              {options?.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          );
          break;

        case 'text':
        default:
          inputNode = (
            <Input
              ref={inputRef}
              onPressEnter={save}
              onBlur={save}
              placeholder={placeholder}
              // defaultValue={record[dataIndex] as string}
              onChange={(e) =>
                (record[dataIndex as keyof typeof record] = e.target.value as any)
              }
            />
          );
          break;
      }

      childNode = (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex.toString()}
          rules={[{ required: true, message: `${title} is required.` }]}
          initialValue={record[dataIndex] as string}
        >
          {inputNode}
        </Form.Item>
      );
      // } else {
      //   childNode = (
      //     <div
      //       className="editable-cell-value-wrap"
      //       style={{ paddingInlineEnd: 24, height: 24 }}
      //       onClick={toggleEdit}
      //     >
      //       {children}
      //     </div>
      //   );
      // }
    }

    return <td {...restProps}>{childNode}</td>;
  };

  const [customDataSource, setCustomDataSource] = useState<TRecord[]>([]);

  useEffect(() => {
    setCustomDataSource([...dataSource]);
  }, [dataSource]);

  const handleSave = (row: TRecord) => {
    const newDataSource = [...customDataSource];
    const index = newDataSource.findIndex((item) => getRowKey(row) === getRowKey(item));
    const item = newDataSource[index];
    newDataSource.splice(index, 1, {
      ...item,
      ...row,
    });
    setCustomDataSource(newDataSource);
    onDataSourceChange?.(newDataSource);
  };

  const handleDelete = (key: React.Key) => {
    const newDataSource = customDataSource.filter((item) => getRowKey(item) !== key);
    setCustomDataSource(newDataSource);
    onDataSourceChange?.(newDataSource);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const extendedColumns = columns?.map(column => {
    let resultColumn = column;
    const columnType = column as ColumnType<TRecord>;
    if (column.filterSearch) {
      if (columnType && columnType.dataIndex) {
        const dataIndex = columnType.dataIndex as keyof TRecord;
        resultColumn = { ...resultColumn, ...getColumnSearchProps(dataIndex) };
      }
    }

    if (column.editable) {
      resultColumn = {
        ...resultColumn,
        onCell: (record: TRecord) => ({
          record,
          editable: resultColumn.editable,
          options: resultColumn.options,
          inputType: resultColumn.inputType,
          dataIndex: columnType.dataIndex,
          title: columnType.title,
          handleSave,
          placeholder: `Enter ${columnType.title}`
        }) as React.HTMLAttributes<HTMLElement>,
      };
    }

    return resultColumn;
  })

  if (allowAddDelete) {
    extendedColumns?.push({
      title: 'operation',
      dataIndex: 'operation',
      render: (_, record) =>
        <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(getRowKey(record))}>
          <a>Delete</a>
        </Popconfirm>
    })
  }

  const createNewItem = async (key?: string) => {
    let newDataRecord;
    if (createItemFactory) {
      newDataRecord = await createItemFactory(key);
    }
    else {
      newDataRecord = {} as TRecord;
    }
    const newDataSource = [...customDataSource, newDataRecord];
    setCustomDataSource(newDataSource);
    onDataSourceChange?.(newDataSource);
  }

  const handleAdd = async () => {
    await createNewItem();
  };

  const menuProps: MenuProps = {
    items: addButtonItems,
    onClick: async (e) => {
      await createNewItem(e.key);
    }
  };

  let buttonComponent;
  if (allowAddDelete) {
    if (addButtonItems && addButtonItems.length > 0) {
      buttonComponent = (
        <Dropdown menu={menuProps}>
          <Button>
            <Space>
              Add
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      );
    }
    else {
      buttonComponent = (
        <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
          Add
        </Button>
      );
    }
  }

  return (
    <div>
      {buttonComponent}
      <Table<TRecord>
        dataSource={customDataSource}
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        columns={extendedColumns}
        rowKey={getRowKey}
        loading={loading}
        rowSelection={rowSelection}
        pagination={pagination}
      // onRow={(record) => ({
      //   onClick: () => {
      //     // console.log(record);
      //   }
      // })}
      />
    </div>
  );
};