import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef } from "react";
import { Outlet } from "react-router";

import { DashBoardHeader } from "./components/DashBoardHeader";
import { DashBoardTable } from "./components/DashBoardTable";
import styles from "./DashBoardLayout.module.scss";

import { useFileVersions } from "../../../hooks/useFileVersions";

export const DashBoardLayout = () => {
  const ref = useRef<HTMLDivElement>(null);

  const queryClient = useQueryClient();

  const { data, isLoading } = useFileVersions();

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries({
        queryKey: ["getVersions"],
      });
    };
  }, []);

  return (
    <div className={styles.root}>
      {!isLoading && data && <DashBoardHeader layoutRef={ref} option={data} />}
      <div className={styles.wrapper} ref={ref}>
        <Outlet />
        <DashBoardTable />
      </div>
    </div>
  );
};
