import { FC } from "react";

import styles from "./ChangePassSeccess.module.scss";

import successImg from "../../../../assets/img/complited.png";
import { AUTH_FORMS } from "../../../../constants/AUTH_FORMS";
import { CustomButton } from "../../../FormFields/CustomButton";
import { FormVerticalWrapper } from "../../../FormFields/FormVerticalWrapper";
import { FormImg } from "../FormImg";
import { FormTitle } from "../FormTitle";

interface IProps {
  onClose?: () => void;
}

export const ChangePassSeccess: FC<IProps> = ({ onClose }) => {
  return (
    <div className={styles.root}>
      <FormVerticalWrapper>
        <FormVerticalWrapper gap={24}>
          <FormImg src={successImg} />
          <FormTitle
            text={AUTH_FORMS.NAMES.PASSWORD_CHANGED_SUCCESSFULLY}
            description={AUTH_FORMS.SUBTITLES.PASSWORD_CHANGED_SUCCESSFULLY}
            aligment="center"
          />
        </FormVerticalWrapper>
        <CustomButton className={styles.btn} onClick={onClose}>
          {AUTH_FORMS.BTNS.CONTINUE}
        </CustomButton>
      </FormVerticalWrapper>
    </div>
  );
};
