import { FC } from "react";

import styles from "./HeaderLogo.module.scss";

import { ReactComponent as Logo } from "../../../assets/icons/logo-icon.svg";
import { ReactComponent as LogoText } from "../../../assets/icons/logoText-icon.svg";
import { Description } from "../../Description";

interface IProps {
  title?: string;
}

export const HeaderLogo: FC<IProps> = ({ title }) => {
  return (
    <div className={styles.root}>
      <div className={styles.logoWrapper}>
        <Logo className={styles.logoIcon} />
        <LogoText className={styles.logoTextIcon} />
      </div>
      <Description theme={"t1"}>{title}</Description>
    </div>
  );
};
