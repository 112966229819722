import styles from "./ProfileSettingsInfo.module.scss";

import { CustomLink } from "../../../CustomLink";

export const ProfileSettingsInfo = () => {
  return (
    <div className={styles.root}>
      <p className={styles.text}>
        If you wish to delete your account, please contact our support for
        assistance.
      </p>
      <CustomLink link="mailto:support@friday-report.com">
        support@friday-report.com
      </CustomLink>
    </div>
  );
};
