import classNames from "classnames";
import { FC, MouseEvent, ReactNode } from "react";
import { Link } from "react-router-dom";

import styles from "./CustomLink.module.scss";

interface IProps {
  children: ReactNode;
  link?: string;
  className?: string;
  isDisabled?: boolean;
  onClick?: (e: MouseEvent<HTMLAnchorElement> | undefined) => void;
}

export const CustomLink: FC<IProps> = ({
  children,
  link = "/",
  className,
  isDisabled,
  onClick,
}) => {
  return (
    <Link
      to={link}
      onClick={onClick}
      className={classNames(
        styles.root,
        { [styles.disabled]: isDisabled },
        className
      )}
    >
      {children}
    </Link>
  );
};
