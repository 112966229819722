import { useCallback, useEffect } from "react";
import { useCookies } from "react-cookie";

import { getAceessTokenByRefresh } from "../api";
import { TOKEN_REFRESH_INTERVAL } from "../constants/TOKEN_REFRESH_INTERVAL";
import { decodeToken } from "../utils/decodeToken";

export const useAuthToken = () => {
  const [token, setToken] = useCookies(["accessToken", "refreshToken"]);

  const requestNewAccessToken = useCallback(async () => {
    const resp = await getAceessTokenByRefresh({
      refreshToken: token.refreshToken,
    });

    if (resp.status === 200) {
      const accessToken = decodeToken(resp.data.data.accessToken);
      const refreshToken = decodeToken(resp.data.data.refreshToken);

      setToken("accessToken", accessToken.token, {
        expires: accessToken.expDate,
        path: "/",
      });
      setToken("refreshToken", refreshToken.token, {
        expires: refreshToken.expDate,
        path: "/",
      });
    }
  }, [setToken, token]);

  useEffect(() => {
    if (token.accessToken || token.refreshToken) {
      if (!token.accessToken) {
        requestNewAccessToken();
      } else {
        const decodeAccessToken = decodeToken(token.accessToken);

        const refreshTime =
          decodeAccessToken.expDate.getTime() - TOKEN_REFRESH_INTERVAL;

        const timeout = setTimeout(
          requestNewAccessToken,
          refreshTime - Date.now()
        );

        return () => {
          clearTimeout(timeout);
        };
      }
    }
  }, [requestNewAccessToken, token]);
};
