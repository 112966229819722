import styles from "./AccountSettingsPage.module.scss";

import { Sections } from "../../components/AccountSettings/components/Sections";
import { SectionTitle } from "../../components/AccountSettings/components/SectionTitle";
import { Subscription } from "../../components/AccountSettings/components/Subscription";

export const AccountSettingsPage = () => {
  return (
    <div className={styles.root}>
      <div className={styles.titleWrapper}>
        <Sections />
        <SectionTitle
          title="Simple, transparent pricing"
          description="We believe Friday Report should be accessible to all companies, no matter the size."
        />
      </div>
      <Subscription />
    </div>
  );
};
