import styles from "./MainBanner.module.scss";

import mainBanner from "../../../../assets/img/mainBanner.png";

export const MainBanner = () => {
  return (
    <div className={styles.root}>
      <img
        src={mainBanner}
        className={styles.image}
        loading="lazy"
        alt="mainBanner"
      />
    </div>
  );
};
