import { forwardRef } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./ReportSettings.module.scss";

import { ReactComponent as ShowHistory } from "../../../../assets/icons/history-icon.svg";
import { ReactComponent as Refresh } from "../../../../assets/icons/refresh-icon.svg";
import { PATHS } from "../../../../constants/PATHS";
import {useAppDispatch} from "../../../../store/hooks/hooks";
import {setIdHistory, setName} from "../../../../store/slices/historySlice";
import { CustomButton } from "../../../FormFields/CustomButton";

interface IProps {
  onOpenClick: () => void;
  reportName: string;
  name: string;
}

export const ReportSettings = forwardRef<HTMLDivElement, IProps>(
  ({ onOpenClick, reportName, name }, ref) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch()

    const onClickShowUpdate = () => {
      dispatch(setIdHistory(reportName))
      dispatch(setName(name))
      navigate(PATHS.HISTORY_PATH);
    };

    const reportSettingsNavigation = [
      {
        id: "1",
        title: "Update Report",
        icon: <Refresh />,
        onClick: onOpenClick,
      },
      {
        id: "2",
        title: "Show update’s history",
        icon: <ShowHistory />,
        onClick: onClickShowUpdate,
      },
    ];

    return (
      <div ref={ref} className={styles.root}>
        <ul className={styles.tabs}>
          {reportSettingsNavigation.map((item) => (
            <li key={item.id}>
              <CustomButton
                theme={"unstyled"}
                className={styles.labelTab}
                onClick={item.onClick}
              >
                <span className={styles.wrapper}>
                  {item.icon}
                  {item.title}
                </span>
              </CustomButton>
            </li>
          ))}
        </ul>
      </div>
    );
  },
);
