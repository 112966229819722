/* eslint-disable max-len */

export const MESSAGES = {
  REQUIRED: "Required field",
  EMAIL: "Invalid email",
  PASSWORDMIN: "Minimum password length 8 characters",
  PASSWORDCONFIRM: "Incorrect password confirmation",
  REQCHAR:
    "The password must contain at least one upper case letter, one lower case letter, one number and one special character (!@#$%^&*)",
  NOTPATTERN: "The password should not be very common",
  NOTFOUND: "Sorry, nothing was found for your query.",
  REQUESTERROR:
    "Sorry, we are unable to retrieve data, try refreshing the page.",
  minLength: (value: string | number) => `Minimum line length ${value}`,
  maxLength: (value: string | number) => `Maximum line length ${value}`,
  PASSWORD:
    "The password can only contain Latin letters, numbers, symbols and at least one Latin letter",
  DATA_LOADING_ERROR:
    "Something's wrong, try again later. Alternatively, reload the page",
  ONLYLATIN: "The field must include Latin letters only",
  ONLYNUMBERS: "Just the numbers",
};
