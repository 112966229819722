import { useFormikContext } from "formik";
import { FC } from "react";

import styles from "./ShareReport.module.scss";

import resetImg from "../../../../../assets/img/shareReport.png";
import { IShereForm } from "../../../../../types/dashBoard";
import { FormImg } from "../../../../Auth/components/FormImg";
import { CustomButton } from "../../../../FormFields/CustomButton";
import { CustomInput } from "../../../../FormFields/CustomInput";
import { Loader } from "../../../../Loader";
import { Title } from "../../../../Title";

interface IProps {
  isLoading: boolean;
}

export const ShareReport: FC<IProps> = ({ isLoading }) => {
  const formik = useFormikContext<IShereForm>();

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <FormImg src={resetImg} />
          <div className={styles.wrapperText}>
            <Title tagName="h3" className={styles.title}>
              Share report
            </Title>
            <p>Enter the recipient's email address to send the report.</p>
          </div>
          <CustomInput
            type="email"
            label="Email"
            id="email"
            name="email"
            placeholder="Enter recipient's email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.errors.email}
          />
        </div>
        <CustomButton
          type="submit"
          className={styles.btn}
          isDisabled={!formik.dirty || !formik.isValid || isLoading}
        >
          {isLoading && <Loader />}
          {!isLoading && "Share"}
        </CustomButton>
      </div>
    </form>
  );
};
