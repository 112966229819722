import classNames from "classnames";
import { FC, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import styles from "./HistoryTable.module.scss";

import {
  changeCurrentTemplate,
  deleteTemplate,
  downloadTemplate,
} from "../../../../api/history/history";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/trash-icon.svg";
import { MESSAGES } from "../../../../constants/MESSAGES";
import { PATHS } from "../../../../constants/PATHS";
import { useClickOutside } from "../../../../hooks/useClickOutside";
import { useCurrentInfoFiles } from "../../../../hooks/useCurrentInfoFiles";
import { IHistoryReports } from "../../../../types/historyReports";
import { createErrorMessage } from "../../../../utils/createErrorMessage";
import { formatDate, formatTimeAsUtc } from "../../../../utils/formatDate";
import { CustomButton } from "../../../FormFields/CustomButton";
import { CustomCheckbox } from "../../../FormFields/CustomCheckbox";
import { ReactPortal } from "../../../ReactPortal";
import { ReactPortalSubstrate } from "../../../ReactPortalSubstrate";
import { DeleteReport } from "../../../Reports/components/DeleteReport";

interface IProps {
  listTable: IHistoryReports[];
  setUpdate: (value: string) => void;
  organizationId: number;
  tenantId: number;
}

export const HistoryTable: FC<IProps> = ({
  listTable,
  tenantId,
  organizationId,
  setUpdate,
}) => {
  const [isPortalOpen, setIsPortalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const formRef = useRef<HTMLDivElement>(null);

  const { refetch } = useCurrentInfoFiles();

  const navigate = useNavigate();

  const onDeleteClick = async (id: string) => {
    try {
      await deleteTemplate(id, organizationId, tenantId).then(() => {
        setUpdate(id);
        refetch();
      });
    } catch (error: any) {
      toast.error(createErrorMessage(error));
    }

    setIsPortalOpen(false);
  };

  const onOpenClick = (id: string) => {
    setIsPortalOpen(true);
    setDeleteId(id);
  };

  const onCloseClick = () => {
    setIsPortalOpen(false);
  };

  const formatTitle = (title: string) => {
    return title.replace(/([A-Z])/g, " $1");
  };

  const downloadFile = async (id: string, templateName: string) => {
    try {
      const res = await downloadTemplate(id, organizationId, tenantId);
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", templateName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      toast.error(MESSAGES.DATA_LOADING_ERROR);
    }
  };

  const handleCheckboxChange = async (id: string) => {
    try {
      await changeCurrentTemplate(id, organizationId, tenantId).then(() => {
        setUpdate(id);
        refetch();
      });
    } catch (error: any) {
      toast.error(createErrorMessage(error));
    }
  };

  const handleTitleClick = (name: string, id: string) => {
    navigate(`${PATHS.REPORTS_PATH}/${name}/${id}`);
  };

  useClickOutside(formRef, onCloseClick);

  return (
    <table className={styles.root}>
      <thead>
        <tr className={styles.tableRow}>
          <th className={styles.headerDate}>Date of update</th>
          <th></th>
          <th>Report’s title</th>
          <th className={styles.headerCurrent}>Сurrent</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {listTable.map((item) => (
          <tr key={item.id} className={styles.row}>
            <td className={styles.dateColum}>{formatDate(item.uploadedOn)}</td>
            <td className={styles.timeColum}>
              {formatTimeAsUtc(item.uploadedOn)} (UTC)
            </td>
            <td className={styles.titleColum}>
              <CustomButton
                theme={"text"}
                className={styles.titleBtn}
                onClick={() => handleTitleClick(item.templateName, item.id)}
              >
                {formatTitle(item.templateName)}
              </CustomButton>
            </td>
            <td className={styles.checkboxContainer}>
              <CustomCheckbox
                labelStyles={styles.labelStyles}
                checked={item.isCurrent}
                onChange={() => handleCheckboxChange(item.id)}
                isDisabled={item.isCurrent}
              />
            </td>
            <td>
              <CustomButton
                theme={"text"}
                className={styles.buttonDown}
                onClick={() => downloadFile(item.id, item.fileName)}
              >
                {" "}
                Download
              </CustomButton>
            </td>
            <td
              className={classNames(styles.btnDelete, {
                [styles.disabled]: item.isCurrent,
              })}
            >
              <CustomButton
                theme={"unstyled"}
                onClick={() => onOpenClick(item.id)}
                isDisabled={item.isCurrent}
              >
                {" "}
                <DeleteIcon />
              </CustomButton>
            </td>
          </tr>
        ))}
      </tbody>

      {isPortalOpen && (
        <ReactPortal>
          <ReactPortalSubstrate className={styles.portalWrapper}>
            <DeleteReport
              ref={formRef}
              onClose={onCloseClick}
              onDeleteClick={onDeleteClick}
              id={deleteId}
            />
          </ReactPortalSubstrate>
        </ReactPortal>
      )}
    </table>
  );
};
