import { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";

import styles from "./UploadData.module.scss";

import { useClickOutside } from "../../../../hooks/useClickOutside";
import { useAppDispatch } from "../../../../store/hooks/hooks";
import { setDemo } from "../../../../store/slices/uploadFileSlice";
import { Auth } from "../../../Auth";
import { Description } from "../../../Description";
import { CustomButton } from "../../../FormFields/CustomButton";
import { CustomUpload } from "../../../FormFields/CustomUpload";
import { ReactPortal } from "../../../ReactPortal";
import { ReactPortalSubstrate } from "../../../ReactPortalSubstrate";

export const UploadData = () => {
  const [isPortalOpen, seIsPortalOpen] = useState(false);

  const [token] = useCookies(["accessToken"]);

  const formRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const onOpenClick = () => {
    seIsPortalOpen(true);
  };

  const onCloseClick = () => {
    seIsPortalOpen(false);
  };

  useClickOutside(formRef, onCloseClick);

  useEffect(() => {
    dispatch(setDemo("upload"));
  }, []);

  return (
    <div className={styles.root}>
      {!token.accessToken && (
        <div className={styles.wrapper}>
          <CustomButton className={styles.btn} onClick={onOpenClick}>
            Login
          </CustomButton>
          <Description theme={"t5"} className={styles.text}>
            Log into the system to upload your data
          </Description>
        </div>
      )}
      {token.accessToken && <CustomUpload className={styles.customUpload} />}
      {isPortalOpen && (
        <ReactPortal>
          <ReactPortalSubstrate className={styles.portalWrapper}>
            <Auth ref={formRef} onClose={onCloseClick} />
          </ReactPortalSubstrate>
        </ReactPortal>
      )}
    </div>
  );
};
