import { useFormikContext } from "formik";
import { FC } from "react";

import styles from "./ReportSent.module.scss";

import completedImg from "../../../../../assets/img/complited.png";
import { IShereForm } from "../../../../../types/dashBoard";
import { FormImg } from "../../../../Auth/components/FormImg";
import { CustomLink } from "../../../../CustomLink";
import { CustomButton } from "../../../../FormFields/CustomButton";
import { Title } from "../../../../Title";

interface IProps {
  onClose: () => void;
}

export const ReportSent: FC<IProps> = ({ onClose }) => {
  const formik = useFormikContext<IShereForm>();

  const onOkClick = () => {
    onClose();
  };

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <FormImg src={completedImg} />
        <div className={styles.wrapperText}>
          <Title tagName="h3" className={styles.title}>
            Report sent!
          </Title>
          <div>
            <p>
              The report has been successfully sent to the specified email
              address:{" "}
            </p>
            <CustomLink
              link={`mailto:${formik.values.email}`}
              className={styles.link}
            >
              {formik.values.email}
            </CustomLink>
          </div>
        </div>
      </div>
      <CustomButton onClick={onOkClick} className={styles.btn}>
        Ok
      </CustomButton>
    </div>
  );
};
