import { Route, Routes } from "react-router";
import { Slide, ToastContainer } from "react-toastify";

import { DashBoardLayout } from "./components/DashBoards/DashBoardLayout";
import { DashBoardProject } from "./components/DashBoards/DashBoardProject";
import { IssuesSummary } from "./components/DashBoards/IssuesSummary";
import { MilestonesSummary } from "./components/DashBoards/MilestonesSummary";
import { Layout } from "./components/Layout";
import { Steps } from "./components/Steps";
import { PATHS } from "./constants/PATHS";
import { Page404 } from "./pages/404";
import { AccountSettingsPage } from "./pages/AccountSettingsPage";
import { ConnectDataPage } from "./pages/ConnectDataPage";
import { HistoryPage } from "./pages/HistoryPage";
import { MainPage } from "./pages/MainPage";
import { PrivacyPolicyPage } from "./pages/PrivacyPolicyPage";
import { ProfileSettingsPage } from "./pages/ProfileSettingsPage";
import { ReportsPage } from "./pages/ReportsPage";
import { PrivateRoute } from "./utils/privateRoute";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<MainPage />} />
          <Route path={"/"} element={<Steps />}>
            <Route path={PATHS.LOAD_DATA_PATH} element={<ConnectDataPage />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route
              path={PATHS.PROFILE_SETTINGS}
              element={<ProfileSettingsPage />}
            />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route
              path={PATHS.ACCOUNT_SETTINGS}
              element={<AccountSettingsPage />}
            />
          </Route>
          <Route element={<PrivateRoute />} path={PATHS.REPORTS_PATH}>
            <Route index element={<ReportsPage />} />
            <Route element={<DashBoardLayout />}>
              <Route
                path={`${PATHS.REPORTS.PROJECT}/:reportId`}
                element={<DashBoardProject />}
              />
              <Route
                path={`${PATHS.REPORTS.MILESTONES}/:reportId`}
                element={<MilestonesSummary />}
              />
              <Route
                path={`${PATHS.REPORTS.ISSUES}/:reportId`}
                element={<IssuesSummary />}
              />
            </Route>
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path={PATHS.HISTORY_PATH} element={<HistoryPage />} />
          </Route>
          <Route
            path={PATHS.PRIVACY_POLICY_PATH}
            element={<PrivacyPolicyPage />}
          />

          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
        style={{ zIndex: 9999 }}
      />
    </>
  );
}

export default App;
