import { ReactComponent as ClickUp } from "../../../../assets/icons/clickUp-icon.svg";
import { ReactComponent as Dataverse } from "../../../../assets/icons/dataverse-icon.svg";
import { ReactComponent as Jira } from "../../../../assets/icons/jira-icon.svg";
import { ReactComponent as Planner } from "../../../../assets/icons/microsoftPlanner-icon.svg";
import { ReactComponent as Monday } from "../../../../assets/icons/monday-icon.svg";
import { ReactComponent as OneDrive } from "../../../../assets/icons/oneDrive-icon.svg";
import { ReactComponent as PowerBI } from "../../../../assets/icons/powerBI-icon.svg";
import { ReactComponent as PPM } from "../../../../assets/icons/ppm-icon.svg";
import { ReactComponent as Online } from "../../../../assets/icons/projectOnline-icon.svg";
import { ReactComponent as ServiceNow } from "../../../../assets/icons/serviceNow-icon.svg";

export const dataList = [
  { id: 1, icon: <PowerBI />, title: "PowerBI" },
  { id: 2, icon: <Jira />, title: "Jira" },
  { id: 3, icon: <OneDrive />, title: "OneDrive" },
  { id: 4, icon: <ServiceNow />, title: "ServiceNow" },
  { id: 5, icon: <PPM />, title: "PPM Express" },
  { id: 6, icon: <Monday />, title: "monday.com" },
  { id: 7, icon: <ClickUp />, title: "ClickUp" },
  { id: 8, icon: <Planner />, title: "Microsoft Planner" },
  { id: 9, icon: <Online />, title: "Project Online" },
  { id: 10, icon: <Dataverse />, title: "Dataverse" },
];
