import classNames from "classnames";
import { FC } from "react";

import styles from "./CustomValueTab.module.scss";

import { ITab } from "../../types/tabs";

interface IProps {
  className?: string;
  wrapperStyles?: string;
  setTabValue: (value: string) => void;
  tabValue?: string;
  tabs: ITab[];
}

export const CustomValueTab: FC<IProps> = ({ tabs, setTabValue, tabValue }) => {
  return (
    <div className={styles.root}>
      {tabs.map((tab) => (
        <div
          key={tab.id}
          className={classNames(styles.wrapper, tab.stylesOrder)}
        >
          <input
            onChange={() => setTabValue(tab.value)}
            value={tab.value}
            checked={tabValue === tab.value}
            className={styles.inputTab}
            type="radio"
            name="tab"
            id={tab.id}
          />
          <label className={styles.labelTab} htmlFor={tab.id}>
            {tab.tab}
          </label>
        </div>
      ))}
    </div>
  );
};
