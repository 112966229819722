import { useFormik } from "formik";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import * as yup from "yup";

import { postNewUser } from "../../../../api";
import confirmImg from "../../../../assets/img/confitmation.png";
import { AUTH_FORMS } from "../../../../constants/AUTH_FORMS";
import { MESSAGES } from "../../../../constants/MESSAGES";
import { useAppDispatch } from "../../../../store/hooks/hooks";
import { setAuthStep } from "../../../../store/slices/authSlice";
import { EAuthSteps } from "../../../../types/formTypes";
import { createErrorMessage } from "../../../../utils/createErrorMessage";
import { decodeToken } from "../../../../utils/decodeToken";
import { CustomInput } from "../../../FormFields/CustomInput";
import { FormVerticalWrapper } from "../../../FormFields/FormVerticalWrapper";
import { FormImg } from "../FormImg";
import { FormSubmitWrapper } from "../FormSubmitWrapper";
import { FormTitle } from "../FormTitle";

export const ConfirmationForm = () => {
  const [token, setToken] = useCookies<string>([
    "tempToken",
    "accessToken",
    "refreshToken",
  ]);

  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: yup.object({
      code: yup
        .string()
        .required(MESSAGES.REQUIRED)
        .min(6, MESSAGES.minLength(6))
        .max(6, MESSAGES.maxLength(6))
        .matches(/^\d+$/, MESSAGES.ONLYNUMBERS),
    }),
    onSubmit: async (data) => {
      try {
        const resp = await postNewUser({
          validateToken: token.tempToken,
          confirmationCode: data.code,
        });

        const accessToken = decodeToken(resp.data.data.accessToken);
        const refreshToken = decodeToken(resp.data.data.refreshToken);

        setToken("accessToken", accessToken.token, {
          expires: accessToken.expDate,
          path: "/",
        });
        setToken("refreshToken", refreshToken.token, {
          expires: refreshToken.expDate,
          path: "/",
        });

        dispatch(setAuthStep(EAuthSteps["Registration completed!"]));
      } catch (error) {
        toast.error(createErrorMessage(error));
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormVerticalWrapper>
        <FormVerticalWrapper gap={32} align="center">
          <FormImg src={confirmImg} />
          <FormTitle
            text={AUTH_FORMS.NAMES.CONFIRMATION}
            description={AUTH_FORMS.SUBTITLES.CONFIRMATION}
          />
          <CustomInput
            id="code"
            name="code"
            placeholder="Enter code"
            value={formik.values.code}
            onChange={formik.handleChange}
            error={formik.errors.code}
          />
        </FormVerticalWrapper>
        <FormSubmitWrapper
          btnText={AUTH_FORMS.BTNS.CONFIRMATION}
          isDisbledSubmit={!formik.dirty || !formik.isValid}
        />
      </FormVerticalWrapper>
    </form>
  );
};
