import {
  Chart as ChartJS,
  CategoryScale,
  Tooltip,
  Legend,
  ArcElement,
  ChartOptions,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import classNames from "classnames";
import { FC } from "react";
import { Doughnut } from "react-chartjs-2";

import styles from "./DoughnutChart.module.scss";

import { DataItem, FormatItem } from "../../../types/previewTemplate";
import { Description } from "../../Description";

ChartJS.register(CategoryScale, Tooltip, Legend, ArcElement, ChartDataLabels);

interface IProps {
  dataList?: DataItem[];
  className?: string;
  radius?: number;
  format?: FormatItem;
}

export const DoughnutChart: FC<IProps> = ({
  dataList,
  className,
  radius = 93,
  format,
}) => {
  const innerDiameter = 45;
  const outerDiameter = 100;

  const options: ChartOptions<"doughnut"> = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: `${(innerDiameter / outerDiameter) * 100}%`,
    radius: radius,
    circumference: 360,
    clip: 33,
    plugins: {
      legend: {
        title: {
          display: true,
          text: "Business Sponsor",
          position: "start",
          color: "#000000",
          padding: 0,
          font: {
            size: 14,
            weight: 500,
          },
        },
        position: "bottom",
        align: "start",
        labels: {
          usePointStyle: true,
          boxWidth: 20,
          boxHeight: 20,
          padding: 24,
          color: "black",
        },
      },

      datalabels: {
        color: "#000000",
        font: {
          weight: 400,
        },
        display: (context) => {
          return context.dataset.data.length < 8;
        },
        formatter: function (value: number, context: any) {
          const dataset = context.chart.data.datasets[context.datasetIndex];
          const total = dataset.data.reduce(
            (acc: number, curr: number) => acc + curr,
            0
          );
          const percentage = ((value / total) * 100).toFixed(2);
          const formattedValue = value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          });

          return `${formattedValue}${format?.unit} (${percentage}%)`;
        },
        anchor: "end",
        align: "end",
        offset: 10,
        clip: false,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
        borderRadius: 0,
      },
    },
    layout: {
      padding: {
        bottom: 0,
      },
    },
  };

  const data = {
    labels: dataList?.map((item) => item.label),
    datasets: [
      {
        data: dataList?.map((item) => item.value),
        backgroundColor: dataList?.map((item) => item.color),
        borderColor: dataList?.map((item) => item.color),
      },
    ],
  };

  return (
    <div className={classNames(styles.root, className)}>
      <Description theme={"t3"}>Budget cost by Business Sponsor</Description>
      <div className={styles.dashBoardWrapper}>
        <Doughnut data={data} options={options} />
      </div>
    </div>
  );
};
