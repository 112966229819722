import { forwardRef } from "react";

import styles from "./AvatarMenu.module.scss";
import { avatarMenuNavigation } from "./MenuList";

import { ReactComponent as LogoutIcon } from "../../../../assets/icons/power-icon.svg";
import { CustomButton } from "../../../FormFields/CustomButton";
import { UserData } from "../../../UserData";
import { MenuNavigation } from "../SideMenu/components/MenuNavigation";

interface IProps {
  onOpenClick: () => void;
  isMenuOpen?: () => void;
}

export const AvatarMenu = forwardRef<HTMLDivElement, IProps>(
  ({ onOpenClick, isMenuOpen }, ref) => {
    return (
      <div ref={ref} className={styles.root}>
        <UserData />
        <nav className={styles.actionsWrapper}>
          <MenuNavigation
            menuList={avatarMenuNavigation}
            className={styles.wrapperNavigation}
            stylesLink={styles.stylesLink}
            isMenuOpen={isMenuOpen}
          />
          <CustomButton
            theme="unstyled"
            className={styles.logoutBtn}
            onClick={onOpenClick}
          >
            <LogoutIcon />
            Log out
          </CustomButton>
        </nav>
      </div>
    );
  },
);
