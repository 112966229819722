import { AxiosPromise } from "axios";

import { ICurrentMetaInfoFilesResp } from "../../types/reports";
import { API_PATHS } from "../API_PATHS";
import { request } from "../utils";

export const getCurrentInfoFiles = (
  organizationId: number,
  tenantId: number
): AxiosPromise<ICurrentMetaInfoFilesResp> => {
  return request({
    method: "GET",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.FILE.GET_CURRENT_FILES(organizationId, tenantId)}`,
  });
};

export const getTableInfo = (
  summary: string,
  fileMetaId: string,
  organizatId: number,
  tenId: number
) => {
  return request({
    method: "GET",
    url:
      // eslint-disable-next-line max-len
      `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.PROJECT_SUMMARY.GET_TABLE_INFO(summary, fileMetaId, organizatId, tenId)}`,
  });
};

export const getVersionsProject = (
  summary: string,
  orgId: number,
  tenantId: number
) => {
  return request({
    method: "GET",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.PROJECT_SUMMARY.GET_VERSIONS(summary, orgId, tenantId)}`,
  });
};

export const shareReport = (
  data: any,
  summary: string,
  email: string,
  orgId: number,
  tenantId: number,
  reportName: string
) => {
  return request({
    method: "POST",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.FILE.SHARE_REPORT(summary, email, orgId, tenantId, reportName)}`,
    data: { file: data },
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getExcelFile = (
  fileId: string,
  organizationId: number,
  tenantId: number
) => {
  return request({
    method: "GET",
    url: `${API_PATHS.BASE_URLS.API_URL}${API_PATHS.FILE.DOWNLOAD_FILE(fileId, organizationId, tenantId)}`,
    responseType: "blob",
  });
};
