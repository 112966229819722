import { ChartOptions } from "chart.js";
import classNames from "classnames";
import { FC } from "react";
import { Pie } from "react-chartjs-2";

import styles from "./PieChart.module.scss";

import { DataItem } from "../../../types/previewTemplate";
import { Description } from "../../Description";

interface IProps {
  dataList?: DataItem[];
  title?: string;
  legendTitle?: string;
  className?: string;
}

export const PieChart: FC<IProps> = ({
  dataList,
  className,
  title,
  legendTitle,
}) => {
  const options: ChartOptions<"pie"> = {
    responsive: true,
    maintainAspectRatio: false,
    radius: 93,
    circumference: 360,
    clip: 33,
    plugins: {
      legend: {
        title: {
          display: true,
          text: legendTitle,
          position: "start",
          color: "#000000",
          padding: 0,
          font: {
            size: 14,
            weight: 500,
            lineHeight: "19px",
          },
        },
        position: "bottom",
        align: "start",
        labels: {
          usePointStyle: true,
          boxWidth: 20,
          boxHeight: 20,
          padding: 24,
          color: "#000000",
        },
      },
      datalabels: {
        color: "#000000",
        font: {
          weight: 400,
        },
        formatter: function (value: number, context: any) {
          const dataset = context.chart.data.datasets[context.datasetIndex];
          const total = dataset.data.reduce(
            (acc: number, curr: number) => acc + curr,
            0,
          );
          const percentage = ((value / total) * 100).toFixed(2);

          return `${value} (${percentage}%)`;
        },
        anchor: "end",
        align: "end",
        offset: 10,
        clip: false,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
        borderRadius: 0,
      },
    },
    layout: {
      padding: {
        bottom: 0,
      },
    },
  };

  const data = {
    labels: dataList?.map((item) => item.label),
    datasets: [
      {
        data: dataList?.map((item) => item.value),
        backgroundColor: ["#FFA267", "#FFDAC2"],
        borderColor: ["#FFA267", "#FFDAC2"],
      },
    ],
  };

  return (
    <div className={classNames(styles.root, className)}>
      <Description theme="t3">{title}</Description>
      <div className={styles.pieWrapper}>
        <Pie data={data} options={options} />
      </div>
    </div>
  );
};
