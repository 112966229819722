import { FC } from "react";

import styles from "./InfoTiles.module.scss";

import { Description } from "../../../../Description";

interface IProps {
  title: string;
  value?: string | number;
}

export const InfoTiles: FC<IProps> = ({ value, title }) => {
  const updateString = (value?: string | number) => {
    if (value) {
      if (typeof value === "number") {
        return value;
      } else {
        const dotIndex = value.indexOf(".");

        if (dotIndex) {
          return `${value.slice(0, dotIndex + 3)}${value.slice(value.length - 1, value.length)}`;
        } else {
          return value;
        }
      }
    }
  };

  return (
    <div className={styles.root}>
      <span className={styles.value}>{updateString(value)}</span>
      <Description theme={"t4"} className={styles.description}>
        {title}
      </Description>
    </div>
  );
};
