import { useLocation } from "react-router-dom";

import styles from "./PageName.module.scss";

import { Title } from "../../../Title";

export const PageName = () => {
  const { pathname } = useLocation();

  const getPageName = () => {
    if (pathname.length <= 1) {
      return "";
    }

    const firstPathPart = pathname.slice(1).split("/");
    const pathSegments = firstPathPart[0].split("-");

    if (pathSegments.length === 1) {
      return `${pathSegments[0].charAt(0).toUpperCase()}${pathSegments[0].slice(1)}`;
    }

    return `${pathSegments[0].charAt(0).toUpperCase()}${pathSegments[0].slice(1)} ${pathSegments[1]}`;
  };

  return (
    <Title tagName="h4" className={styles.root}>
      {getPageName()}
    </Title>
  );
};
