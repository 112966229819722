import { nanoid } from "@reduxjs/toolkit";
import classNames from "classnames";
import { FC } from "react";
import { MultiSelect } from "react-multi-select-component";

import { CustomItem } from "./components/CustomItem/CustomItem";
import styles from "./CustomMultiSelect.module.scss";
import { IOptions } from "./types";

interface IProps {
  options: IOptions[];
  value: IOptions[];
  label: string;
  id?: string;
  placeholder?: string;
  className?: string;
  onChange: (value: IOptions[]) => void;
}

export const CustomMultiSelect: FC<IProps> = ({
  options,
  value,
  label,
  id = nanoid(),
  placeholder,
  className,
  onChange,
}) => {
  return (
    <div className={classNames(styles.root, className)}>
      {label && <label htmlFor={id}>{label}</label>}
      <MultiSelect
        options={options}
        value={value}
        onChange={onChange}
        labelledBy="Select"
        className={styles.select}
        ItemRenderer={CustomItem}
        overrideStrings={{
          allItemsAreSelected: "All",
        }}
      />
    </div>
  );
};
