import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import styles from "./Header.module.scss";

import { getReportHeader } from "../../../../../api/milestonesSummary/milestonesSummary";
import { useUserData } from "../../../../../hooks/useUserProfile";
import { useAppSelector } from "../../../../../store/hooks/hooks";
import { selectCurrentId } from "../../../../../store/slices/stepSlice";
import { HeaderMilestones } from "../../../../../types/milestonesSummary";
import { createErrorMessage } from "../../../../../utils/createErrorMessage";
import { formatDate } from "../../../../../utils/formatDate";
import { Loader } from "../../../../Loader";
import { HeaderLogo } from "../../../HederLogo";
import { HeaderFilters } from "../HeaderFilters";

export const Header = () => {
  const [headerInfo, setHeaderInfo] = useState<HeaderMilestones>();

  const currentId = useAppSelector(selectCurrentId);

  const { organizationId, tenantId } = useUserData();
  const { reportId } = useParams();

  const { isLoading } = useQuery({
    queryKey: ["getHeaderMilestones", tenantId, reportId, currentId],
    queryFn:
      tenantId &&
      reportId &&
      (async () => {
        try {
          const headerInfo = await getReportHeader(
            currentId ? currentId : reportId,
            organizationId,
            tenantId,
          );
          setHeaderInfo(headerInfo.data.data);
        } catch (error) {
          toast.error(createErrorMessage(error));
        }
      }),
    enabled: !!tenantId && !!reportId && (!!currentId || !!reportId),
  });

  return (
    <div className={styles.root}>
      <HeaderLogo title={headerInfo?.reportName} />

      {isLoading && <Loader />}
      {!isLoading && headerInfo && (
        <HeaderFilters
          completed={headerInfo?.completedStates}
          projects={headerInfo?.projects}
          milestoneType={headerInfo?.milestoneTypes}
          maxPeriod={formatDate(headerInfo.maxPeriod)}
          minPeriod={formatDate(headerInfo.minPeriod)}
        />
      )}
    </div>
  );
};
