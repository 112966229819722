import { PrivacyDataList } from "./PrivacyData";
import styles from "./PrivacyPolicyPage.module.scss";

import { Title } from "../../components/Title";

export const PrivacyPolicyPage = () => {
  return (
    <div className={styles.root}>
      <Title tagName={"h2"}>Privacy Policy</Title>
      <div className={styles.wrapper}>
        {PrivacyDataList.map((item) => (
          <div key={item.id}>
            <Title tagName={"h3"}>{item.title}</Title>
            {item.text}
          </div>
        ))}
      </div>
    </div>
  );
};
