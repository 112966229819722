import styles from "./EmptyHistoryReports.module.scss";

import emptyTable from "../../../../assets/img/emptyTable.png";
import { FormImg } from "../../../Auth/components/FormImg";
import { Description } from "../../../Description";

export const EmptyHistoryReports = () => {
  return (
    <div className={styles.root}>
      <FormImg src={emptyTable} />
      <Description theme={"t2"} className={styles.description}>
        This is where the history of uploaded reports is displayed. Currently,
        there are no available reports to show.
      </Description>
    </div>
  );
};
